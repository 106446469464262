// third party
import React from 'react'
import fetch from 'cross-fetch'
// utils
import RequestUtils from '../utils/RequestUtils'
import request from '../utils/request/Request'
// constants
import { API_HOST, API_AUTH } from '../constants/EnvTypes'
import {
  REQUEST_ALL_PACIENTES, RECEIVE_ALL_PACIENTES, REQUEST_PACIENTES, RECEIVE_PACIENTES, REQUEST_PACIENTE,
  RECEIVE_PACIENTE, REQUEST_PACIENTE_CALL_STATUS, RECEIVE_PACIENTE_CALL_STATUS, REQUEST_PACIENTE_PRONTUARIO,
  RECEIVE_PACIENTE_PRONTUARIO, RESET_PACIENTE, REQUEST_VALIDATE_PACIENTE, RECEIVE_VALIDATE_PACIENTE,
  REQUEST_SAVE_PACIENTE, REQUEST_SAVE_PACIENTE_ATENDIMENTO, RECEIVE_SAVE_PACIENTE_ATENDIMENTO, RECEIVE_SAVE_PACIENTE,
  REQUEST_CHANGE_PASSWD_PACIENTE, RECEIVE_CHANGE_PASSWD_PACIENTE, REQUEST_SAVE_ANEXOS_PACIENTE,
  RECEIVE_SAVE_ANEXOS_PACIENTE, REQUEST_REMOVE_ANEXOS_PACIENTE, RECEIVE_REMOVE_ANEXOS_PACIENTE,
  REQUEST_CERT_SIGN_ANEXO_PACIENTE, RECEIVE_CERT_SIGN_ANEXO_PACIENTE,
  REQUEST_PACIENTE_CANCEL_AGENDAMENTO, RECEIVE_PACIENTE_CANCEL_AGENDAMENTO, REQUEST_PACIENTE_RESCHEDULE_AGENDAMENTO,
  RECEIVE_PACIENTE_RESCHEDULE_AGENDAMENTO, REQUEST_PACIENTE_RETORNO_AGENDAMENTO, RECEIVE_PACIENTE_RETORNO_AGENDAMENTO,
  REQUEST_CHECK_TIME_AVAILABILITY, RECEIVE_CHECK_TIME_AVAILABILITY, REQUEST_ACCESS_PROTOCOL, RECEIVE_ACCESS_PROTOCOL,
  REQUEST_ALL_EMPRESAS, RECEIVE_ALL_EMPRESAS, REQUEST_SAVE_SIGNATURE_PACIENTE, RECEIVE_SAVE_SIGNATURE_PACIENTE,
  RESET_ALL_PACIENTE
} from '../constants/ActionTypes'
// actions
import { showRequestError } from './AppActions'
import { addToast } from './ToastActions'

function requestAllPacientes() {
  return {
    type: REQUEST_ALL_PACIENTES.type,
    isLoading: true,
  }
}

function receiveAllPacientes(data) {
  return {
    type: RECEIVE_ALL_PACIENTES.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchAllPacientes(callback = () => {}) {
  return (dispatch, getState) => {
    const isLoaded = (getState().paciente.all.receivedAt !== null)
    // carrega apenas uma vez
    if (isLoaded) return callback(true)
    dispatch(requestAllPacientes())
    request.get(`${API_HOST}/paciente/all`, {}).then(json => {
      dispatch(receiveAllPacientes(json))
      callback(true)
    }).catch(err => {
      dispatch(receiveAllPacientes())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestAllEmpresas() {
  return {
    type: REQUEST_ALL_EMPRESAS,
    isLoading: true,
  }
}

function receiveAllEmpresas(data) {
  return {
    type: RECEIVE_ALL_EMPRESAS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchAllEmpresas() {
  return (dispatch, getState) => {
    // const isLoaded = (getState().paciente.all.receivedAt !== null)
    // carrega apenas uma vez
    // if (isLoaded) return callback(true)
    dispatch(requestAllEmpresas())
    request.get(`${API_HOST}/paciente/empresas`, {}).then(json => {
      dispatch(receiveAllEmpresas(json))
    }).catch(err => {
      dispatch(receiveAllEmpresas())
      dispatch(showRequestError(err))
    })
  }
}

function requestPacientes() {
  return {
    type: REQUEST_PACIENTES,
    isLoading: true,
  }
}

function receivePacientes(data) {
  return {
    type: RECEIVE_PACIENTES,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchPacientes(params /*= { page: 1, limit: 50, orderBy: 'id', orderDirection: 'DESC' }*/, callback = () => {}) {

  return (dispatch, getState) => {
    dispatch(requestPacientes())
    const endPoint = `${API_HOST}/paciente`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar pacientes!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar pacientes!',
          err500: 'Erro ao consultar pacientes!',
          errDefault: 'Erro desconhecido ao consultar pacientes!'
        })
      })
      .then(json => {
        dispatch(receivePacientes(json))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(false)
      })
  }
}

function requestPaciente() {
  return {
    type: REQUEST_PACIENTE,
    isLoading: true,
  }
}

function receivePaciente(data) {
  return {
    type: RECEIVE_PACIENTE,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchPaciente(id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestPaciente())
    const url = `${API_HOST}/paciente/${id}`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar paciente!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar paciente!',
          err500: 'Erro ao consultar paciente!',
          errDefault: 'Erro desconhecido ao consultar paciente!'
        })
      })
      .then(json => {
        dispatch(receivePaciente(json))
        callback(json)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(null)
      })
  }
}

export function fetchExternalPaciente(partner, data, callback = () => {}) {
  return dispatch => {
    if (partner === 'UNIMED_CEARA_CARTEIRINHA') {
      const url = `${API_HOST}/unimed-ceara/paciente/carteirinha/${data.carteirinha}`
      request.get(url).then(json => {
        callback(null, json)
      }).catch(err => {
        callback(err)
      })

    } else if (partner === 'UNIMED_CEARA_CPF') {
      const url = `${API_HOST}/unimed-ceara/paciente/cpf/${data.cpf}`
      request.get(url).then(json => {
        callback(null, json)
      }).catch(err => {
        callback(err)
      })

    } else {
      dispatch(showRequestError(new Error('Parceiro desconhecido')))
    }
  }
}

function requestPacienteCallStatus() {
  return {
    type: REQUEST_PACIENTE_CALL_STATUS.type,
    isLoading: true,
  }
}

function receivePacienteCallStatus(data) {
  return {
    type: RECEIVE_PACIENTE_CALL_STATUS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchPacienteCallStatus(entity, idEntity, callback = () => {}) {
  return dispatch => {
    dispatch(requestPacienteCallStatus())
    request.get(`${API_HOST}/paciente/call-status/${entity}/${idEntity}`, {}).then(json => {
      dispatch(receivePacienteCallStatus(json))
      callback(null, json)
    }).catch(err => {
      dispatch(receivePacienteCallStatus({ online: false }))
      callback(err)
    })
  }
}

function requestPacienteProntuario() {
  return {
    type: REQUEST_PACIENTE_PRONTUARIO.type,
    isLoading: true,
  }
}

function receivePacienteProntuario(data) {
  return {
    type: RECEIVE_PACIENTE_PRONTUARIO.type,
    isLoading: false,
    data: data,
  }
}

export function fetchPacienteProntuario(idPaciente, callback = () => {}) {
  return dispatch => {
    dispatch(requestPacienteProntuario())
    request.get(`${API_HOST}/paciente/${idPaciente}/prontuario`, {}).then(json => {
      dispatch(receivePacienteProntuario(json))
      callback()
    }).catch(err => {
      dispatch(receivePacienteProntuario())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

export function resetPaciente() {
  return {
    type: RESET_PACIENTE,
  }
}

function requestValidatePaciente() {
  return {
    type: REQUEST_VALIDATE_PACIENTE,
    isLoading: true,
  }
}

function receiveValidatePaciente() {
  return {
    type: RECEIVE_VALIDATE_PACIENTE,
    isLoading: false,
  }
}

/**
 * Reseta imagem_md5 do exame para viabilizar envio de exame com mesma(a) imagens.
 */
export function validatePaciente(data, callback = () => {}, showError = true) {
  return (dispatch, getState) => {
    const isLoading = getState().paciente.validate.isLoading
    console.log('validatePaciente.isLoading', isLoading)
    if (isLoading) return
    dispatch(requestValidatePaciente())
    const url = `${API_HOST}/paciente/validate`
    request.post(url, {}, data).then(json => {
      dispatch(receiveValidatePaciente())
      if (json && 'pacientes' in json && json.pacientes.length) {
        callback(json)
      } else {
        callback(null)
      }
      if (showError && json && 'pacientes' in json && json.pacientes.length) {
        let count = 0
        const msgPacientes = []
        for (const item of json.pacientes) {
          msgPacientes.push(`${item.id} - ${item.nome}`)
          count++
          if (count === 3) break
        }
        const message = `${json.userMessage}:<br />${msgPacientes.join('<br/>')}`
        dispatch(addToast('error', <div dangerouslySetInnerHTML={{__html: message}} />, 'Entendi', false))
      }
    }).catch(err => {
      console.log(err)
      callback(null)
      dispatch(receiveValidatePaciente())
    })
  }
}

function requestSavePaciente() {
  return {
    type: REQUEST_SAVE_PACIENTE,
    isLoading: true,
  }
}

function receiveSavePaciente(data, saveAsSuccess = false) {
  return {
    type: RECEIVE_SAVE_PACIENTE,
    isLoading: false,
    data,
    saveAsSuccess: saveAsSuccess,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados do paciente. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Number} id
 * @param {Object} data
 */
export function savePaciente(id, data, callback = () => {}) {
  return (dispatch, getState) => {
    const isLoading = getState().paciente.item.isLoading
    console.log('savePaciente.isLoading', isLoading)
    if (isLoading) return
    dispatch(requestSavePaciente())
    const url = (id) ? `${API_HOST}/paciente/${id}` : `${API_HOST}/paciente`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: (id) ? 'PUT' : 'POST',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao salvar paciente!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para salvar paciente!',
          err500: 'Erro ao salvar paciente!',
          errDefault: 'Erro desconhecido ao salvar paciente!'
        })
      })
      .then(json => {
        dispatch(addToast('success', 'Registro salvo com sucesso.', null, true))
        dispatch(receiveSavePaciente(json, true))
        callback(true, json)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSavePaciente(data))
        callback(false)
      })
  }
}

function requestSaveAtendimento() {
  return {
    type: REQUEST_SAVE_PACIENTE_ATENDIMENTO.type,
    isLoading: true,
  }
}

function receiveSaveAtendimento() {
  return {
    type: RECEIVE_SAVE_PACIENTE_ATENDIMENTO.type,
    isLoading: false,
  }
}

/**
 * Realiza o agendamento de atendimento.
 *
 * @param {Number} idPaciente
 * @param {Object} data
 * @param {Function} [callback] função de callback caso
 */
export function saveAtendimento(idPaciente, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveAtendimento())
    const url = `${API_HOST}/paciente/${idPaciente}/atendimento`
    request.post(url, {}, JSON.stringify(data)).then(json => {
      dispatch(receiveSaveAtendimento())
      dispatch(addToast('success', 'Registro salvo com sucesso.', null, true))
      callback(json.idResult)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      dispatch(receiveSaveAtendimento())
      callback(false)
    })
  }
}

/*export function fetchPacientesIfNeeded(params = {page: 1, limit: 50}) {
  return (dispatch, getState) => {
    const state = getState()
    console.log(state)
    const hashParams = hash(params)
    const itensByParams = state.paciente.list.itensByParams
    const inCache = function(element, index, array) {
      if (element.key == hashParams) {
        return true
      }
    }
    if (itensByParams.find(inCache)) {
      console.log('EM CACHE')
      return Promise.resolve()
    } else {
      console.log('NAO EM CACHE')
      return dispatch(fetchPacientes(params))
    }
  }
}*/

function requestChangePasswd() {
  return {
    type: REQUEST_CHANGE_PASSWD_PACIENTE.type,
    isLoading: true,
  }
}

function receiveChangePasswd() {
  return {
    type: RECEIVE_CHANGE_PASSWD_PACIENTE.type,
    isLoading: false,
  }
}

/**
 * Envia requisição de alteração de senha.
 *
 * @param {Object} data { current_passwd: '123456', new_passwd: 'abcdef' }
 */
export function fetchChangePasswd(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestChangePasswd())
    const url = `${API_AUTH}/pacientes/change/passwd`
    request.post(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Senha alterada com sucesso.', null, true))
      dispatch(receiveChangePasswd())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveChangePasswd())
      callback(false)
    })
  }
}

function requestSaveAnexos() {
  return {
    type: REQUEST_SAVE_ANEXOS_PACIENTE.type,
    isLoading: true,
  }
}

function receiveSaveAnexos() {
  return {
    type: RECEIVE_SAVE_ANEXOS_PACIENTE.type,
    isLoading: false,
  }
}

export function fetchSaveAnexos(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveAnexos())
    const url = `${API_HOST}/paciente/anexos`
    request.post(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Anexo(s) salvo(s) com sucesso.', null, true))
      dispatch(receiveSaveAnexos())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveAnexos())
      callback(false)
    })
  }
}

function requestRemoveAnexos() {
  return {
    type: REQUEST_REMOVE_ANEXOS_PACIENTE.type,
    isLoading: true,
  }
}

function receiveRemoveAnexos() {
  return {
    type: RECEIVE_REMOVE_ANEXOS_PACIENTE.type,
    isLoading: false,
  }
}

export function fetchRemoveAnexos(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoveAnexos())
    const url = `${API_HOST}/paciente/anexos/${id}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Anexo removido com sucesso.', null, true))
      dispatch(receiveRemoveAnexos())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveRemoveAnexos())
      callback(false)
    })
  }
}

function requestCertSignAnexo() {
  return {
    type: REQUEST_CERT_SIGN_ANEXO_PACIENTE,
    isLoading: true,
  }
}

function receiveCertSignAnexo() {
  return {
    type: RECEIVE_CERT_SIGN_ANEXO_PACIENTE,
    isLoading: false,
  }
}

export function fetchCertSignAnexo(idTeleconsulta, idAnexoPaciente, callback = () => {}) {
  return dispatch => {
    dispatch(requestCertSignAnexo())
    const url = `${API_HOST}/prontuario/teleconsulta/${idTeleconsulta}/cert-sign-anexo/${idAnexoPaciente}`
    request.post(url).then(json => {
      dispatch(addToast('success', 'Anexo assinado com sucesso.', null, true))
      dispatch(receiveCertSignAnexo())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveCertSignAnexo())
      callback(false)
    })
  }
}

export function fetchRemoveCertSignAnexo(idTeleconsulta, idAnexoPaciente, callback = () => {}) {
  return dispatch => {
    dispatch(requestCertSignAnexo())
    const url = `${API_HOST}/prontuario/teleconsulta/${idTeleconsulta}/cert-sign-anexo/${idAnexoPaciente}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Assinatura removida com sucesso.', null, true))
      dispatch(receiveCertSignAnexo())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveCertSignAnexo())
      callback(false)
    })
  }
}

function requestCancelAgendamento() {
  return {
    type: REQUEST_PACIENTE_CANCEL_AGENDAMENTO.type,
    isLoading: true,
  }
}

function receiveCancelAgendamento() {
  return {
    type: RECEIVE_PACIENTE_CANCEL_AGENDAMENTO.type,
    isLoading: false,
  }
}

/**
 * Cancela agendamento de atendimento.
 *
 * @param {Object} item { id: 1 }
 * @param {Function} callback
 */
export function fetchCancelAgendamento(item, callback = () => {}) {
  return dispatch => {
    dispatch(requestCancelAgendamento())
    const url = `${API_HOST}/paciente/agendamento/cancel`
    request.put(url, {}, JSON.stringify(item)).then(json => {
      dispatch(addToast('success', 'Agendamento cancelado com sucesso.', null, true))
      dispatch(receiveCancelAgendamento())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveCancelAgendamento())
      callback(false)
    })
  }
}

function requestRescheduleAgendamento() {
  return {
    type: REQUEST_PACIENTE_RESCHEDULE_AGENDAMENTO.type,
    isLoading: true,
  }
}

function receiveRescheduleAgendamento() {
  return {
    type: RECEIVE_PACIENTE_RESCHEDULE_AGENDAMENTO.type,
    isLoading: false,
  }
}

/**
 * Reagenda (tele)consulta.
 *
 * @param {Object} item { id: 1, agendamento: '2020-03-30T14:15:00', id_endereco_medico: 22 }
 * @param {Function} callback
 */
export function fetchRescheduleAgendamento(item, callback = () => {}) {
  return dispatch => {
    dispatch(requestRescheduleAgendamento())
    const url = `${API_HOST}/paciente/agendamento/reschedule`
    request.put(url, {}, JSON.stringify(item)).then(json => {
      dispatch(addToast('success', 'Reagendado com sucesso.', null, true))
      dispatch(receiveRescheduleAgendamento())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveRescheduleAgendamento())
      callback(false)
    })
  }
}

function requestCheckTimeAvailability() {
  return {
    type: REQUEST_CHECK_TIME_AVAILABILITY.type,
    isLoading: true,
    data: { available: null }
  }
}

function receiveCheckTimeAvailability(data) {
  return {
    type: RECEIVE_CHECK_TIME_AVAILABILITY.type,
    isLoading: false,
    data,
  }
}

/**
 * Verifica disponibilidade na agenda do médico de um determinado horário.
 *
 * @param {Object} data { id_medico: 1, agendamento: '2020-03-30T14:15:00' }
 */
export function fetchCheckTimeAvailability(data) {
  return dispatch => {
    dispatch(requestCheckTimeAvailability())
    const url = `${API_HOST}/paciente/agendamento/check-time-availability`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(receiveCheckTimeAvailability(json))
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveCheckTimeAvailability({ available: null }))
    })
  }
}

function requestRetornoAgendamento() {
  return {
    type: REQUEST_PACIENTE_RETORNO_AGENDAMENTO.type,
    isLoading: true,
  }
}

function receiveRetornoAgendamento() {
  return {
    type: RECEIVE_PACIENTE_RETORNO_AGENDAMENTO.type,
    isLoading: false,
  }
}

/**
 * Reagenda (tele)consulta.
 *
 * @param {Object} item {  id: 1, agendamento: '2020-03-30T14:15:00' }
 * @param {Function} callback
 */
export function fetchRetornoAgendamento(item, callback = () => {}) {
  return dispatch => {
    dispatch(requestRetornoAgendamento())
    const url = `${API_HOST}/paciente/agendamento/retorno`
    request.put(url, {}, JSON.stringify(item)).then(json => {
      dispatch(addToast('success', 'Retorno agendado com sucesso.', null, true))
      dispatch(receiveRetornoAgendamento())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveRetornoAgendamento())
      callback(false)
    })
  }
}

function requestAccessProtocol() {
  return {
    type: REQUEST_ACCESS_PROTOCOL,
    isLoading: true,
    data: null
  }
}

function receiveAccessProtocol(data) {
  return {
    type: RECEIVE_ACCESS_PROTOCOL,
    isLoading: false,
    data,
  }
}

/**
 * Consulta informações de protocolo para acesso a exames pelo paciente.
 *
 * @param {Number} idPaciente
 * @param {Function} callback
 */
export function fetchAccessProtocol(idPaciente, callback = () => {}) {
  return dispatch => {
    dispatch(requestAccessProtocol())
    const url = `${API_HOST}/paciente/${idPaciente}/access-protocol`
    request.get(url).then(json => {
      dispatch(receiveAccessProtocol(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveAccessProtocol())
      callback(err)
    })
  }
}

/**
 * Gera PDF para impressão de informações de protocolo para acesso a exames pelo paciente.
 *
 * @param {Number} idPaciente
 */
export function fetchPrintAccessProtocol(idPaciente) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/paciente/${idPaciente}/access-protocol/pdf?jwt=${jwt}`
    window.open(url, '_blank')
  }
}

/**
 * Envia e-mail com protocolo para acesso a exames para paciente.
 *
 * @param {Number} idPaciente
 * @param {Object} data { email: 'andreluizhaag@gmail.com' }
 */
export function fetchEmailAccessProtocol(idPaciente, data, callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/paciente/${idPaciente}/access-protocol/email`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      callback(null, json)
      dispatch(addToast('success', 'E-mail enviado com sucesso.', null, true))
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

export function fetchUltimoLaudoByEspecialidade(idPaciente, idEspecialidade, limitDays = 30, idsParteCorpo = null, callback = () => {}) {
  return (dispatch) => {
    const url = `${API_HOST}/paciente/${idPaciente}/ultimo-laudo/${idEspecialidade}?limit_days=${limitDays}&id_parte_corpo=${idsParteCorpo}`
    request.get(url).then(json => {
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestSaveSignature() {
  return {
    type: REQUEST_SAVE_SIGNATURE_PACIENTE,
    isLoading: true,
  }
}

function receiveSaveSignature() {
  return {
    type: RECEIVE_SAVE_SIGNATURE_PACIENTE,
    isLoading: false,
  }
}

/**
 * Persiste assinatura do paciente.
 * Inicialmente para assinatura do ASO.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveSignature(id, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveSignature())
    const url = `${API_HOST}/paciente/${id}/signature`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Salvo com sucesso.', null, true))
      dispatch(receiveSaveSignature())
      callback()
    }).catch(err => {
      dispatch(receiveSaveSignature())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

export function fetchSignatureLinkPaciente(idPaciente, idExame = null, callback = () => {}) {
  return dispatch => {
    let url = `${API_HOST}/paciente/${idPaciente}/signature/link`
    const params = idExame ? { id_exame: idExame } : null
    request.get(url, params).then(json => {
      callback(null, json.link)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(err)
    })
  }
}

export function fetchSignatureInviteEmail(idPaciente, email, idExame = null, callback = () => {}) {
  return dispatch => {
    let url = `${API_HOST}/paciente/${idPaciente}/signature/invite-email`
    request.post(url, {}, JSON.stringify({ email, id_exame: idExame })).then(json => {
      dispatch(addToast('success', `Convite enviado para ${email}`, null, true))
      callback()
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(err)
    })
  }
}

/**
 * Remove assinatura do paciente.
 *
 * Caso informado o idExame é removido o laudo e regerado para gerar versão sem assinatura.
 *
 * @param {Number} id ID do paciente
 * @param {Number} idExame ID do paciente
 */
 export function removeSignature(id, idExame = null, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveSignature())
    const url = `${API_HOST}/paciente/${id}/signature`
    request.delete(url, { ...(idExame ? { id_exame: idExame } : {}) }).then(json => {
      dispatch(addToast('success', 'Assinatura removida com sucesso.', null, true))
      dispatch(receiveSaveSignature())
      callback()
    }).catch(err => {
      dispatch(receiveSaveSignature())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

export function resetAllPaciente() {
  return {
    type: RESET_ALL_PACIENTE,
  }
}
