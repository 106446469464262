// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'

class DownloadService {

  /**
   * Obtem URL temporária para download.
   *
   * @param {Object} file Nome do arquivo incluindo estrutura de diretório caso exista.
   * @param {String} entity
   * @returns Promise
   */
  async getDownloadUrl(file, entity) {
    console.log(`DownloadService.getDownloadUrl: fileName=${file}, entity=${entity}`)
    try {
      const res = await request.get(`${API_HOST}/upload/download/tmp-url/${entity}/${window.encodeURIComponent(file)}`)
      console.log('res', res.url[0])
      let src = res.url[0]
      return src
    } catch (error) {
      console.error(error.message)
      console.error(error.stack)
      return null
    }
  }
}

export default new DownloadService()
