// constants
import { REQUEST_APP } from '../constants/ActionTypes'
// actions
import { fetchApp } from '../actions/AppActions'

/**
 * Middleware responsável por realizar o despacho de requisição de recursos globais
 * da aplicação caso o login ja tenha sido realizado e os dados ainda não tenham sido carregados.
 */
export const bootstrap = ({dispatch, getState}) => next => action => {


  let state = getState()
  let isLogged = state.auth.isLogged
  // let idUser = (Object.keys(state.auth.jwtPayload).length === 0) ? null : state.auth.jwtPayload.user.entityId
  let loginType = (Object.keys(state.auth.jwtPayload).length === 0) ? null : state.auth.jwtPayload.user.type
  let loginProfile = (Object.keys(state.auth.jwtPayload).length === 0) ? null : state.auth.jwtPayload.user.profile
  let isAppDispatched = state.app.dispatched

  if (isLogged && /*idUser &&*/ ! isAppDispatched && action && 'type' in action && action.type != REQUEST_APP) {
    dispatch(fetchApp(loginType, loginProfile))
  }

  next(action)
}