// third party
import fetch from 'cross-fetch'
// utils
import RequestUtils from '../utils/RequestUtils'
import request from '../utils/request/Request' // new
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_LAUDOS, RECEIVE_LAUDOS, REQUEST_LAUDO, RECEIVE_LAUDO, REQUEST_LAUDOS_BY_PACIENTE, RECEIVE_LAUDOS_BY_PACIENTE,
  REQUEST_SAVE_LAUDO, RECEIVE_SAVE_LAUDO, REQUEST_LAUDO_TEXTOS, RECEIVE_LAUDO_TEXTOS, REQUEST_LAUDO_TEXTO,
  RESET_LAUDO_TEXTO, RECEIVE_LAUDO_TEXTO, REQUEST_SAVE_LAUDO_TEXTO, RECEIVE_SAVE_LAUDO_TEXTO, REQUEST_DELETE_LAUDO_TEXTO,
  RECEIVE_DELETE_LAUDO_TEXTO, REQUEST_LAUDO_TEXTOS_BY_ESPECIALIDADE, RECEIVE_LAUDO_TEXTOS_BY_ESPECIALIDADE,
  REQUEST_LAUDO_TEXTOS_OIT_ADICIONAIS, RECEIVE_LAUDO_TEXTOS_OIT_ADICIONAIS, REQUEST_REMOVE_LAUDO,
  RECEIVE_REMOVE_LAUDO, RESET_ALL_LAUDO, REQUEST_LAUDO_TRANSLATE, RECEIVE_LAUDO_TRANSLATE, REQUEST_LABELS_BONUS,
  RECEIVE_LABELS_BONUS, REQUEST_LAUDO_TEXTO_TAGS, RECEIVE_LAUDO_TEXTO_TAGS, REQUEST_SAVE_LAUDO_TEXTO_TAG,
  RECEIVE_SAVE_LAUDO_TEXTO_TAG
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'
import { fetchEntityInformacoesAdicionais } from './AuthActions'
import { showRequestError } from './AppActions'


function requestLaudos() {
  return {
    type: REQUEST_LAUDOS,
    isLoading: true,
  }
}

function receiveLaudos(data) {
  return {
    type: RECEIVE_LAUDOS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchLaudos(params = { page: 1, limit: 50 }, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestLaudos())
    const endPoint = `${API_HOST}/laudo`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar laudos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar laudos!',
          err500: 'Erro ao consultar laudos!',
          errDefault: 'Erro desconhecido ao consultar laudos!'
        })
      })
      .then(json => {
        dispatch(receiveLaudos(json))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(false)
      })
  }
}

function requestLaudo() {
  return {
    type: REQUEST_LAUDO,
    isLoading: true,
  }
}

function receiveLaudo(data) {
  return {
    type: RECEIVE_LAUDO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchLaudo(id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestLaudo())
    const url = `${API_HOST}/laudo/${id}`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar laudo!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar laudo!',
          err500: 'Erro ao consultar laudo!',
          errDefault: 'Erro desconhecido ao consultar laudo!'
        })
      })
      .then(json => {
        dispatch(receiveLaudo(json))
        callback(null, json)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(err.message)
      })
  }
}

function requestLaudoTranslate() {
  return {
    type: REQUEST_LAUDO_TRANSLATE,
    isLoading: true,
  }
}

function receiveLaudoTranslate(data) {
  return {
    type: RECEIVE_LAUDO_TRANSLATE,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Realiza tradução de um texto ou HTML.
 *
 * @param {Object} data Ex: { text: 'Texto para ser traduzido', target: 'es' }
 * @param {Function} callback
 */
export function fetchLaudoTranslate(idLaudo, callback = () => {}) {
  return dispatch => {
    dispatch(requestLaudoTranslate())
    request.get(`${API_HOST}/laudo/${idLaudo}/translate`).then(json => {
      dispatch(receiveLaudoTranslate(json.text))
      callback(null, json.text)
    }).catch(err => {
      dispatch(addToast('error', 'Erro ao tentar traduzir', null, true))
      callback(err)
    })
  }
}

function requestLaudosByPaciente(idPaciente) {
  return {
    type: REQUEST_LAUDOS_BY_PACIENTE,
    isLoading: true,
    idPaciente: idPaciente,
  }
}

function receiveLaudosByPaciente(idPaciente, data) {
  return {
    type: RECEIVE_LAUDOS_BY_PACIENTE,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchLaudosByPaciente(idPaciente, params = { page: 1, limit: 200 }) {
  return (dispatch, getState) => {
    dispatch(requestLaudosByPaciente(idPaciente))
    const endPoint = `${API_HOST}/laudo/paciente/${idPaciente}`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar laudos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar laudos!',
          err500: 'Erro ao consultar laudos!',
          errDefault: 'Erro desconhecido ao consultar laudos!'
        })
      })
      .then(json => dispatch(receiveLaudosByPaciente(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestSaveLaudos() {
  return {
    type: REQUEST_SAVE_LAUDO,
    isLoading: true,
  }
}

function receiveSaveLaudos(data) {
  return {
    type: RECEIVE_SAVE_LAUDO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados do laudo. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Object} data
 */
export function saveLaudos(data, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveLaudos())
    const url = `${API_HOST}/laudo`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao enviar laudo!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para enviar laudo!',
          err500: 'Erro ao tentar enviar laudo! Contate o administrador',
          errDefault: 'Erro desconhecido ao enviar laudo!'
        })
      }).then(json => {
        // dispatch(addToast('success', 'Laudo enviado com sucesso.', null, true))
        dispatch(receiveSaveLaudos(json))
        dispatch(fetchEntityInformacoesAdicionais())
        callback(true)
      }).catch(err => {
        console.log(err)
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveLaudos(data))
        callback(false)
      })
  }
}

/**
 * Admin substitui conteúdo do laudo em registro de revisão.
 *
 * @param {Object} data Exemplo: { justificativa: '', laudo: '' }
 */
 export function saveLaudoReplace(idLaudo, data, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveLaudos())
    const url = `${API_HOST}/laudo/${idLaudo}/replace`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao enviar laudo!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para enviar laudo!',
          err500: 'Erro ao tentar enviar laudo! Contate o administrador',
          errDefault: 'Erro desconhecido ao enviar laudo!'
        })
      }).then(json => {
        dispatch(receiveSaveLaudos(json))
        callback(null, json)
      }).catch(err => {
        console.log(err)
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveLaudos())
        callback(err)
      })
  }
}

/**
 * Atualiza laudo.
 *
 * Inicialmente apenas ASO.
 *
 * @param {Object} data Campos de 'aso_anamnese' e 'aso'
 */
export function updateLaudo(idLaudo, data, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveLaudos())
    const url = `${API_HOST}/laudo/${idLaudo}`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao enviar laudo!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para enviar laudo!',
          err500: 'Erro ao tentar enviar laudo! Contate o administrador',
          errDefault: 'Erro desconhecido ao enviar laudo!'
        })
      }).then(json => {
        dispatch(receiveSaveLaudos(json))
        callback(null, json)
      }).catch(err => {
        console.log(err)
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveLaudos())
        callback(err)
      })
  }
}

function requestLaudoTextos(params) {
  return {
    type: REQUEST_LAUDO_TEXTOS,
    isLoading: true,
    params: params,
  }
}

function receiveLaudoTextos(data) {
  return {
    type: RECEIVE_LAUDO_TEXTOS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchLaudoTextos(params = { page: 1, limit: 50 }, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestLaudoTextos(params))
    const endPoint = `${API_HOST}/laudo/textos`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar textos para laudos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar textos para laudos!',
          err500: 'Erro ao consultar laudos!',
          errDefault: 'Erro desconhecido ao consultar textos para laudos!'
        })
      })
      .then(json => {
        dispatch(receiveLaudoTextos(json))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(false)
      })
  }
}

function requestLaudoTextoTags() {
  return {
    type: REQUEST_LAUDO_TEXTO_TAGS,
    isLoading: true,
  }
}

function receiveLaudoTextoTags(data) {
  return {
    type: RECEIVE_LAUDO_TEXTO_TAGS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Busca tags de substituição dinamica de textos de laudo.
 */
export function fetchLaudoTextoTags(params = { page: 1, limit: 1000 }, callback = () => {}) {
  return dispatch => {
    dispatch(requestLaudoTextoTags())
    request.get(`${API_HOST}/laudo/texto/tags`, params).then(json => {
      dispatch(receiveLaudoTextoTags(json))
      callback(null, json)
    }).catch(err => {
      dispatch(addToast('error', 'Erro ao obter tags', null, true))
      dispatch(receiveLaudoTextoTags({}))
      callback(err)
    })
  }
}

function requestSaveLaudoTextoTag() {
  return {
    type: REQUEST_SAVE_LAUDO_TEXTO_TAG,
    isLoading: true,
  }
}

function receiveSaveLaudoTextoTag() {
  return {
    type: RECEIVE_SAVE_LAUDO_TEXTO_TAG,
    isLoading: false,
  }
}

/**
 * Persiste tag de substituição dinamica de textos de laudo.
 *
 * @param {Object} data
 */
export function saveLaudoTextoTag(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveLaudoTextoTag())
    const url = (data.id)
      ? `${API_HOST}/laudo/texto/tags/${data.id}`
      : `${API_HOST}/laudo/texto/tags`
    const method = (data.id) ? 'put' : 'post'
    request[method](url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Tag salva com sucesso.', null, true))
      dispatch(receiveSaveLaudoTextoTag())
      dispatch(fetchLaudoTextoTags())
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveLaudoTextoTag())
      callback(err)
    })
  }
}

/**
 * Persiste tag de substituição dinamica de textos de laudo.
 *
 * @param {Object} data
 */
 export function removeLaudoTextoTag(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveLaudoTextoTag())
    request.delete(`${API_HOST}/laudo/texto/tags/${id}`).then(json => {
      dispatch(addToast('success', 'Tag removida com sucesso.', null, true))
      dispatch(receiveSaveLaudoTextoTag())
      dispatch(fetchLaudoTextoTags())
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveLaudoTextoTag())
      callback(err)
    })
  }
}

function requestLaudoTexto() {
  return {
    type: REQUEST_LAUDO_TEXTO,
    isLoading: true,
  }
}

function receiveLaudoTexto(data) {
  return {
    type: RECEIVE_LAUDO_TEXTO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Obtem um laudo_texto por ID.
 *
 * @param {Number} params ID do exame.
 */
export function fetchLaudoTexto(id) {
  return dispatch => {
    dispatch(requestLaudoTexto())
    const url = `${API_HOST}/laudo/texto/${id}`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar texto para laudo!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar texto para laudo!',
          err500: 'Erro ao consultar texto para laudo!',
          errDefault: 'Erro desconhecido ao consultar texto para laudo!'
        })
      })
      .then(json => dispatch(receiveLaudoTexto(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

export function resetLaudoTexto() {
  return {
    type: RESET_LAUDO_TEXTO,
  }
}


function requestSaveLaudoTexto() {
  return {
    type: REQUEST_SAVE_LAUDO_TEXTO,
    isLoading: true,
  }
}

function receiveSaveLaudoTexto() {
  return {
    type: RECEIVE_SAVE_LAUDO_TEXTO,
    isLoading: false,
  }
}

/**
 * Persiste os dados de laudo texto. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveLaudoTexto(id, data, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveLaudoTexto())
    const url = (id) ? `${API_HOST}/laudo/texto/${id}` : `${API_HOST}/laudo/texto`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: (id) ? 'PUT' : 'POST',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao salvar laudo texto!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para salvar laudo texto!',
          err500: 'Erro ao salvar laudo texto!',
          errDefault: 'Erro desconhecido ao salvar laudo texto!'
        })
      })
      .then(json => {
        dispatch(addToast('success', 'Registro salvo com sucesso.', null, true))
        dispatch(receiveSaveLaudoTexto())
        callback()
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveLaudoTexto())
        callback(err)
      })
  }
}

export function fetchRemoveLaudoTexto(id, callback) {
  return (dispatch, getState) => {
    dispatch(requestSaveLaudoTexto())
    const url = `${API_HOST}/laudo/texto/${id}`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'DELETE',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao remover laudo texto!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para remover laudo texto!',
          err500: 'Erro ao remover laudo texto!',
          errDefault: 'Erro desconhecido ao remover laudo texto!'
        })
      })
      .then(json => {
        dispatch(addToast('success', 'Registro removido com sucesso.', null, true))
        dispatch(fetchLaudoTextos(getState().laudo.laudoTextos.params))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveLaudoTexto())
        callback(false)
      })
  }
}

function requestLaudoTextosByIdEspecialidade(isOrientacao) {
  return {
    type: REQUEST_LAUDO_TEXTOS_BY_ESPECIALIDADE,
    isLoading: true,
    isOrientacao: isOrientacao
  }
}

function receiveLaudoTextosByIdEspecialidade(data, isOrientacao) {
  return {
    type: RECEIVE_LAUDO_TEXTOS_BY_ESPECIALIDADE,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
    isOrientacao: isOrientacao
  }
}

/**
 * Busca frases prontas
 *
 * @param {Number} idEspecialidade
 * @param {Boolean} isOrientacao
 * @param {Function} callback
 * @param {Boolean} onlyCallback Se true apenas retorna no callback sem alterar o estado
 */
export function fetchLaudoTextosByIdEspecialidade(idEspecialidade, isOrientacao = false, callback = () => {}, onlyCallback = false) {
  let filters = [{ name:'id_especialidade', value: idEspecialidade, type:'ids'}]
  if (isOrientacao) {
    filters.push({ name:'orientacao', value: 1, type:'ids'})
  } else {
    filters.push({ name:'orientacao', value: 0, type:'ids'})
  }
  let params = {
    page: 1,
    limit: 3000,
    orderBy: 'titulo',
    orderDirection: 'ASC',
    filters: JSON.stringify(filters)
  }
  return (dispatch, getState) => {
    if (!onlyCallback) dispatch(requestLaudoTextosByIdEspecialidade(isOrientacao))
    const endPoint = `${API_HOST}/laudo/textos`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar textos para laudos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar textos para laudos!',
          err500: 'Erro ao consultar laudos!',
          errDefault: 'Erro desconhecido ao consultar textos para laudos!'
        })
      })
      .then(json => {
        if (!onlyCallback) dispatch(receiveLaudoTextosByIdEspecialidade(json, isOrientacao))
        // se OIT carrega texto da especialidade 77 para utilização nos laudos adicionais
        if (idEspecialidade == 73 && isOrientacao === false) {
          if (!onlyCallback) dispatch(fetchLaudoTextosOitAdicionais())
        }
        callback(null, json)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(err)
      })
  }
}

function requestLaudoTextosOitAdicionais() {
  return {
    type: REQUEST_LAUDO_TEXTOS_OIT_ADICIONAIS,
    isLoading: true,
  }
}

function receiveLaudoTextosOitAdicionais(data) {
  return {
    type: RECEIVE_LAUDO_TEXTOS_OIT_ADICIONAIS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchLaudoTextosOitAdicionais() {
  let params = {
    page: 1,
    limit: 500,
    orderBy: 'titulo',
    orderDirection: 'ASC',
    filters: JSON.stringify([
      { name:'id_especialidade', value: 77, type:'ids'},
      { name:'orientacao', value: 0, type:'ids'}
    ])
  }
  return (dispatch, getState) => {
    dispatch(requestLaudoTextosOitAdicionais())
    const endPoint = `${API_HOST}/laudo/textos`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar textos para laudos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar textos para laudos!',
          err500: 'Erro ao consultar laudos!',
          errDefault: 'Erro desconhecido ao consultar textos para laudos!'
        })
      })
      .then(json => dispatch(receiveLaudoTextosOitAdicionais(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestRemoveLaudo() {
  return {
    type: REQUEST_REMOVE_LAUDO.type,
    isLoading: true,
  }
}

function receiveRemoveLaudo() {
  return {
    type: RECEIVE_REMOVE_LAUDO.type,
    isLoading: false,
  }
}

export function fetchRemoveLaudo(id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestRemoveLaudo())
    const url = `${API_HOST}/laudo/${id}`
    request.delete(url, {}, { requestId: REQUEST_REMOVE_LAUDO.id }).then(json => {
      dispatch(receiveRemoveLaudo(json))
      dispatch(addToast('success', 'Laudo removido com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(receiveRemoveLaudo())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestLabelBonus() {
  return {
    type: REQUEST_LABELS_BONUS,
    isLoading: true,
  }
}

function receiveLabelBonus(data) {
  return {
    type: RECEIVE_LABELS_BONUS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Busca informações para legenda de bonificação.
 */
export function fetchLabelBonus() {
  return dispatch => {
    dispatch(requestLabelBonus())
    request.get(`${API_HOST}/laudo/labels-bonus`).then(json => {
      dispatch(receiveLabelBonus(json))
    }).catch(err => {
      dispatch(addToast('error', 'Erro ao buscar informações de legenda', null, true))
      dispatch(receiveLabelBonus(null))
    })
  }
}

export function resetAllLaudo() {
  return {
    type: RESET_ALL_LAUDO,
  }
}
