// third party
import { combineReducers } from 'redux'
import { createResponsiveStateReducer } from 'redux-responsive'
// application reducers
import app from './AppReducer'
import auth from './AuthReducer'
import aviso from './AvisoReducer'
import banco from './BancoReducer'
import empresa from './EmpresaReducer'
import encaminhamento from './EncaminhamentoReducer'
import endereco from './EnderecoReducer'
import cabine from './CabineReducer'
import certificado from './CertificadoReducer'
import clinica from './ClinicaReducer'
import config from './ConfigReducer'
import context from './ContextReducer'
import coupon from './CouponReducer'
import desktopNotification from './DesktopNotificationReducer'
import doenca from './DoencaReducer'
import especialidade from './EspecialidadeReducer'
import exame from './ExameReducer'
import estatistica from './EstatisticaReducer'
import equipamento from './EquipamentoReducer'
import equipe from './EquipeReducer'
import fatura from './FaturaReducer'
import ferramentas from './FerramentasReducer'
import filter from './FilterReducer'
import laudo from './LaudoReducer'
import paciente from './PacienteReducer'
import pagamento from './PagamentoReducer'
import plan from './PlanReducer'
import procedimento from './ProcedimentoReducer'
import prescricaoRenovacao from './PrescricaoRenovacaoReducer'
import plantao from './PlantaoReducer'
import plantaoUnico from './PlantaoUnicoReducer'
import prontuario from './ProntuarioReducer'
import questionario from './QuestionarioReducer'
import mail from './MailReducer'
import medico from './MedicoReducer'
import mensagem from './MensagemReducer'
import relatorio from './RelatorioReducer'
import sms from './SMSReducer'
import nota from './NotaReducer'
import teleconsulta from './TeleconsultaReducer'
import tecnico from './TecnicoReducer'
import toast from './ToastReducer'
import imageViewer from './ImageViewerReducer'
import users from './UsersReducer'
import vendedor from './VendedorReducer'
import whatsapp from './WhatsappReducer'
import windowResizable from './WindowResizableReducer'
// import websocket from './WebsocketReducer'

const RootReducer = combineReducers({
  app,
  auth,
  aviso,
  banco,
  doenca,
  empresa,
  encaminhamento,
  endereco,
  toast,
  cabine,
  certificado,
  clinica,
  config,
  context,
  coupon,
  desktopNotification,
  especialidade,
  exame,
  estatistica,
  equipamento,
  equipe,
  fatura,
  ferramentas,
  filter,
  laudo,
  paciente,
  pagamento,
  plan,
  procedimento,
  plantao,
  plantaoUnico,
  prontuario,
  prescricaoRenovacao,
  questionario,
  mail,
  medico,
  mensagem,
  relatorio,
  sms,
  nota,
  imageViewer,
  tecnico,
  teleconsulta,
  users,
  vendedor,
  whatsapp,
  windowResizable,
  // websocket,
  browser: createResponsiveStateReducer({
    extraSmall: 479,
    small: 767,
    medium: 1000,
    large: 1280,
    extraLarge: 1400,
  }),
})

export default RootReducer
