import { REQUEST_ALL_DOENCAS, RECEIVE_ALL_DOENCAS, RESET_ALL_DOENCAS } from '../constants/ActionTypes'

const INITIAL_STATE = {
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
}

export default function DoencaReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ALL_DOENCAS:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_DOENCAS:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_DOENCAS:
      return INITIAL_STATE
    default:
      return state
  }
}
