// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_CERTIFICADO_VIDAAS, RECEIVE_CERTIFICADO_VIDAAS, REQUEST_AUTHORIZE_VIDAAS, RECEIVE_AUTHORIZE_VIDAAS,
  REQUEST_VERIFY_PUSH_AUTHORIZED_VIDAAS, RECEIVE_VERIFY_PUSH_AUTHORIZED_VIDAAS
} from '../constants/ActionTypes'
// actions
import { showRequestError } from './AppActions'
import { fetchLoggedEntity } from './AuthActions'

function requestCertificadoVidaas(silent) {
  return {
    type: REQUEST_CERTIFICADO_VIDAAS,
    isLoading: !silent,
    isSilent: silent,
  }
}

function receiveCertificadoVidaas(data) {
  return {
    type: RECEIVE_CERTIFICADO_VIDAAS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Obtem informações do certificado A3 VIDaaS caso tenha algum token válido e não expirado.
 */
export function fetchCertificadoVidaas(callback = () => {}, silent = false) {
  return dispatch => {
    dispatch(requestCertificadoVidaas(silent))
    request.get(`${API_HOST}/vidaas`).then(json => {
      dispatch(receiveCertificadoVidaas(json))
      callback(null, json)
    }).catch(err => {
      dispatch(receiveCertificadoVidaas(null))
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestAuthorizeVidaas() {
  return {
    type: REQUEST_AUTHORIZE_VIDAAS,
    isLoading: true,
  }
}

function receiveAuthorizeVidaas(data) {
  return {
    type: RECEIVE_AUTHORIZE_VIDAAS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Solicita autorização de uso para o certificado VIDaaS.
 *
 * A autorização busca e-CPF com base no CPF do médico solicitante.
 *
 * @param {String} type Mode de validação 'qrcode' ou 'push'
 * @param {Number} hours Quantidade de horas para uso do ceertificado
 * @param {String} [cpf] Opcional para casos de 'clinica_medico' e 'medico' de telediagnóstico que não tenha esta informação apenas na primeira vez. Formato '000.000.000-00'
 */
 export function fetchVidaasAuhorize(type, hours, cpf = null, callback = () => {}) {
  return dispatch => {
    dispatch(requestAuthorizeVidaas())
    const params = cpf ? { cpf } : null
    request.get(`${API_HOST}/vidaas/authorize/${type}/${hours}`, params).then(json => {
      dispatch(receiveAuthorizeVidaas(json))
      callback(null, json)
      if (cpf) dispatch(fetchLoggedEntity(true))
    }).catch(err => {
      dispatch(receiveAuthorizeVidaas(null))
      // dispatch(showRequestError(err)) // o erro é mostrado no modal
      callback(err)
    })
  }
}

function requestVerifyPushAuthorizedVidaas(silent) {
  return {
    type: REQUEST_VERIFY_PUSH_AUTHORIZED_VIDAAS,
    isLoading: !silent,
    isSilent: silent,
  }
}

function receiveVerifyPushAuthorizedVidaas(data) {
  return {
    type: RECEIVE_VERIFY_PUSH_AUTHORIZED_VIDAAS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Verifica se uma solicitação de autorização push foi autorizada no APP VIDaaS.
 *
 * Este método deve ser chamado recusrsivamente após solicitação de autorização
 * por push para verificar se foi autorizado. Isso faz também com que seja
 * persistido o token no registro (não existe postback quando push).
 */
 export function fetchVerifyPushAuthorizedVidaas(callback = () => {}, silent = false) {
  return dispatch => {
    dispatch(requestVerifyPushAuthorizedVidaas(silent))
    request.get(`${API_HOST}/vidaas/verify-push-authorized`).then(json => {
      dispatch(receiveVerifyPushAuthorizedVidaas(json))
      callback(null, json)
    }).catch(err => {
      dispatch(receiveVerifyPushAuthorizedVidaas(null))
      callback(err)
    })
  }
}
