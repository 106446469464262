// third party
import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { responsiveStoreEnhancer } from 'redux-responsive'
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4'
// events
import WindowEvents from './events/WindowEvents'
// reducer
import RootReducer from './reducers/RootReducer'
// componets
import MainRouter from './components/MainRouter'
// middlewares
import { verifyLogged } from './middlewares/VerifyLogged'
import { bootstrap } from './middlewares/Bootstrap'
// import { offlineRequestsDiscard } from 'middlewares/OfflineRequestsDiscard'
import * as serviceWorker from './serviceWorker'

let history = createBrowserHistory()

// expoe globalemente
window.router = history

// inicializa google analytics
// ReactGA.initialize('UA-118255894-1')
ReactGA.initialize("G-LDVYRV28X1")

// atualiza rota no google analytics
history.listen(location =>  {
  // ReactGA.pageview(location.pathname)
  ReactGA.send('pageview')
  ReactGA.send({ hitType: "pageview", page: location.pathname })
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  RootReducer,
  composeEnhancer(
    applyMiddleware(
      thunk,
      verifyLogged,
      bootstrap,
    ),
    responsiveStoreEnhancer
  ),
)

WindowEvents(store.dispatch, store.getState)

render(
  <Provider store={store}>
    <Router history={history}>
      <MainRouter />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()
