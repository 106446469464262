// utils
import request from '../utils/request/Request'
// constants
import { UI_HOST, API_HOST } from '../constants/EnvTypes'
import { EXPIRED_SESSION_ERROR, EXPIRED_APP_VERSION_ERROR } from '../constants/ErrorTypes'
import {
  REQUEST_APP, RESET_ALL_APP, DISMISS_VERIFICAR_PAGAMENTO, DISMISS_CERTIFICATE_EXPIRE,
  APP_ONLINE, WINDOW_ACTIVE, WINDOW_VISIBLE, TOGGLE_CHAT_VISIBLE, TOGGLE_AVISOS_VISIBLE, UPDATE_NEW_CHAT_MESSAGES,
  REQUEST_CHAT_BROADCAST_MESSAGE, RECEIVE_CHAT_BROADCAST_MESSAGE, REQUEST_EMAIL_BROADCAST_MESSAGE,
  RECEIVE_EMAIL_BROADCAST_MESSAGE, SHOW_MESSAGE_MODAL, HIDE_MESSAGE_MODAL, CHANGE_WIZARD, REQUEST_WIZARD,
  RECEIVE_WIZARD, REQUEST_SAVE_WIZARD, RECEIVE_SAVE_WIZARD, DISMISS_EXAMES_AGUARDANDO_ENVIO,
  SHOW_POPUP_LAUDAR_SEM_CERTIFICADO
} from '../constants/ActionTypes'
import {
  UPDATE_LOGGED_ENTITY_INTERVAL, UPDATE_INFORMACOES_ADICIONAIS, UPDATE_INFORMACOES_ADICIONAIS_ADMIN, UPDATE_USER_ONLINE,
  VERIFY_USER_SESSION
} from '../constants/Timers'
// actions
import {
  fetchSignOut, fetchLoggedEntity, fetchEntityInformacoesAdicionais, notifyOnLine, verifyUserSession, resetAllAuth
} from './AuthActions'
import { fetchCertificado } from './ConfigActions'
import { fetchCertificadoVidaas } from './CertificadoActions'
import { requestPermission } from './NotificationActions'
import { resetAllClinica } from './ClinicaActions'
import { fetchAvisos, resetAllAviso } from './AvisoActions'
import { resetAllEndereco } from './EnderecoActions'
import { resetAllExame } from './ExameActions'
import { resetAllFatura } from './FaturaActions'
import { resetAllLaudo } from './LaudoActions'
import { resetAllMedico } from './MedicoActions'
import { resetAllNota } from './NotaActions'
import { resetAllPaciente } from './PacienteActions'
import { addToast } from './ToastActions'

let timerReloadLoggedEntity = null
let timerReloadInformacoesAdicionais = null
// let timerUserOnline = null
let timerVerifySession = null

export function showRequestError(err, requestId = null) {
  return dispatch => {
    // monta ID do erro
    const errorID = (requestId && requestId != 11003) ? `${requestId} - ` : ''

    switch (err.message) {
      case EXPIRED_SESSION_ERROR:
        dispatch(fetchSignOut(false))
        dispatch(addToast('info', errorID + 'Sessão expirada. Refaça o login', null, true))
        break
      case EXPIRED_APP_VERSION_ERROR:
        dispatch(fetchSignOut(true))
        dispatch(addToast('info', errorID + 'Aplicação atualizada para nova versão.', null, true))
        break
      default:
        if (requestId == 11003 || requestId == 21001) {
          dispatch(addToast('error', err.message, 'Entendi', false))
        } else {
          dispatch(addToast('error', errorID + err.message, null, true))
        }
        break
    }
  }
}

/**
 * Marca requsiçao dos dados do app como em andamento.
 */
function requestApp() {
  return {
    type: REQUEST_APP,
    dispatched: true,
  }
}

/**
 * Informações que devem ser carregadas no inicio do cíclo de vida da aplicação.
 */
export function fetchApp(loginType, loginProfile) {
  return dispatch => {
    dispatch(requestApp()) // informa que a requisição ja foi realizada uma vez

    if (loginType == 'clinica') {
      timerReloadLoggedEntity = setInterval(() => dispatch(fetchLoggedEntity(true)), UPDATE_LOGGED_ENTITY_INTERVAL)
      dispatch(fetchLoggedEntity(true))
      dispatch(fetchEntityInformacoesAdicionais())
      dispatch(fetchAvisos({}, (err, json) => {
        let countUnread = 0
        if (json && json.rows && json.rows.length) {
          for (const row of json.rows) {
            if (!row.aviso_visualizado) countUnread++
          }
          if (countUnread > 0) dispatch({
            type: TOGGLE_AVISOS_VISIBLE,
            avisosVisible: true
          })
        }
      }))

      // se clinica_medico (envio de exames) CARREGA INFO CERTIFICADOS
      if (loginProfile == 'clinica_medico') {
        dispatch(fetchCertificado())
        dispatch(fetchCertificadoVidaas())
      }

    } else if (loginType === 'medico' || loginType === 'tecnico') {
      dispatch(fetchLoggedEntity(true))
      dispatch(fetchEntityInformacoesAdicionais())
      dispatch(requestPermission()) // requisita permissão para notificações no desktop
      if (timerReloadInformacoesAdicionais) clearInterval(timerReloadInformacoesAdicionais)
      timerReloadInformacoesAdicionais = setInterval(() =>
        dispatch(fetchEntityInformacoesAdicionais(true)),
        UPDATE_INFORMACOES_ADICIONAIS
      )
      if (loginType === 'medico' && loginProfile == 'medico') {
        /*if (timerUserOnline) clearInterval(timerUserOnline)
        timerUserOnline = setInterval(() => {
          dispatch(notifyOnLine())
        }, UPDATE_USER_ONLINE)*/

        if (timerVerifySession) clearInterval(timerVerifySession)
        timerVerifySession = setInterval(() => {
          dispatch(verifyUserSession())
        }, VERIFY_USER_SESSION)
      }

      // se teleconsulta ou telediagnóstico CARREGA INFO CERTIFICADOS
      if (loginType === 'medico') {
        dispatch(fetchCertificado())
        dispatch(fetchCertificadoVidaas())
      }

    } else if (loginType === 'admin') {
      dispatch(fetchEntityInformacoesAdicionais())
      timerReloadInformacoesAdicionais = setInterval(() =>
        dispatch(fetchEntityInformacoesAdicionais(true)),
        UPDATE_INFORMACOES_ADICIONAIS_ADMIN
      )

    } else if (loginType === 'vendedor') {
      dispatch(fetchLoggedEntity(true))
      dispatch(fetchEntityInformacoesAdicionais(true))
    }
  }
}

/**
 * Reseta todos os states para o valor inicial.
 */
export function resetStates() {
  return (dispatch, getState) => {
    if (timerReloadLoggedEntity) clearInterval(timerReloadLoggedEntity)
    if (timerReloadInformacoesAdicionais) clearInterval(timerReloadInformacoesAdicionais)
    // if (timerUserOnline) clearInterval(timerUserOnline)
    if (timerVerifySession) clearInterval(timerVerifySession)
    dispatch(resetAllApp())
    dispatch(resetAllAviso())
    dispatch(resetAllClinica())
    dispatch(resetAllEndereco())
    dispatch(resetAllExame())
    dispatch(resetAllFatura())
    dispatch(resetAllLaudo())
    dispatch(resetAllMedico())
    dispatch(resetAllNota())
    dispatch(resetAllPaciente())
    dispatch(resetAllAuth())
  }
}

export function dismissImprimirFaturaNfse() {
  return {
    type: DISMISS_IMPRIMIR_FATURA_NFSE,
  }
}

export function fetchDismissImprimirFaturaNfse(callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/clinica/dismiss-invoice-nfse`
    request.put(url).then(json => {
      dispatch(fetchEntityInformacoesAdicionais())
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      callback()
    })
  }
}

export function dismissExamesAguardandoEnvio() {
  return {
    type: DISMISS_EXAMES_AGUARDANDO_ENVIO,
  }
}

export function dismissVerificarPagamento(data) {
  return {
    type: DISMISS_VERIFICAR_PAGAMENTO,
    data: data
  }
}

export function dismissCertificateExpire() {
  return {
    type: DISMISS_CERTIFICATE_EXPIRE,
  }
}

export function setShowPopupLaudarSemCertificado(data) {
  return {
    type: SHOW_POPUP_LAUDAR_SEM_CERTIFICADO,
    data,
  }
}

export function windowActive(active) {
  return {
    type: WINDOW_ACTIVE,
    active,
  }
}

export function windowVisible(visible) {
  return {
    type: WINDOW_VISIBLE,
    visible,
  }
}

export function onWindowLoad(e) {
  return (dispatch, getState) => {
    dispatch(navigatorOnLine())
    // dispatch(loadServiceWorker())
  }
}

export function navigatorOnLine(e) {
  return {
    type: APP_ONLINE,
    appOnline: navigator.onLine
  }
}

export function toggleChatVisible() {
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_CHAT_VISIBLE,
      chatVisible: ! getState().app.chatVisible
    })
  }
}

export function toggleAvisosVisible() {
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_AVISOS_VISIBLE,
      avisosVisible: ! getState().app.avisosVisible
    })
  }
}

export function updateNewChatMessages(qtdMessages) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_NEW_CHAT_MESSAGES,
      chatNewMessages: qtdMessages
    })
    // abre chat caso esteja fechado
    if (! getState().app.chatVisible && qtdMessages > 0) {
      dispatch({
        type: TOGGLE_CHAT_VISIBLE,
        chatVisible: true
      })
    }
  }
}

export function openChat() {
  return (dispatch, getState) => {
    let chatVisible = getState().app.chatVisible
    // abre chat caso esteja fechado
    if (!chatVisible) {
      dispatch({
        type: TOGGLE_CHAT_VISIBLE,
        chatVisible: true
      })
    }
    // reproduz som de alerta caso:
    // - janela não ativa
    // - chat fechado
    if (document.hidden || (!chatVisible)) {
      // play audio alert
      var audio = new Audio('/audio/notification.mp3')
      audio.play().catch(function(error) {
        console.log(error.message)
      })
    }
  }
}

export function requestChatBroadcastMessage() {
  return {
    type: REQUEST_CHAT_BROADCAST_MESSAGE,
    isLoading: true
  }
}

export function receiveChatBroadcastMessage() {
  return dispatch => {
    dispatch({
      type: RECEIVE_CHAT_BROADCAST_MESSAGE,
      isLoading: false
    })
    dispatch(addToast('success', 'Mensagem enviada com sucesso', null, true))
  }
}

export function fetchChatBroadcastMessage(ids, msg, callback) {
  return dispatch => {
    dispatch(requestChatBroadcastMessage())
    var data = { type: 'BROADCAST_MESSAGE', msg, ids }
    let iFrame = document.getElementById('chat-frame')
    iFrame.contentWindow.postMessage(data, UI_HOST)
    callback()
  }
}

export function requestEmailBroadcastMessage() {
  return {
    type: REQUEST_EMAIL_BROADCAST_MESSAGE,
    isLoading: true
  }
}

export function receiveEmailBroadcastMessage() {
  return dispatch => {
    dispatch({
      type: RECEIVE_EMAIL_BROADCAST_MESSAGE,
      isLoading: false
    })
    dispatch(addToast('success', 'Mensagem enviada com sucesso', null, true))
  }
}

export function fetchEmailBroadcastMessage(ids, subject, msg, callback) {
  return dispatch => {
    dispatch(requestEmailBroadcastMessage())
    const url = `${API_HOST}/aviso/broadcast-mail`
    const data = { ids, subject, mensagem: msg }
    request.post(url, {}, JSON.stringify(data)).then(json => {
      dispatch(receiveEmailBroadcastMessage())
      dispatch(addToast('success', 'E-mail(s) enviado(s) com sucesso.', null, true))
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveEmailBroadcastMessage())
      callback()
    })
  }
}

/*function loadServiceWorker() {
  return (dispatch, getState) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope)
      }).catch(function(err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err)
      })
    }
  }
}*/

export function showModalMessage(title, content) {
  return {
    type: SHOW_MESSAGE_MODAL,
    title,
    content
  }
}

export function hideModalMessage() {
  return {
    type: HIDE_MESSAGE_MODAL,
  }
}

export function changeWizard(payload) {
  return {
    type: CHANGE_WIZARD,
    payload
  }
}

export function requestWizard() {
  return {
    type: REQUEST_WIZARD,
    isLoading: true
  }
}

export function receiveWizard(data, invite, status) {
  return {
    type: RECEIVE_WIZARD,
    isLoading: false,
    data,
    invite,
    status,
  }
}

export function fetchWizard() {
  return dispatch => {
    dispatch(requestWizard())
    const url = `${API_HOST}/wizard`
    request.get(url).then(json => {
      const data = JSON.parse(json.content)
      const invite = json.invite ? JSON.parse(json.invite) : {}
      dispatch(receiveWizard(data, invite, json.status))
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveWizard())
    })
  }
}

export function requestSaveWizard() {
  return {
    type: REQUEST_SAVE_WIZARD,
    isLoading: true
  }
}

export function receiveSaveWizard() {
  return {
    type: RECEIVE_SAVE_WIZARD,
    isLoading: false
  }
}

export function fetchSaveWizard(step, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveWizard())
    const url = `${API_HOST}/wizard`
    request.post(url, {}, JSON.stringify({ step, data })).then(json => {
      console.log(json)
      dispatch(receiveSaveWizard())
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveWizard())
      callback(err)
    })
  }
}

export function fetchHelpByProfile(callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/help`
    request.get(url).then(json => {
      callback(null, json)
    }).catch(err => {
      callback(err)
    })
  }
}

export function resetAllApp() {
  return {
    type: RESET_ALL_APP,
  }
}
