import moment from 'moment'
// utils
import request from '../utils/request/Request'
// constants
import { API_AUTH } from '../constants/EnvTypes'
import { UNIMED_CEARA } from '../constants/PartnerId'
import {
  REQUEST_SIGN_IN, RECEIVE_SIGN_IN, RECEIVE_SIGN_IN_FAIL, RECEIVE_SIGN_OUT, REQUEST_LOGGED_ENTITY,
  RECEIVE_LOGGED_ENTITY, REQUEST_ENTITY_INFORMACOES_ADICIONAIS, RECEIVE_ENTITY_INFORMACOES_ADICIONAIS,
  REQUEST_CREDENTIALS, REQUEST_NOTIFY_ONLINE, RECEIVE_NOTIFY_ONLINE, REQUEST_VERIFY_USER_SESSION,
  RECEIVE_VERIFY_USER_SESSION, RESET_ALL_AUTH
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'
import { resetStates, fetchApp, dismissVerificarPagamento, changeWizard } from './AppActions'
import { fetchPublicClinicaContext } from './ContextActions'
import { fetchPlantaoQueueInfo } from './PlantaoUnicoActions'
// import { initWebsocket, closeWebsocket } from './WebsocketActions'
import { setLocalStorage, clearLocalStorage, setSessionStorage, clearSessionStorage } from './StorageActions'
// utils
import { showRequestError } from './AppActions'
import { parseJwt } from '../utils/JwtUtils'

export function requestSignIn() {
  return {
    type: REQUEST_SIGN_IN.type,
    isFetching: true,
    isLogged: false,
    jwt: '',
    jwtPayload: {
      user: {
        tipo: '',
        nome: '',
        email: '',
        usuario: '',
        profile: '',
        type: '',
      }
    },
    receivedAt: null,
  }
}

export function receiveSignIn(jwt, expiresInactivity, saveJWT = false) {
  // apenas quando fetchSwitchCredentials
  if (saveJWT) {
    // armazena jwt no storage
    setSessionStorage('jwt', jwt)
    setSessionStorage('expires_inactivity', expiresInactivity)
  }

  return {
    type: RECEIVE_SIGN_IN.type,
    isFetching: false,
    isLogged: true,
    jwt: jwt,
    expires_inactivity: expiresInactivity,
    jwtPayload: parseJwt(jwt),
    receivedAt: Date.now(),
  }
}

export function receiveSignInFail() {
  return {
    type: RECEIVE_SIGN_IN_FAIL.type,
    isFetching: false,
    isLogged: false,
  }
}

export function fetchSignIn(credentials, rememberme, callback = () => {}) {
  return dispatch => {
    dispatch(requestSignIn())
    const url = `${API_AUTH}/auth/sign-in`
    const options = {
      requestId: REQUEST_SIGN_IN.id,
      errorMessages: {
        400: 'Erro ao tentar realizar o login! Contate o administrador.',
        401: 'Usuário ou senha incorretos!',
        403: 'Sem permisão para acessar este recurso',
        404: 'Recurso não encontrado',
        500: 'Requisição retornou erro.'
      },
      logoutOn401: false
    }
    request.post(url, {}, JSON.stringify(credentials), options).then(json => {
      let jwt = json.token
      let expiresInactivity = json.expires_inactivity
      dispatch(addToast('success', 'Autenticação realizada com sucesso.', null, true))
      dispatch(receiveSignIn(jwt, expiresInactivity))
      // armazena jwt no session storage (da janela)
      setSessionStorage('jwt', jwt)
      setSessionStorage('expires_inactivity', expiresInactivity)
      // armazena jwt no local storage (de todas as janelas)
      if (rememberme) setLocalStorage('jwt', jwt)
      if (rememberme) setLocalStorage('expires_inactivity', expiresInactivity) // persiste jwt no local storage
      // dispatch(initWebsocket())
      const jwtPayload = parseJwt(jwt)
      dispatch(fetchApp(jwtPayload.user.type, jwtPayload.user.profile))
      callback(null, jwtPayload)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSignInFail())
      callback(err)
    })
  }
}

export function requestSignOut(reloadPage = false, fromVerifyLogged = false) {
  return (dispatch, getState) => {
    // obtem jwt para verificar se tem página de login personalizada (Unimed Ceará)
    const state = getState()
    let searchUrl = document.location.search
    if ('jwtPayload' in state.auth && 'user' in state.auth.jwtPayload && 'type' in state.auth.jwtPayload.user) {
      if (
          (state.auth.jwtPayload.user.type === 'clinica' && state.auth.jwtPayload.user.entityId === UNIMED_CEARA) ||
          (state.auth.jwtPayload.user.type === 'medico' && state.auth.jwtPayload.user.clinicaId === UNIMED_CEARA)
        ) {
        searchUrl = '?partner=unimed-ceara'
      }
    }

    // limpa sessionStorage
    clearLocalStorage()
    clearSessionStorage()
    if (typeof window != 'undefined') { // em ambiente de teste nao existe
      if (
        window.location.pathname.indexOf('/login') == -1 &&
        window.location.pathname.indexOf('/forgot-password') == -1
      ) {
        console.log('window.router.push(/login)')

        console.log('window.location.pathname', window.location.pathname)

        // Trata rotoas que voltam após o login (URL callback param)
        // ---------------------------------------------------------------------
        if (
          fromVerifyLogged &&
          (
            window.location.pathname.indexOf('/teleconsulta/detail/') != -1 ||
            window.location.pathname.indexOf('/prescricao-renovacao/detail/') != -1
          )
        ) {
          let pathname = window.location.pathname
          let search = window.location.search
          let currentURL = `${pathname}${search}`
          const callback = window.encodeURIComponent(currentURL)
          const callbackURLParam = (searchUrl.indexOf('?') != -1) ? `&callback=${callback}` : `?callback=${callback}`
          searchUrl = searchUrl + callbackURLParam
        }
        // ---------------------------------------------------------------------

        window.router.push('/login' + searchUrl)

        if (reloadPage) window.location.reload(true) // recarrega página para limpar cache
      }
    }
    dispatch(() => ({
      type: RECEIVE_SIGN_OUT.type,
      isFetching: false,
      isLogged: false,
      jwt: '',
      jwtPayload: {
        user: {
          tipo: '',
          nome: '',
          email: '',
          usuario: '',
          profile: '',
          type: '',
        }
      },
      expiration: null,
      receivedAt: null,
    }))
  }
}

function requestLoggedEntity(silent) {
  return {
    type: REQUEST_LOGGED_ENTITY.type,
    isLoading: (silent) ? false : true,
    isSilent: silent,
  }
}

function receiveLoggedEntity(data) {
  console.log('receiveLoggedEntity', data)
  return {
    type: RECEIVE_LOGGED_ENTITY.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega dados da entidade logada.
 *
 * @param {Boolean} silent Indica se o carregamento deve ser silencioso (não exibir carregando).
 */
export function fetchLoggedEntity(silent = false, callback = () => {}) {
  return (dispatch, getState) => {
    let state = getState()
    const isLogged = state.auth.isLogged
    if (isLogged) {
      dispatch(requestLoggedEntity(silent))
      const url = `${API_AUTH}/auth/logged-entity`

      request.get(url, {}, { requestId: REQUEST_LOGGED_ENTITY.id }).then(json => {
        dispatch(receiveLoggedEntity(json))
        if (json.type === 'clinica' && (json.tem_teleconsulta === 1)) {
          dispatch(fetchPublicClinicaContext(json.id))
        } else if (json.type === 'medico' && (json.tem_teleconsulta === 1)) {
          dispatch(fetchPublicClinicaContext(json.id_clinica, { idMedico: json.id }))
        }
        callback(true)
      }).catch(err => {
        dispatch(showRequestError(err))
        callback(false)
    })
    } else {
      console.log('DISCARD fetchLoggedEntity')
    }
  }
}

function requestEntityInformacoesAdicionais(userType, silent) {
  return {
    type: REQUEST_ENTITY_INFORMACOES_ADICIONAIS.type,
    userType: userType,
    isLoading: (silent) ? false : true,
    isSilent: silent,
  }
}

function receiveEntityInformacoesAdicionais(userType, data) {
  return {
    type: RECEIVE_ENTITY_INFORMACOES_ADICIONAIS.type,
    isLoading: false,
    userType: userType,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega informações adicionais da entidade logada
 *
 * @param {Boolean} silent Indica se o carregamento deve ser silencioso (não exibir carregando).
 */
export function fetchEntityInformacoesAdicionais(silent = true, callback = () => {}) {
  return (dispatch, getState) => {
    let state = getState()
    const isLogged = state.auth.isLogged
    let appOnline = state.app.appOnline
    if (appOnline && isLogged) {
      let userType = state.auth.jwtPayload.user.type
      dispatch(requestEntityInformacoesAdicionais(userType, silent))
      const url = `${API_AUTH}/auth/informacoes-adicionais`
      request.get(url, {}, { requestId: REQUEST_ENTITY_INFORMACOES_ADICIONAIS.id }).then(json => {
        if (['medico', 'clinica'].includes(userType)  && 'wizardStatus' in json && ['waiting', 'started', 'validation', 'rectify'].includes(json.wizardStatus)) {
          console.log('WIZARD PENDENTE')
          dispatch(changeWizard({ show: true }))
          window.router.push('/wizard')
        } else if (userType == 'medico' && state.auth.jwtPayload.user.profile == 'medico_clinica' && state.auth.loggedEntity.data && state.auth.loggedEntity.data.atende_teleconsulta_plantao_unico == 1) {
          dispatch(fetchPlantaoQueueInfo(true))
        }
        dispatch(receiveEntityInformacoesAdicionais(userType, json))
        // reseta para que a mensagem seja apresentada novamente caso exista pendências
        dispatch(dismissVerificarPagamento(false))
        callback(true)
      }).catch(err => {
        dispatch(showRequestError(err))
        callback(false)
      })
    } else {
      console.log('DISCARD fetchEntityInformacoesAdicionais')
    }
  }
}

function requestNotifyOnLine() {
  return {
    type: REQUEST_NOTIFY_ONLINE,
    isLoading: true,
  }
}

function receiveNotifyOnLine(notificationOk) {
  return {
    type: RECEIVE_NOTIFY_ONLINE,
    isLoading: false,
    receivedAt: notificationOk ? Date.now() : null,
  }
}

/**
 * Iforma a api-auth que o usuário está online (logado e com a tela visível)
 *
 * @deprecated
 */
export function notifyOnLine() {
  return (dispatch, getState) => {
    let state = getState()
    let appOnline = state.app.appOnline
    if (appOnline) {
      const state = getState()

      // verifica se logado
      const isLogged = state.auth.isLogged
      if (!isLogged) {
        console.log('Nao logado. ABORTA...')
        return
      }

      // verifica se ainda está carregando
      const isLoading = state.auth.notifyOnline.isLoading
      if (isLoading) {
        console.log('Aguardando pooling anterior. ABORTA...')
        return
      }

      const userType = state.auth.jwtPayload.user ? state.auth.jwtPayload.user.type : ''
      const userProfile = state.auth.jwtPayload.user ? state.auth.jwtPayload.user.profile : ''
      if (userType !== 'medico'/* || userProfile !== 'medico'*/) {
        console.log('Nao medico. ABORTA...')
        return
      }

      // verifica se está na hora de notificar (ultima notificacao )
      const lastNotification = state.auth.notifyOnline.receivedAt
      if (lastNotification) {
        const now = moment.utc(new Date())
        const last = moment.utc(lastNotification)
        const elapsedSeconds = moment.duration(now.diff(last)).asSeconds()
        console.log('elapsedSeconds', elapsedSeconds)
        console.log('expires_inactivity', state.auth.expires_inactivity)
        // se passou mais de 10min expira sessao e redireciona p/ login
        if (state.auth.expires_inactivity && elapsedSeconds >= Number(state.auth.expires_inactivity) && userProfile == 'medico') {
          console.log('Expirou tempo de atividade. EXPIRA autenticação...')
          dispatch(fetchSignOut())
          return
        }

        // verifica se janela NAO está visível
        const windowVisible = state.app.windowVisible
        if (windowVisible !== 'visible') {
          console.log('Janela nao visivel. ABORTA...')
          return
        }

        // se passou mais de 1min notifica online
        if (elapsedSeconds <= 60) {
          console.log('Nao atingiu tempo de espera. ABORTA...')
          return
        }
      }

      dispatch(requestNotifyOnLine())
      console.log('NOTIFY ONLINE >>>>>>')
      const url = `${API_AUTH}/auth/notify-online`
      request.get(url).then(json => {
        console.log('NOTIFY ONLINE <<<<<<')
        dispatch(receiveNotifyOnLine(true))
      }).catch(err => {
        console.error(err)
        dispatch(receiveNotifyOnLine(false))
      })
    } else {
      console.log('DISCARD notifyOnLine')
    }
  }
}

function requestVerifyUserSession() {
  return {
    type: REQUEST_VERIFY_USER_SESSION,
    isLoading: true,
  }
}

function receiveVerifyUserSession(notificationOk) {
  return {
    type: RECEIVE_VERIFY_USER_SESSION,
    isLoading: false,
    receivedAt: notificationOk ? Date.now() : null,
  }
}

/**
 * Verifica se "sessão" deve ser expirada.
 * Apenas usuário médico se não laudou nos últimos 30min é deslogado.
 */
export function verifyUserSession() {
  return (dispatch, getState) => {
    let state = getState()
    let appOnline = state.app.appOnline
    if (appOnline) {
      const state = getState()

      // verifica se logado
      const isLogged = state.auth.isLogged
      if (!isLogged) {
        console.log('Nao logado. ABORTA...')
        return
      }

      // verifica se ainda está carregando
      const isLoading = state.auth.verifyUserSession.isLoading
      if (isLoading) {
        console.log('Aguardando pooling anterior. ABORTA...')
        return
      }

      const userType = state.auth.jwtPayload.user ? state.auth.jwtPayload.user.type : ''
      const userProfile = state.auth.jwtPayload.user ? state.auth.jwtPayload.user.profile : ''
      if (userType !== 'medico' || userProfile !== 'medico') {
        console.log('Nao medico. ABORTA...')
        return
      }

      // verifica se está na hora de verificar (ultima verificação)
      const lastNotification = state.auth.verifyUserSession.receivedAt
      if (lastNotification) {
        const now = moment.utc(new Date())
        const last = moment.utc(lastNotification)
        const elapsedSeconds = moment.duration(now.diff(last)).asSeconds()

        // se passou mais de 1min notifica online
        if (elapsedSeconds <= 60) {
          console.log('Nao atingiu tempo de espera. ABORTA...')
          return
        }
      }

      dispatch(requestVerifyUserSession())
      console.log('VERIFY USER SESSION >>>>>>')
      const url = `${API_AUTH}/auth/verify-expire`
      request.get(url).then(json => {
        console.log('VERIFY USER SESSION <<<<<<')
        console.log(json)

        // desloga
        if (json && 'valid_session' in json && json.valid_session == false) {
          dispatch(fetchSignOut())
        }

        dispatch(receiveVerifyUserSession(true))
      }).catch(err => {
        console.error(err)
        console.log(err.message)
        if (err.message == 'EXPIRED_SESSION_ERROR') {
          dispatch(fetchSignOut(true))
        }
        dispatch(receiveVerifyUserSession(false))
      })
    } else {
      console.log('DISCARD verifyUserSession')
    }
  }
}

/**
 * Obtem credenciais de acesso para abertura de área da clinica pelo administrador.
 *
 * @param {String} entity
 * @param {Number} id da entidade
 * @param {Function} callback função de callback
 */
export function fetchCredentials(entity, id, callback) {
  return dispatch => {
    const url = `${API_AUTH}/auth/credentials`
    request.post(url, {}, JSON.stringify({ entity, id }), { requestId: REQUEST_CREDENTIALS.id }).then(json => {
      dispatch(addToast('success', 'Aberto em nova aba.', null, true))
      let jwt = json.token
      callback(null, jwt)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

/**
 * Obtem credenciais do user oposto ao atual. (medico => clinica, clinica => médico).
 *
 * @param {Function} callback função de callback
 */
export function fetchSwitchCredentials(callback = () => {}) {
  return dispatch => {
    const url = `${API_AUTH}/auth/switch-credentials`
    request.post(url).then(json => {
      let jwt = json.token
      dispatch(receiveSignIn(jwt, null, true))
      // if (rememberme) setLocalStorage('jwt', jwt) // persiste jwt no local storage
      const jwtPayload = parseJwt(jwt)
      dispatch(fetchApp(jwtPayload.user.type, jwtPayload.user.profile))
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        window.router.push('/')
      }
      callback(null)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}


export function fetchSignOut(reloadPage = false) {
  return dispatch => {
    // dispatch(addToast('success', 'Sessão encerrada.', null, true))
    dispatch(requestSignOut(reloadPage))
    // dispatch(closeWebsocket())
    dispatch(resetStates())
  }
}

export function resetAllAuth() {
  return {
    type: RESET_ALL_AUTH,
  }
}
