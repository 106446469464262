// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_BOLETOS, RECEIVE_BOLETOS, REQUEST_BOLETO, RECEIVE_BOLETO, REQUEST_BALANCO_ANUAL, RECEIVE_BALANCO_ANUAL,
  REQUEST_ATUALIZAR_VENCIMENTO, RECEIVE_ATUALIZAR_VENCIMENTO, REQUEST_PRORROGAR_VENCIMENTO, RECEIVE_PRORROGAR_VENCIMENTO,
  REQUEST_INFORMAR_PAGAMENTO, RECEIVE_INFORMAR_PAGAMENTO, REQUEST_REMESSAS, RECEIVE_REMESSAS, REQUEST_RETORNOS,
  RECEIVE_RETORNOS, REQUEST_CAUSAS_REJEICAO_BANCO, RECEIVE_CAUSAS_REJEICAO_BANCO, REQUEST_STATUS_BANCO,
  RECEIVE_STATUS_BANCO, REQUEST_GENERATE_REMESSA, RECEIVE_GENERATE_REMESSA, REQUEST_PROCESS_RETORNO,
  RECEIVE_PROCESS_RETORNO, REQUEST_STATUS, RECEIVE_STATUS, RESET_ALL_FATURA, REQUEST_CONFIRMAR_PAGAMENTO,
  RECEIVE_CONFIRMAR_PAGAMENTO, REQUEST_REJEITAR_PAGAMENTO, RECEIVE_REJEITAR_PAGAMENTO, REQUEST_CREATE_FATURA,
  REQUEST_UPDATE_FATURA, RECEIVE_UPDATE_FATURA, REQUEST_FATURA_PIX, RECEIVE_FATURA_PIX, REQUEST_CANCELAR_FATURA,
  RECEIVE_CANCELAR_FATURA, REQUEST_FATURAS_VENCIDAS, RECEIVE_FATURAS_VENCIDAS, REQUEST_PORTESTO_SOLICITAR,
  RECEIVE_PORTESTO_SOLICITAR, REQUEST_PORTESTO_CANCELAR, RECEIVE_PORTESTO_CANCELAR
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'
import { showRequestError } from './AppActions'


function requestFaturas() {
  return {
    type: REQUEST_BOLETOS.type,
    isLoading: true,
  }
}

function receiveFaturas(data) {
  return {
    type: RECEIVE_BOLETOS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchFaturas(params = { page: 1, limit: 50, orderBy: 'data', orderDirection: 'DESC' }, callback = () => {}) {
  return dispatch => {
    dispatch(requestFaturas())
    const url = `${API_HOST}/fatura`
    request.get(url, params, { requestId: REQUEST_BOLETOS.id }).then(json => {
      dispatch(receiveFaturas(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestFatura(silent) {
  console.log('requestFatura', silent)
  return {
    type: REQUEST_BOLETO.type,
    isLoading: silent ? false : true,
    isSilent: silent,
  }
}

function receiveFatura(data) {
  return {
    type: RECEIVE_BOLETO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchFatura(id, silent = false, callback = () => {}) {
  return dispatch => {
    dispatch(requestFatura(silent))
    const url = `${API_HOST}/fatura/${id}`
    request.get(url, {}, { requestId: REQUEST_BOLETO.id }).then(json => {
      dispatch(receiveFatura(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestBalancoAnual() {
  return {
    type: REQUEST_BALANCO_ANUAL.type,
    isLoading: true,
  }
}

function receiveBalancoAnual(data) {
  return {
    type: RECEIVE_BALANCO_ANUAL.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchBalancoAnual(callback = () => {}) {
  return (dispatch, getState) => {
    // se ja carregou uma vez, não busca novamente
    const state = getState()
    if (state.fatura.balancoAnual.data.length > 0) {
      return Promise.resolve()
    }
    dispatch(requestBalancoAnual())
    const url = `${API_HOST}/fatura/balanco/anual`
    request.get(url, {}, { requestId: REQUEST_BALANCO_ANUAL.id }).then(json => {
      dispatch(receiveBalancoAnual(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestUpdateFatura() {
  return {
    type: REQUEST_UPDATE_FATURA.type,
    isLoading: true,
  }
}

function receiveUpdateFatura(data) {
  return {
    type: RECEIVE_UPDATE_FATURA.type,
    isLoading: false,
  }
}

export function fetchUpdateFatura(idFatura, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestUpdateFatura())
    const url = `${API_HOST}/fatura/${idFatura}`
    request.put(url, {}, JSON.stringify(data), { requestId: REQUEST_UPDATE_FATURA.id }).then(json => {
      dispatch(receiveUpdateFatura(json))
      dispatch(addToast('success', 'Status da fatura atualizado com sucesso.', null, true))
      dispatch(fetchFatura(idFatura))
    }).catch(err => {
      dispatch(receiveUpdateFatura())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Cria uma fatura avulsa para um determinada clinica.
 */
export function fetchCreateFatura(data, callback = () => {}) {
  return (dispatch) => {
    const url = `${API_HOST}/fatura`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_CREATE_FATURA.id }).then(json => {
      dispatch(receiveBalancoAnual(json))
      dispatch(addToast('success', 'Fatura criada com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestAtualizarVencimento() {
  return {
    type: REQUEST_ATUALIZAR_VENCIMENTO.type,
    isLoading: true,
  }
}

function receiveAtualizarVencimento(data) {
  return {
    type: RECEIVE_ATUALIZAR_VENCIMENTO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchAtualizarVencimento(idFatura, callback = () => {}) {
  return dispatch => {
    dispatch(requestAtualizarVencimento())
    const url = `${API_HOST}/fatura/${idFatura}/atualizar-vencimento`
    request.get(url, {}, { requestId: REQUEST_ATUALIZAR_VENCIMENTO.id }).then(json => {
      dispatch(receiveAtualizarVencimento(json))
      dispatch(addToast('success', 'Fatura gerada com sucesso com novo vencimento!', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestProrrogarVencimento() {
  return {
    type: REQUEST_PRORROGAR_VENCIMENTO,
    isLoading: true,
  }
}

function receiveProrrogarVencimento(data) {
  return {
    type: RECEIVE_PRORROGAR_VENCIMENTO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Solicita prorrogação de boleto SICCOB via API.
 *
 * @param {Number} idFatura
 * @param {String} dataVencimento Nova data de vencimento no formato 'YYYY-DD-MM'
 * @param {Function} callback
 */
export function fetchProrrogarVencimento(idFatura, dataVencimento, callback = () => {}) {
  return dispatch => {
    dispatch(requestProrrogarVencimento())
    const url = `${API_HOST}/fatura/${idFatura}/prorrogar-vencimento`
    request.put(url, {}, { data_vencimento: dataVencimento }).then(json => {
      dispatch(receiveProrrogarVencimento(json))
      dispatch(addToast('success', 'Data de vencimento prorrogada com sucesso!', null, true))
      callback()
    }).catch(err => {
      dispatch(receiveProrrogarVencimento())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestProtestoSolicitar() {
  return {
    type: REQUEST_PORTESTO_SOLICITAR,
    isLoading: true,
  }
}

function receiveProtestoSolicitar(data) {
  return {
    type: RECEIVE_PORTESTO_SOLICITAR,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Solicita protesto de boleto SICCOB via API.
 *
 * @param {Number} idFatura
 * @param {Function} callback
 */
export function fetchProtestoSolicitar(idFatura, callback = () => {}) {
  return dispatch => {
    dispatch(requestProtestoSolicitar())
    const url = `${API_HOST}/fatura/${idFatura}/protesto-solicitar`
    request.put(url).then(json => {
      dispatch(receiveProtestoSolicitar(json))
      dispatch(addToast('success', 'Protesto solicitado com sucesso!', null, true))
      callback()
    }).catch(err => {
      dispatch(receiveProtestoSolicitar())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestProtestoCancelar() {
  return {
    type: REQUEST_PORTESTO_CANCELAR,
    isLoading: true,
  }
}

function receiveProtestoCancelar(data) {
  return {
    type: RECEIVE_PORTESTO_CANCELAR,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Cancela solicitação ou remove protesto já efetivado de boleto SICCOB via API.
 *
 * @param {Number} idFatura
 * @param {Function} callback
 */
export function fetchProtestoCancelar(idFatura, callback = () => {}) {
  return dispatch => {
    dispatch(requestProtestoCancelar())
    const url = `${API_HOST}/fatura/${idFatura}/protesto-cancelar`
    request.put(url).then(json => {
      dispatch(receiveProtestoCancelar(json))
      dispatch(addToast('success', 'Protesto cancelado com sucesso!', null, true))
      callback()
    }).catch(err => {
      dispatch(receiveProtestoCancelar())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestCancelar() {
  return {
    type: REQUEST_CANCELAR_FATURA.type,
    isLoading: true,
  }
}

function receiveCancelar(data) {
  return {
    type: RECEIVE_CANCELAR_FATURA.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchCancelar(idFatura, callback = () => {}) {
  return dispatch => {
    dispatch(requestCancelar())
    const url = `${API_HOST}/fatura/${idFatura}/cancelar`
    request.put(url, {}, { requestId: REQUEST_CANCELAR_FATURA.id }).then(json => {
      dispatch(receiveCancelar(json))
      dispatch(addToast('success', 'Fatura cancelada com sucesso!', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Abre nova guia com boleto para impressão.
 * Não altera estado da aplicação.
 *
 * @param {Number} idFatura
 */
export function fetchPrintBoleto(idFatura) {
  return (dispatch, getState) => {
    let state = getState()
    let jwt = state.auth.jwt
    let url = `${API_HOST}/fatura/${idFatura}/boleto?jwt=${jwt}`
    window.open(url, '_blank')
    dispatch(addToast('success', 'Boleto aberto em nova guia.', null, true))
  }
}

/**
 * Obtem código de barras do boleto.
 *
 * @param {Number} idFatura
 */
 export function fetchCodigoBarras(idFatura, callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/fatura/${idFatura}/codigo-barras`
    request.get(url).then(json => {
      // dispatch(addToast('success', 'Fatura gerada com sucesso com novo vencimento!', null, true))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestPix() {
  return {
    type: REQUEST_FATURA_PIX,
    isLoading: true,
  }
}

function receivePix(data) {
  return {
    type: RECEIVE_FATURA_PIX,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchFaturaPix(idFatura) {
  return dispatch => {
    dispatch(requestPix())
    const url = `${API_HOST}/fatura/${idFatura}/pix`
    request.get(url).then(json => {
      dispatch(receivePix(json))
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receivePix())
    })
  }
}

function requestInformarPagamento() {
  return {
    type: REQUEST_INFORMAR_PAGAMENTO.type,
    isLoading: true,
  }
}

function receiveInformarPagamento(data) {
  return {
    type: RECEIVE_INFORMAR_PAGAMENTO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchInformarPagamento(idFatura, data, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestInformarPagamento())
    const url = `${API_HOST}/fatura/${idFatura}/informar-pagamento`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_INFORMAR_PAGAMENTO.id }).then(json => {
      let loggedEntity = getState().auth.loggedEntity
      if (loggedEntity.data.prepago == 1) {
        dispatch(addToast('success', 'Aguarde receber email c/ confirmação do pagamento para liberar o sistema.', null, true))
      } else {
        dispatch(addToast('success', 'Seu comprovante foi enviado com sucesso.', null, true))
      }
      dispatch(receiveInformarPagamento(json))
      dispatch(fetchFatura(idFatura)) // recarrega fatura após atualização
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestConfirmarPagamento() {
  return {
    type: REQUEST_CONFIRMAR_PAGAMENTO.type,
    isLoading: true,
  }
}

function receiveConfirmarPagamento(data) {
  return {
    type: RECEIVE_CONFIRMAR_PAGAMENTO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchConfirmarPagamento(idFatura, callback = () => {}) {
  return dispatch => {
    dispatch(requestConfirmarPagamento())
    const url = `${API_HOST}/fatura/${idFatura}/confirmar-pagamento`
    request.put(url, {}, '', { requestId: REQUEST_CONFIRMAR_PAGAMENTO.id }).then(json => {
      dispatch(addToast('success', 'Pagamento confirmado com sucesso.', null, true))
      dispatch(receiveConfirmarPagamento(json))
      dispatch(fetchFatura(idFatura)) // recarrega fatura após atualização
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRejeitarPagamento() {
  return {
    type: REQUEST_REJEITAR_PAGAMENTO.type,
    isLoading: true,
  }
}

function receiveRejeitarPagamento(data) {
  return {
    type: RECEIVE_REJEITAR_PAGAMENTO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchRejeitarPagamento(idFatura, callback = () => {}) {
  return dispatch => {
    dispatch(requestRejeitarPagamento())
    const url = `${API_HOST}/fatura/${idFatura}/rejeitar-pagamento`
    request.put(url, {}, '', { requestId: REQUEST_REJEITAR_PAGAMENTO.id }).then(json => {
      dispatch(addToast('success', 'Pagamento rejeitado com sucesso.', null, true))
      dispatch(receiveRejeitarPagamento(json))
      dispatch(fetchFatura(idFatura)) // recarrega fatura após atualização
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRemessas() {
  return {
    type: REQUEST_REMESSAS.type,
    isLoading: true,
  }
}

function receiveRemessas(data) {
  return {
    type: RECEIVE_REMESSAS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchRemessas(params = { page: 1, limit: 50, orderBy: 'id', orderDirection: 'DESC' }, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemessas())
    const url = `${API_HOST}/fatura/remessas`
    request.get(url, params, { requestId: REQUEST_REMESSAS.id }).then(json => {
      dispatch(receiveRemessas(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestGenerateRemessa() {
  return {
    type: REQUEST_GENERATE_REMESSA.type,
    isLoading: true,
  }
}

function receiveGenerateRemessa() {
  return {
    type: RECEIVE_GENERATE_REMESSA.type,
    isLoading: false,
  }
}

export function fetchGenerateRemessa(callback = () => {}) {
  return dispatch => {
    dispatch(requestGenerateRemessa())
    const url = `${API_HOST}/fatura/generate-remessa`
    request.post(url, {}, '', { requestId: REQUEST_GENERATE_REMESSA.id }).then(json => {
      dispatch(receiveGenerateRemessa(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveGenerateRemessa({}))
      callback(false)
    })
  }
}

function requestRetornos() {
  return {
    type: REQUEST_RETORNOS.type,
    isLoading: true,
  }
}

function receiveRetornos(data) {
  return {
    type: RECEIVE_RETORNOS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchRetornos(params = { page: 1, limit: 50, orderBy: 'id', orderDirection: 'DESC' }, callback = () => {}) {
  return dispatch => {
    dispatch(requestRetornos())
    const url = `${API_HOST}/fatura/retornos`
    request.get(url, params, { requestId: REQUEST_RETORNOS.id }).then(json => {
      dispatch(receiveRetornos(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestProcessRetorno() {
  return {
    type: REQUEST_PROCESS_RETORNO.type,
    isLoading: true,
  }
}

function receiveProcessRetorno() {
  return {
    type: RECEIVE_PROCESS_RETORNO.type,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

export function fetchProcessRetorno(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestProcessRetorno())
    const url = `${API_HOST}/fatura/process-retorno`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_PROCESS_RETORNO.id }).then(json => {
      dispatch(receiveProcessRetorno())
      dispatch(fetchRetornos()) // recarrega listagem
      // apresenta mensagem
      if ('result' in json && 'arrErr' in json.result && json.result.arrErr.length > 0) {
        dispatch(addToast('error', `Arquivo de retorno carregado, porém os nossos números ${json.result.arrErr.join(', ')} não foram encontrados. FAVOR VERIFICAR!'`, 'fechar', false))
      } else {
        dispatch(addToast('success', 'Arquivo de retorno carregado com sucesso.', null, true))
      }
      callback(true)
    }).catch(err => {
      dispatch(receiveProcessRetorno())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestCausasRejeicaoBanco() {
  return {
    type: REQUEST_CAUSAS_REJEICAO_BANCO.type,
    isLoading: true,
  }
}

function receiveCausasRejeicaoBanco(data) {
  return {
    type: RECEIVE_CAUSAS_REJEICAO_BANCO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchCausasRejeicaoBanco(callback = () => {}) {
  return (dispatch, getState) => {
    // se ja carregou uma vez, não busca novamente
    const state = getState()
    if (state.fatura.causasRejeicaoBanco.data.length > 0) {
      return Promise.resolve()
    }
    dispatch(requestCausasRejeicaoBanco())
    const url = `${API_HOST}/fatura/causas-rejeicao-banco`
    request.get(url, {}, { requestId: REQUEST_CAUSAS_REJEICAO_BANCO.id }).then(json => {
      dispatch(receiveCausasRejeicaoBanco(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestStatus() {
  return {
    type: REQUEST_STATUS.type,
    isLoading: true,
  }
}

function receiveStatus(data) {
  return {
    type: RECEIVE_STATUS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchStatus(callback = () => {}) {
  return (dispatch, getState) => {
    // se ja carregou uma vez, não busca novamente
    const state = getState()
    if (state.fatura.status.data.length > 0) {
      return Promise.resolve()
    }
    dispatch(requestStatus())
    const url = `${API_HOST}/fatura/status`
    request.get(url, {}, { requestId: REQUEST_STATUS.id }).then(json => {
      dispatch(receiveStatus(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestStatusBanco() {
  return {
    type: REQUEST_STATUS_BANCO.type,
    isLoading: true,
  }
}

function receiveStatusBanco(data) {
  return {
    type: RECEIVE_STATUS_BANCO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchStatusBanco(callback = () => {}) {
  return (dispatch, getState) => {
    // se ja carregou uma vez, não busca novamente
    const state = getState()
    if (state.fatura.statusBanco.data.length > 0) {
      return Promise.resolve()
    }
    dispatch(requestStatusBanco())
    const url = `${API_HOST}/fatura/status-banco`
    request.get(url, {}, { requestId: REQUEST_STATUS_BANCO.id }).then(json => {
      dispatch(receiveStatusBanco(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

export function fetchDownloadRemessa(idRemessa) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/fatura/remessa/${idRemessa}/download?jwt=${jwt}`
    window.open(url, '_blank')
  }
}

export function fetchDownloadRetorno(idRetorno) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/fatura/retorno/${idRetorno}/download?jwt=${jwt}`
    window.open(url, '_blank')
  }
}

function requestFaturasVencidas() {
  return {
    type: REQUEST_FATURAS_VENCIDAS,
    isLoading: true,
  }
}

function receiveFaturasVencidas(data) {
  return {
    type: RECEIVE_FATURAS_VENCIDAS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchFaturasVencidas() {
  return dispatch => {
    dispatch(requestFaturasVencidas())
    const url = `${API_HOST}/fatura/vencidas`
    request.get(url).then(json => {
      dispatch(receiveFaturasVencidas(json))
    }).catch(err => {
      dispatch(receiveFaturasVencidas([]))
    })
  }
}

export function resetAllFatura() {
  return {
    type: RESET_ALL_FATURA.type,
  }
}
