import {
  REQUEST_PUBLIC_CLINICA_CONTEXT, RECEIVE_PUBLIC_CLINICA_CONTEXT, RESET_ALL_CONTEXT
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  clinicaPublicContext: {
    isLoading: false,
    context: null,
    linkClinica: null,
    linkMedico: null,
  },
}

export default function ContextReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_PUBLIC_CLINICA_CONTEXT.type:
      return {
        ...state,
        clinicaPublicContext: {
          isLoading: action.isLoading,
          context: state.clinicaPublicContext.context,
          linkClinica: state.clinicaPublicContext.linkClinica,
          linkMedico: state.clinicaPublicContext.linkMedico,
        }
      }
    case RECEIVE_PUBLIC_CLINICA_CONTEXT.type:
      return {
        ...state,
        clinicaPublicContext: {
          isLoading: action.isLoading,
          context: action.context || state.clinicaPublicContext.context,
          linkClinica: action.linkClinica || state.clinicaPublicContext.linkClinica,
          linkMedico: action.linkMedico || state.clinicaPublicContext.linkMedico,
        }
      }
    case RESET_ALL_CONTEXT.type:
      return INITIAL_STATE
    default:
      return state
  }
}
