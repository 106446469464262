import {
  REQUEST_CERTIFICADO_VIDAAS, RECEIVE_CERTIFICADO_VIDAAS, REQUEST_AUTHORIZE_VIDAAS, RECEIVE_AUTHORIZE_VIDAAS,
  REQUEST_VERIFY_PUSH_AUTHORIZED_VIDAAS, RECEIVE_VERIFY_PUSH_AUTHORIZED_VIDAAS
 } from '../constants/ActionTypes'

const INITIAL_STATE = {
  vidaas: {
    isLoading: false,
    data: null,
    receivedAt: null,
  },
  // futuros certificados AQUI ...
  vidaasAuthorize: {
    isLoading: false,
    data: null,
    receivedAt: null,
  },
  vidaasVerifyPushAuthorized: {
    isLoading: false,
    data: null,
    receivedAt: null,
  },
}

export default function CertificadoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_CERTIFICADO_VIDAAS:
      return {
        ...state,
        vidaas: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.vidaas.data : INITIAL_STATE.vidaas.data,
        }
      }
    case RECEIVE_CERTIFICADO_VIDAAS:
      return {
        ...state,
        vidaas: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_AUTHORIZE_VIDAAS:
      return {
        ...state,
        vidaasAuthorize: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.vidaasAuthorize.data,
        }
      }
    case RECEIVE_AUTHORIZE_VIDAAS:
      return {
        ...state,
        vidaasAuthorize: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_VERIFY_PUSH_AUTHORIZED_VIDAAS:
      return {
        ...state,
        vidaasVerifyPushAuthorized: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.vidaasVerifyPushAuthorized.data : INITIAL_STATE.vidaasVerifyPushAuthorized.data,
        }
      }
    case RECEIVE_VERIFY_PUSH_AUTHORIZED_VIDAAS:
      return {
        ...state,
        vidaasVerifyPushAuthorized: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    default:
      return state
  }
}
