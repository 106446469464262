// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_PUBLIC_CLINICA_CONTEXT, RECEIVE_PUBLIC_CLINICA_CONTEXT, RESET_ALL_CONTEXT
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'

function requestPublicClinicaContext() {
  return {
    type: REQUEST_PUBLIC_CLINICA_CONTEXT.type,
    isLoading: true,
  }
}

function receivePublicClinicaContext(data) {
  return {
    type: RECEIVE_PUBLIC_CLINICA_CONTEXT.type,
    isLoading: false,
    ...data
  }
}

/**
 * Carrega link de contexto da clinica e do médico caso informado em aditionals.
 *
 * @param {Number} idClinica
 * @param {Object} additionals Objeto opcional ontendo idMedico caso seja médico para buscar os dois links. Ex: { idMedico: 1 }
 */
export function fetchPublicClinicaContext(idClinica, additionals) {
  return dispatch => {
    dispatch(requestPublicClinicaContext())
    let query = ''
    if (additionals) query = `?additionals=${encodeURIComponent(JSON.stringify(additionals))}`
    request.get(`${API_HOST}/context/clinica/${idClinica}/public${query}`).then(json => {
      dispatch(receivePublicClinicaContext(json))
    }).catch(err => {
      console.error(err)
      // dispatch(addToast('error', 'Erro ao obter URL pública da clínica', null, true))
      dispatch(receivePublicClinicaContext(null))
    })
  }
}

export function resetAllContext() {
  return {
    type: RESET_ALL_CONTEXT.type,
  }
}
