import {
  REQUEST_PRESCRICOES_RENOVACAO, RECEIVE_PRESCRICOES_RENOVACAO, REQUEST_PRESCRICAO_RENOVACAO,
  RECEIVE_PRESCRICAO_RENOVACAO, REQUEST_SAVE_PRESCRICAO_RENOVACAO, RECEIVE_SAVE_PRESCRICAO_RENOVACAO,
  REQUEST_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO, RECEIVE_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO,
  RESET_ALL_PRESCRICAO_RENOVACAO, REQUEST_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM,
  RECEIVE_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM, REQUEST_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM,
  RECEIVE_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM, REQUEST_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR,
  RECEIVE_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  save: {
    isLoading: false,
  },
  saveReembolso: {
    isLoading: false,
  },
  criarPrePostagem: {
    isLoading: false,
  },
  cancelarPrePostagem: {
    isLoading: false,
  },
  solicitarImpressao: {
    isLoading: false,
  },
  impressaoPdf: {
    isLoading: false,
  },
}

export default function PrescricaoRenovacaoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_PRESCRICOES_RENOVACAO:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.list.data : INITIAL_STATE.list.data,
          receivedAt: state.list.receivedAt,
        }
      }
    case RECEIVE_PRESCRICOES_RENOVACAO:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PRESCRICAO_RENOVACAO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.item.data : INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_PRESCRICAO_RENOVACAO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_PRESCRICAO_RENOVACAO:
    case RECEIVE_SAVE_PRESCRICAO_RENOVACAO:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO:
    case RECEIVE_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO:
      return {
        ...state,
        saveReembolso: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM:
    case RECEIVE_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM:
      return {
        ...state,
        criarPrePostagem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM:
    case RECEIVE_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM:
      return {
        ...state,
        cancelarPrePostagem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR:
    case RECEIVE_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR:
      return {
        ...state,
        cancelarPrePostagem: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_PRESCRICAO_RENOVACAO:
      return INITIAL_STATE
    default:
      return state
  }
}