/**
 * Action para emissão de notificações no desktop.
 *
 * @see https://developer.mozilla.org/pt-BR/docs/Web/API/notificacoes
 */

import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import { CHANGE_NOTIFICATION_FLAGS } from '../constants/ActionTypes'
// store
// import { getLocalStorage, setLocalStorage } from './StorageActions'
import { showRequestError } from './AppActions'

 // Firebase
let messaging = null
try {
  // try browser suport
  messaging = firebase.messaging()
  // Add the public key generated from the console here.
  messaging.usePublicVapidKey("BPmp_2Yf9iBZ9C95JBhLFvchwbPqh8mpDmuan4SvztEVx-XP4MKaFbhg15et1z13Ofra_SyXbbt-0xFCVH9MaZ4")
} catch (error) {
  console.log('Erro on import firebase messaging', error.message)
}

let timerVerifyPermission = null
let countVerifications = 0


function changeFlags(permission) {
  return {
    type: CHANGE_NOTIFICATION_FLAGS,
    permission: permission,
  }
}

/**
 * Solicita permisão ao usuário para exibição de notificações no desktop
 */
export function requestPermission() {
  console.log('dispatch: requestPermission')
  return (dispatch) => {
    let permission = getPermission()
    console.log('permission: ', permission)
    // altera state
    dispatch(changeFlags(permission))
    switch (permission) {
      case 'not_suport':
        return
      case 'granted':
        dispatch(initFirebaseCloudMessage())
        return
      case 'denied':
      case 'default':
        if ('Notification' in window) {
          Notification.requestPermission()
          // se timer de verificação não estiver alocado inicia
          if (!timerVerifyPermission) {
            countVerifications = 0
            timerVerifyPermission = setInterval(() => verifyPermissionHasBeenGranted(), 1000)
          }
        }
        return
      default:
        return
    }
  }
}

/**
 * Exibi uma nova notificação no desktop.
 *
 * @param {String} title
 * @param {String} body
 * @param {Function} onClick
 */
export function showNotification(title, body, onClick) {
  return (dispatch) => {
    if (browserHasSuport() && getPermission() !== 'granted') return
    // create notification
    var notification = new Notification(title, {
      icon: '/images/core-icon-128.png',
      body: body
    });
    // set click callback function
    notification.onclick = function () {
      notification.close()
      if (onClick) onClick()
    }
    // play audio alert
    var audio = new Audio('/audio/notification.mp3')
    audio.play().catch(function(error) {
      console.log(error.message)
    })
  }
}

/**
 * Verifica se broser tem suporte a notifications.
 */
function browserHasSuport() {
  if (! ('Notification' in window)) {
    console.log('Desktop notifications not available in your browser. Try Chromium.');
    return false
  }
  return true
}

/**
 * Verifica se usuário concedeu permissão para exibição de notificações.
 *
 * not_suport: Browser not suport notifications.
 * granted:    The user has explicitly granted permission for the current origin to display system notifications.
 * denied:     The user has explicitly denied permission for the current origin to display system notifications.
 * default:    The user decision is unknown; in this case the application will act as if permission was denied.
 */
function getPermission() {
  if (!browserHasSuport()) return 'not_suport'
  return Notification.permission
}

/**
 * Verificação cíclica se o usuário concedeu permisão
 */
function verifyPermissionHasBeenGranted() {
  console.log('dispatch: verifyPermissionHasBeenGranted')
  return (dispatch) => {
    let permission = getPermission()
    console.log('permision: ', permission)
    dispatch(changeFlags(permission))
    switch (permission) {
      case 'not_suport':
      case 'granted':
        clearInterval(timerVerifyPermission)
        dispatch(initFirebaseCloudMessage())
      case 'denied':
      case 'default':
        countVerifications++
        if (countVerifications >= 15) clearInterval(timerVerifyPermission)
    }
  }
}

function initFirebaseCloudMessage() {
  console.log('dispatch: initFirebaseCloudMessage')
  return (dispatch) => {
    if (! messaging) {
      console.log('Browser not suport firebase messaging')
      return
    }
    messaging.getToken().then(function(token) {
      console.log('getToken toke: ', token)
      if (token) {
        dispatch(sendTokenToServer(token))
      } else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.')
        // setTokenSentToServer(false)
      }
    }).catch(function(err) {
      console.log('An error occurred while retrieving token. ', err)
      // setTokenSentToServer(false)
    })

    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(function() {
      messaging.getToken().then(function(refreshedToken) {
        console.log('Token refreshed: ', refreshedToken)
        // Indicate that the new Instance ID token has not yet been sent to the app server.
        // setTokenSentToServer(false)
        // Send Instance ID token to app server.
        dispatch(sendTokenToServer(refreshedToken))
      }).catch(function(err) {
        console.log('Unable to retrieve refreshed token ', err)
      })
    })

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.setBackgroundMessageHandler` handler.
    messaging.onMessage(function(payload) {
      console.log('Message received. ', payload)
      const notificationData = JSON.parse(payload.data.notification)
      dispatch(showNotification(notificationData.title, notificationData.body, () => {
        if (typeof window != 'undefined') { // em ambiente de teste nao existe
          window.router.push('/exame/disponiveis')
        }
      }))
    })
  }
}

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer(token) {
  return (dispatch) => {
    // if (!getTokenSentToServer()) {
      console.log('Send new token to server: ', token)
      const url = `${API_HOST}/notification/register`
      request.post(url, {}, JSON.stringify({ token })).then(json => {
        console.log('Send new token to server as success: ', json)
        // setTokenSentToServer(true)
      }).catch(err => {
        dispatch(showRequestError(err))
      })
    /*} else {
      console.log('Token already sent to server so won\'t send it again unless it changes')
    }*/
  }
}

/*function getTokenSentToServer() {
  return (getLocalStorage('FCM_TOKEN_REGISTRED') === '1')
}*/

/*function setTokenSentToServer(sent) {
  let parsedSent = (sent) ? '1' : '0'
  setLocalStorage('FCM_TOKEN_REGISTRED', parsedSent)
}*/
