
// export const API_HOST = '/api-main/api'
export const UI_HOST = (process.env.NODE_ENV === 'production')
  ? 'https://app.telemedicinamorsch.com.br'
  : 'https://ui.telemedicinamorsch.com.br'

export const API_HOST = (process.env.NODE_ENV === 'production')
  ? '//app.telemedicinamorsch.com.br/api-main/v1'
  : '//ui.telemedicinamorsch.com.br/api-main/v1'

export const API_AUTH = (process.env.NODE_ENV === 'production')
  ? '//app.telemedicinamorsch.com.br/api-auth/v1'
  : '//ui.telemedicinamorsch.com.br/api-auth/v1'

export const SERVICE_CHAT = (process.env.NODE_ENV === 'production')
  ? '//app.telemedicinamorsch.com.br/service-chat/v1'
  : '//ui.telemedicinamorsch.com.br/service-chat/v1'

  export const API_MEMED = (process.env.NODE_ENV === 'production')
  ? 'https://api.memed.com.br'
  : 'https://api.memed.com.br' // 'https://integracao.api.memed.com.br'

export const MEMED = (process.env.NODE_ENV === 'production')
  ? 'https://memed.com.br'
  : 'https://memed.com.br' // 'https://integracao.memed.com.br'
