// app
export const APP_ONLINE = 'APP_ONLINE'
export const WINDOW_ACTIVE = 'WINDOW_ACTIVE'
export const WINDOW_VISIBLE = 'WINDOW_VISIBLE'
export const REQUEST_APP = 'REQUEST_APP'
export const DISMISS_EXAMES_AGUARDANDO_ENVIO = 'DISMISS_EXAMES_AGUARDANDO_ENVIO'
export const DISMISS_VERIFICAR_PAGAMENTO = 'DISMISS_VERIFICAR_PAGAMENTO'
export const DISMISS_CERTIFICATE_EXPIRE = 'DISMISS_CERTIFICATE_EXPIRE'
export const SHOW_POPUP_LAUDAR_SEM_CERTIFICADO = 'SHOW_POPUP_LAUDAR_SEM_CERTIFICADO'
export const RESET_ALL_APP = 'RESET_ALL_APP'
export const TOGGLE_CHAT_VISIBLE = 'TOGGLE_CHAT_VISIBLE'
export const TOGGLE_AVISOS_VISIBLE = 'TOGGLE_AVISOS_VISIBLE'
export const UPDATE_NEW_CHAT_MESSAGES = 'UPDATE_NEW_CHAT_MESSAGES'
export const REQUEST_CHAT_BROADCAST_MESSAGE = 'REQUEST_CHAT_BROADCAST_MESSAGE'
export const RECEIVE_CHAT_BROADCAST_MESSAGE = 'RECEIVE_CHAT_BROADCAST_MESSAGE'
export const REQUEST_EMAIL_BROADCAST_MESSAGE = 'REQUEST_EMAIL_BROADCAST_MESSAGE'
export const RECEIVE_EMAIL_BROADCAST_MESSAGE = 'RECEIVE_EMAIL_BROADCAST_MESSAGE'
export const SHOW_MESSAGE_MODAL = 'SHOW_MESSAGE_MODAL'
export const HIDE_MESSAGE_MODAL = 'HIDE_MESSAGE_MODAL'
export const CHANGE_WIZARD = 'CHANGE_WIZARD'
export const REQUEST_WIZARD = 'REQUEST_WIZARD'
export const RECEIVE_WIZARD = 'RECEIVE_WIZARD'
export const REQUEST_SAVE_WIZARD = 'REQUEST_SAVE_WIZARD'
export const RECEIVE_SAVE_WIZARD = 'RECEIVE_SAVE_WIZARD'

// auth
export const REQUEST_SIGN_IN = {type: 'REQUEST_SIGN_IN', id: 14000}
export const RECEIVE_SIGN_IN = {type: 'RECEIVE_SIGN_IN'}
export const RECEIVE_SIGN_IN_FAIL = {type: 'RECEIVE_SIGN_IN_FAIL'}
export const RECEIVE_SIGN_OUT = {type: 'RECEIVE_SIGN_OUT'}
export const REQUEST_LOGGED_ENTITY = {type: 'REQUEST_LOGGED_ENTITY', id: 14001}
export const RECEIVE_LOGGED_ENTITY = {type: 'RECEIVE_LOGGED_ENTITY'}
export const REQUEST_ENTITY_INFORMACOES_ADICIONAIS = {type: 'REQUEST_ENTITY_INFORMACOES_ADICIONAIS', id: 14002}
export const RECEIVE_ENTITY_INFORMACOES_ADICIONAIS = {type: 'RECEIVE_ENTITY_INFORMACOES_ADICIONAIS'}
export const REQUEST_CREDENTIALS = {type: 'REQUEST_CREDENTIALS', id: 14004}
export const REQUEST_NOTIFY_ONLINE = 'REQUEST_NOTIFY_ONLINE'
export const RECEIVE_NOTIFY_ONLINE = 'RECIVE_NOTIFY_ONLINE'
export const REQUEST_VERIFY_USER_SESSION = 'REQUEST_VERIFY_USER_SESSION'
export const RECEIVE_VERIFY_USER_SESSION = 'RECEIVE_VERIFY_USER_SESSION'
export const RESET_ALL_AUTH = 'RESET_ALL_AUTH'

// aviso
export const REQUEST_AVISOS = {type: 'REQUEST_AVISOS', id: 13000}
export const RECEIVE_AVISOS = {type: 'RECEIVE_AVISOS'}
export const REQUEST_AVISO = {type: 'REQUEST_AVISO', id: 13003}
export const RECEIVE_AVISO = {type: 'RECEIVE_AVISO'}
export const REQUEST_SAVE_AVISO = {type: 'REQUEST_SAVE_AVISO', id: 13001}
export const RECEIVE_SAVE_AVISO = {type: 'RECEIVE_SAVE_AVISO'}
export const REQUEST_REMOVE_AVISO = {type: 'REQUEST_REMOVE_AVISO', id: 13002}
export const RECEIVE_REMOVE_AVISO = {type: 'RECEIVE_REMOVE_AVISO'}
export const REQUEST_READ_AVISO = 'REQUEST_READ_AVISO'
export const RECEIVE_READ_AVISO = 'RECEIVE_READ_AVISO'
export const RESET_ALL_AVISO = {type: 'RESET_ALL_AVISO'}

// banco
export const REQUEST_BANCOS = {type: 'REQUEST_BANCOS', id: 15000}
export const RECEIVE_BANCOS = {type: 'RECEIVE_BANCOS'}
export const RESET_ALL_BANCO = {type: 'RESET_ALL_BANCO'}

// cabine
export const REQUEST_CABINES = 'REQUEST_CABINES'
export const RECEIVE_CABINES = 'RECEIVE_CABINES'
export const REQUEST_ALL_CABINES = 'REQUEST_ALL_CABINES'
export const RECEIVE_ALL_CABINES = 'RECEIVE_ALL_CABINES'
export const REQUEST_CABINE = 'REQUEST_CABINE'
export const RECEIVE_CABINE = 'RECEIVE_CABINE'
export const REQUEST_SAVE_CABINE = 'REQUEST_SAVE_CABINE'
export const RECEIVE_SAVE_CABINE = 'RECEIVE_SAVE_CABINE'
export const RESET_CABINE = 'RESET_CABINE'
export const RESET_ALL_CABINE = 'RESET_ALL_CABINE'

// certificado
export const REQUEST_CERTIFICADO_VIDAAS = 'REQUEST_CERTIFICADO_VIDAAS'
export const RECEIVE_CERTIFICADO_VIDAAS = 'RECEIVE_CERTIFICADO_VIDAAS'
export const REQUEST_AUTHORIZE_VIDAAS = 'REQUEST_AUTHORIZE_VIDAAS'
export const RECEIVE_AUTHORIZE_VIDAAS = 'RECEIVE_AUTHORIZE_VIDAAS'
export const REQUEST_VERIFY_PUSH_AUTHORIZED_VIDAAS = 'REQUEST_VERIFY_PUSH_AUTHORIZED_VIDAAS'
export const RECEIVE_VERIFY_PUSH_AUTHORIZED_VIDAAS = 'RECEIVE_VERIFY_PUSH_AUTHORIZED_VIDAAS'

// config
export const REQUEST_CONFIG = 'REQUEST_CONFIG'
export const RECEIVE_CONFIG = 'RECEIVE_CONFIG'
export const REQUEST_FERIADOS = 'REQUEST_FERIADOS'
export const RECEIVE_FERIADOS = 'RECEIVE_FERIADOS'
export const REQUEST_SAVE_FERIADO = 'REQUEST_SAVE_FERIADO'
export const RECEIVE_SAVE_FERIADO = 'RECEIVE_SAVE_FERIADO'
export const REQUEST_IMPOSTOS = {type: 'REQUEST_IMPOSTOS', id: 19000}
export const RECEIVE_IMPOSTOS = {type: 'RECEIVE_IMPOSTOS'}
export const REQUEST_SAVE_IMPOSTOS = {type: 'REQUEST_SAVE_IMPOSTOS', id: 19001}
export const RECEIVE_SAVE_IMPOSTOS = {type: 'RECEIVE_SAVE_IMPOSTOS'}
export const REQUEST_CERTIFICADO = {type: 'REQUEST_CERTIFICADO', id: 19002}
export const RECEIVE_CERTIFICADO = {type: 'RECEIVE_CERTIFICADO'}
export const REQUEST_SAVE_CERTIFICADO = {type: 'REQUEST_SAVE_CERTIFICADO', id: 19003}
export const RECEIVE_SAVE_CERTIFICADO = {type: 'RECEIVE_SAVE_CERTIFICADO'}
export const REQUEST_CONVENIOS = 'REQUEST_CONVENIOS'
export const RECEIVE_CONVENIOS = 'RECEIVE_CONVENIOS'
export const REQUEST_CONVENIO = 'REQUEST_CONVENIO'
export const RECEIVE_CONVENIO = 'RECEIVE_CONVENIO'
export const REQUEST_SAVE_CONVENIO = 'REQUEST_SAVE_CONVENIO'
export const RECEIVE_SAVE_CONVENIO = 'RECEIVE_SAVE_CONVENIO'
export const REQUEST_RECEBIMENTO = 'REQUEST_RECEBIMENTO'
export const RECEIVE_RECEBIMENTO = 'RECEIVE_RECEBIMENTO'
export const REQUEST_SAVE_RECEBIMENTO = 'REQUEST_SAVE_RECEBIMENTO'
export const RECEIVE_SAVE_RECEBIMENTO = 'RECEIVE_SAVE_RECEBIMENTO'
export const RESET_ALL_CONFIG = {type: 'RESET_ALL_CONFIG'}

// context
export const REQUEST_PUBLIC_CLINICA_CONTEXT = {type: 'REQUEST_PUBLIC_CLINICA_CONTEXT'}
export const RECEIVE_PUBLIC_CLINICA_CONTEXT = {type: 'RECEIVE_PUBLIC_CLINICA_CONTEXT'}
export const RESET_ALL_CONTEXT = {type: 'RESET_ALL_CONTEXT'}

// clinica
export const REQUEST_CLINICAS = {type: 'REQUEST_CLINICAS', id: 10000}
export const RECEIVE_CLINICAS = {type: 'RECEIVE_CLINICAS'}
export const REQUEST_CLINICAS_MATRIZ = {type: 'REQUEST_CLINICAS_MATRIZ', id: 10002}
export const RECEIVE_CLINICAS_MATRIZ = {type: 'RECEIVE_CLINICAS_MATRIZ'}
export const REQUEST_CLINICA_MATRIZ = {type: 'REQUEST_CLINICA_MATRIZ', id: 10003}
export const RECEIVE_CLINICA_MATRIZ = {type: 'RECEIVE_CLINICA_MATRIZ'}
export const REQUEST_SAVE_CUSTOMER_SUCCESS = {type: 'REQUEST_SAVE_CUSTOMER_SUCCESS', id: 10014}
export const RECEIVE_SAVE_CUSTOMER_SUCCESS = {type: 'RECEIVE_SAVE_CUSTOMER_SUCCESS'}
export const REQUEST_ALL_CLINICAS = {type: 'REQUEST_ALL_CLINICAS', id: 10001}
export const RECEIVE_ALL_CLINICAS = {type: 'RECEIVE_ALL_CLINICAS'}
export const REQUEST_CLINICA = {type: 'REQUEST_CLINICA', id: 10004}
export const RECEIVE_CLINICA = {type: 'RECEIVE_CLINICA'}
export const REQUEST_SAVE_CLINICA = {type: 'REQUEST_SAVE_CLINICA', id: 10004}
export const RECEIVE_SAVE_CLINICA = {type: 'RECEIVE_SAVE_CLINICA'}
export const REQUEST_SAVE_CLINICA_MATRIZ = {type: 'REQUEST_SAVE_CLINICA_MATRIZ', id: 10006}
export const RECEIVE_SAVE_CLINICA_MATRIZ = {type: 'RECEIVE_SAVE_CLINICA_MATRIZ'}
export const REQUEST_CLINICA_DOCUMENTOS = {type: 'REQUEST_CLINICA_DOCUMENTOS'}
export const RECEIVE_CLINICA_DOCUMENTOS = {type: 'RECEIVE_CLINICA_DOCUMENTOS'}
export const REQUEST_ENVIAR_DOCUMENTO_CLINICA = {type: 'REQUEST_ENVIAR_DOCUMENTO_CLINICA', id: 10013}
export const RECEIVE_ENVIAR_DOCUMENTO_CLINICA = {type: 'RECEIVE_ENVIAR_DOCUMENTO_CLINICA'}
export const REQUEST_REMOVE_DOCUMENTO_CLINICA = {type: 'REQUEST_REMOVE_DOCUMENTO_CLINICA'}
export const RECEIVE_REMOVE_DOCUMENTO_CLINICA = {type: 'RECEIVE_REMOVE_DOCUMENTO_CLINICA'}
export const REQUEST_REMOVE_CLINICA = {type: 'REQUEST_REMOVE_CLINICA', id: 10007}
export const RECEIVE_REMOVE_CLINICA = {type: 'RECEIVE_REMOVE_CLINICA'}
export const REQUEST_CLINICA_ESPECIALIDADES = {type: 'REQUEST_CLINICA_ESPECIALIDADES', id: 10008}
export const RECEIVE_CLINICA_ESPECIALIDADES = {type: 'RECEIVE_CLINICA_ESPECIALIDADES'}
export const REQUEST_CLINICA_IDIOMAS = {type: 'REQUEST_CLINICA_IDIOMAS', id: 10009}
export const RECEIVE_CLINICA_IDIOMAS = {type: 'RECEIVE_CLINICA_IDIOMAS'}
export const REQUEST_ADD_CREDIT = {type: 'REQUEST_ADD_CREDIT', id: 10010}
export const RECEIVE_ADD_CREDIT = {type: 'RECEIVE_ADD_CREDIT'}
export const REQUEST_SAVE_CLINICA_ESPECIALIDADE = {type: 'REQUEST_SAVE_CLINICA_ESPECIALIDADE', id: 10011}
export const RECEIVE_SAVE_CLINICA_ESPECIALIDADE = {type: 'RECEIVE_SAVE_CLINICA_ESPECIALIDADE'}
export const REQUEST_REMOVE_CLINICA_ESPECIALIDADE = {type: 'REQUEST_REMOVE_CLINICA_ESPECIALIDADE', id: 10012}
export const RECEIVE_REMOVE_CLINICA_ESPECIALIDADE = {type: 'RECEIVE_REMOVE_CLINICA_ESPECIALIDADE'}
export const REQUEST_CLINICA_VALORES_RECEBER = {type: 'REQUEST_CLINICA_VALORES_RECEBER'}
export const RECEIVE_CLINICA_VALORES_RECEBER = {type: 'RECEIVE_CLINICA_VALORES_RECEBER'}
export const REQUEST_CLINICA_RECIBOS_DE_TAXAS = {type: 'REQUEST_CLINICA_RECIBOS_DE_TAXAS'}
export const RECEIVE_CLINICA_RECIBOS_DE_TAXAS = {type: 'RECEIVE_CLINICA_RECIBOS_DE_TAXAS'}
export const REQUEST_SAVE_PROFILE_CLINICA = 'REQUEST_SAVE_PROFILE_CLINICA'
export const RECEIVE_SAVE_PROFILE_CLINICA = 'RECEIVE_SAVE_PROFILE_CLINICA'
export const REQUEST_SAVE_AUTENTICAR_CLINICA = 'REQUEST_SAVE_AUTENTICAR_CLINICA'
export const RECEIVE_SAVE_AUTENTICAR_CLINICA = 'RECEIVE_SAVE_AUTENTICAR_CLINICA'
export const REQUEST_SAVE_CLINICA_LAYOUT = 'REQUEST_SAVE_CLINICA_LAYOUT'
export const RECEIVE_SAVE_CLINICA_LAYOUT = 'RECEIVE_SAVE_CLINICA_LAYOUT'
export const REQUEST_SAVE_CLINICA_PAGAMENTO = 'REQUEST_SAVE_CLINICA_PAGAMENTO'
export const RECEIVE_SAVE_CLINICA_PAGAMENTO = 'RECEIVE_SAVE_CLINICA_PAGAMENTO'
export const REQUEST_CONFIG_NOTIFICACAO_CREDITO = 'REQUEST_CONFIG_NOTIFICACAO_CREDITO'
export const RECEIVE_CONFIG_NOTIFICACAO_CREDITO = 'RECEIVE_CONFIG_NOTIFICACAO_CREDITO'
export const REQUEST_SAVE_CONFIG_NOTIFICACAO_CREDITO = 'REQUEST_SAVE_CONFIG_NOTIFICACAO_CREDITO'
export const RECEIVE_SAVE_CONFIG_NOTIFICACAO_CREDITO = 'RECEIVE_SAVE_CONFIG_NOTIFICACAO_CREDITO'
export const RESET_CLINICA = {type: 'RESET_CLINICA'}
export const RESET_ALL_CLINICA = {type: 'RESET_ALL_CLINICA'}

// coupon
export const REQUEST_COUPONS = 'REQUEST_COUPONS'
export const RECEIVE_COUPONS = 'RECEIVE_COUPONS'
export const REQUEST_COUPON = 'REQUEST_COUPON'
export const RECEIVE_COUPON = 'RECEIVE_COUPON'
export const RESET_COUPON = 'RESET_COUPON'
export const REQUEST_SAVE_COUPON = 'REQUEST_SAVE_COUPON'
export const RECEIVE_SAVE_COUPON = 'RECEIVE_SAVE_COUPON'
export const REQUEST_REMOVE_COUPON = 'REQUEST_REMOVE_COUPON'
export const RECEIVE_REMOVE_COUPON = 'RECEIVE_REMOVE_COUPON'
export const REQUEST_APPLY_COUPON = 'REQUEST_APPLY_COUPON'
export const RECEIVE_APPLY_COUPON = 'RECEIVE_APPLY_COUPON'
export const RESET_ALL_COUPON = 'RESET_ALL_COUPON'

// desktop notification
export const CHANGE_NOTIFICATION_FLAGS = 'CHANGE_NOTIFICATION_FLAGS'

// doenças
export const REQUEST_ALL_DOENCAS = 'REQUEST_ALL_DOENCAS'
export const RECEIVE_ALL_DOENCAS = 'RECEIVE_ALL_DOENCAS'
export const RESET_ALL_DOENCAS = 'RESET_ALL_DOENCAS'

// empresa
export const REQUEST_EMPRESAS = 'REQUEST_EMPRESAS'
export const RECEIVE_EMPRESAS = 'RECEIVE_EMPRESAS'
export const REQUEST_SAVE_EMPRESA = 'REQUEST_SAVE_EMPRESA'
export const RECEIVE_SAVE_EMPRESA = 'RECEIVE_SAVE_EMPRESA'
export const RESET_ALL_EMPRESA = 'RESET_ALL_EMPRESA'

// encaminhamento
export const REQUEST_ENCAMINHAMENTOS = 'REQUEST_ENCAMINHAMENTOS'
export const RECEIVE_ENCAMINHAMENTOS = 'RECEIVE_ENCAMINHAMENTOS'
export const REQUEST_ENCAMINHAMENTO = 'REQUEST_ENCAMINHAMENTO'
export const RECEIVE_ENCAMINHAMENTO = 'RECEIVE_ENCAMINHAMENTO'
export const REQUEST_SAVE_ENCAMINHAMENTO = 'REQUEST_SAVE_ENCAMINHAMENTO'
export const RECEIVE_SAVE_ENCAMINHAMENTO = 'RECEIVE_SAVE_ENCAMINHAMENTO'
export const REQUEST_ENCAMINHAMENTO_STATUS = 'REQUEST_ENCAMINHAMENTO_STATUS'
export const RECEIVE_ENCAMINHAMENTO_STATUS = 'RECEIVE_ENCAMINHAMENTO_STATUS'
export const RESET_ENCAMINHAMENTO = 'RESET_ENCAMINHAMENTO'
export const RESET_ALL_ENCAMINHAMENTO = 'RESET_ALL_ENCAMINHAMENTO'

// endereco
export const REQUEST_PAISES = 'REQUEST_PAISES'
export const RECEIVE_PAISES = 'RECEIVE_PAISES'
export const REQUEST_ESTADOS = 'REQUEST_ESTADOS'
export const RECEIVE_ESTADOS = 'RECEIVE_ESTADOS'
export const REQUEST_CIDADES = 'REQUEST_CIDADES'
export const RECEIVE_CIDADES = 'RECEIVE_CIDADES'
export const RESET_ALL_ENDERECO = 'RESET_ALL_ENDERECO'

// equipamentos
export const REQUEST_EQUIPAMENTOS = 'REQUEST_EQUIPAMENTOS'
export const RECEIVE_EQUIPAMENTOS = 'RECEIVE_EQUIPAMENTOS'
export const REQUEST_EQUIPAMENTOS_LIVRES = 'REQUEST_EQUIPAMENTOS_LIVRES '
export const RECEIVE_EQUIPAMENTOS_LIVRES = 'RECEIVE_EQUIPAMENTOS_LIVRES'
export const REQUEST_EQUIPAMENTOS_UTILIZADOS = 'REQUEST_EQUIPAMENTOS_UTILIZADOS'
export const RECEIVE_EQUIPAMENTOS_UTILIZADOS = 'RECEIVE_EQUIPAMENTOS_UTILIZADOS'
export const REQUEST_EQUIPAMENTO = 'REQUEST_EQUIPAMENTO'
export const RECEIVE_EQUIPAMENTO = 'RECEIVE_EQUIPAMENTO'
export const REQUEST_SAVE_EQUIPAMENTO = 'REQUEST_SAVE_EQUIPAMENTO'
export const RECEIVE_SAVE_EQUIPAMENTO = 'RECEIVE_SAVE_EQUIPAMENTO'
export const REQUEST_REMOVE_EQUIPAMENTO = 'REQUEST_REMOVE_EQUIPAMENTO'
export const RECEIVE_REMOVE_EQUIPAMENTO = 'RECEIVE_REMOVE_EQUIPAMENTO'
export const RESET_EQUIPAMENTO = 'RESET_EQUIPAMENTO'
export const REQUEST_EQUIPAMENTO_STATUS = 'REQUEST_EQUIPAMENTO_STATUS'
export const RECEIVE_EQUIPAMENTO_STATUS = 'RECEIVE_EQUIPAMENTO_STATUS'
export const REQUEST_EQUIPAMENTO_DISPONIBILIDADE = 'REQUEST_EQUIPAMENTO_DISPONIBILIDADE'
export const RECEIVE_EQUIPAMENTO_DISPONIBILIDADE = 'RECEIVE_EQUIPAMENTO_DISPONIBILIDADE'
export const REQUEST_ASSOCIATE_CLINICA_IN_BATCH = 'REQUEST_ASSOCIATE_CLINICA_IN_BATCH'
export const RECEIVE_ASSOCIATE_CLINICA_IN_BATCH = 'RECEIVE_ASSOCIATE_CLINICA_IN_BATCH'
export const RESET_ALL_EQUIPAMENTO = 'RESET_ALL_EQUIPAMENTO'

// equipe
export const REQUEST_ALL_EQUIPES = 'REQUEST_ALL_EQUIPES'
export const RECEIVE_ALL_EQUIPES = 'RECEIVE_ALL_EQUIPES'
export const REQUEST_EQUIPES = 'REQUEST_EQUIPES'
export const RECEIVE_EQUIPES = 'RECEIVE_EQUIPES'
export const REQUEST_EQUIPE = 'REQUEST_EQUIPE'
export const RECEIVE_EQUIPE = 'RECEIVE_EQUIPE'
export const REQUEST_SAVE_EQUIPE = 'REQUEST_SAVE_EQUIPE'
export const RECEIVE_SAVE_EQUIPE = 'RECEIVE_SAVE_EQUIPE'
export const RESET_EQUIPE = 'RESET_EQUIPE'
export const RESET_ALL_EQUIPE = 'RESET_ALL_EQUIPE'

// especialidade
export const REQUEST_ALL_ESPECIALIDADES = {type: 'REQUEST_ALL_ESPECIALIDADES', id: 16000}
export const RECEIVE_ALL_ESPECIALIDADES = {type: 'RECEIVE_ALL_ESPECIALIDADES'}
export const REQUEST_ALL_ESPECIALIDADES_TELE = {type: 'REQUEST_ALL_ESPECIALIDADES_TELE'}
export const RECEIVE_ALL_ESPECIALIDADES_TELE = {type: 'RECEIVE_ALL_ESPECIALIDADES_TELE'}
export const REQUEST_ALL_ESPECIALIDADES_AUTOSETUP = 'REQUEST_ALL_ESPECIALIDADES_AUTOSETUP'
export const RECEIVE_ALL_ESPECIALIDADES_AUTOSETUP = 'RECEIVE_ALL_ESPECIALIDADES_AUTOSETUP'
export const REQUEST_ESPECIALIDADES = {type: 'REQUEST_ESPECIALIDADES', id: 16001}
export const RECEIVE_ESPECIALIDADES = {type: 'RECEIVE_ESPECIALIDADES'}
export const REQUEST_ESPECIALIDADE = {type: 'REQUEST_ESPECIALIDADE', id: 16002}
export const RECEIVE_ESPECIALIDADE = {type: 'RECEIVE_ESPECIALIDADE'}
export const REQUEST_SAVE_ESPECIALIDADE = {type: 'REQUEST_SAVE_ESPECIALIDADE', id: 16003}
export const RECEIVE_SAVE_ESPECIALIDADE = {type: 'RECEIVE_SAVE_ESPECIALIDADE'}
export const REQUEST_AJUSTE_VALOR = {type: 'REQUEST_AJUSTE_VALOR'}
export const RECEIVE_AJUSTE_VALOR = {type: 'RECEIVE_AJUSTE_VALOR'}
export const RESET_ESPECIALIDADE = {type: 'RESET_ESPECIALIDADE'}
export const REQUEST_ESPECIALIDADES_MACRO = {type: 'REQUEST_ESPECIALIDADES_MACRO', id: 16004}
export const RECEIVE_ESPECIALIDADES_MACRO = {type: 'RECEIVE_ESPECIALIDADES_MACRO'}
export const REQUEST_ESPECIALIDADE_MACRO = {type: 'REQUEST_ESPECIALIDADE_MACRO', id: 16005}
export const RECEIVE_ESPECIALIDADE_MACRO = {type: 'RECEIVE_ESPECIALIDADE_MACRO'}
export const REQUEST_SAVE_ESPECIALIDADE_MACRO = {type: 'REQUEST_SAVE_ESPECIALIDADE_MACRO', id: 16006}
export const RECEIVE_SAVE_ESPECIALIDADE_MACRO = {type: 'RECEIVE_SAVE_ESPECIALIDADE_MACRO'}
export const REQUEST_DELETE_ESPECIALIDADE_MACRO = 'REQUEST_DELETE_ESPECIALIDADE_MACRO'
export const RECEIVE_DELETE_ESPECIALIDADE_MACRO = 'RECEIVE_DELETE_ESPECIALIDADE_MACRO'
export const RESET_ESPECIALIDADE_MACRO = {type: 'RESET_ESPECIALIDADE_MACRO'}
export const REQUEST_ESPECIALIDADES_TELE = {type: 'REQUEST_ESPECIALIDADES_TELE'}
export const RECEIVE_ESPECIALIDADES_TELE = {type: 'RECEIVE_ESPECIALIDADES_TELE'}
export const REQUEST_ESPECIALIDADE_TELE = {type: 'REQUEST_ESPECIALIDADE_TELE'}
export const RECEIVE_ESPECIALIDADE_TELE = {type: 'RECEIVE_ESPECIALIDADE_TELE'}
export const REQUEST_SAVE_ESPECIALIDADE_TELE = {type: 'REQUEST_SAVE_ESPECIALIDADE_TELE'}
export const RECEIVE_SAVE_ESPECIALIDADE_TELE = {type: 'RECEIVE_SAVE_ESPECIALIDADE_TELE'}
export const REQUEST_DELETE_ESPECIALIDADE_TELE = {type: 'REQUEST_DELETE_ESPECIALIDADE_TELE'}
export const RECEIVE_DELETE_ESPECIALIDADE_TELE = {type: 'RECEIVE_DELETE_ESPECIALIDADE_TELE'}
export const RESET_ESPECIALIDADE_TELE = {type: 'RESET_ESPECIALIDADE_TELE'}
export const REQUEST_ESPECIALIDADES_AUTOSETUP = {type: 'REQUEST_ESPECIALIDADES_AUTOSETUP'}
export const RECEIVE_ESPECIALIDADES_AUTOSETUP = {type: 'RECEIVE_ESPECIALIDADES_AUTOSETUP'}
export const REQUEST_ESPECIALIDADE_AUTOSETUP = {type: 'REQUEST_ESPECIALIDADE_AUTOSETUP'}
export const RECEIVE_ESPECIALIDADE_AUTOSETUP = {type: 'RECEIVE_ESPECIALIDADE_AUTOSETUP'}
export const REQUEST_SAVE_ESPECIALIDADE_AUTOSETUP = {type: 'REQUEST_SAVE_ESPECIALIDADE_AUTOSETUP'}
export const RECEIVE_SAVE_ESPECIALIDADE_AUTOSETUP = {type: 'RECEIVE_SAVE_ESPECIALIDADE_AUTOSETUP'}
export const REQUEST_DELETE_ESPECIALIDADE_AUTOSETUP = {type: 'REQUEST_DELETE_ESPECIALIDADE_AUTOSETUP'}
export const RECEIVE_DELETE_ESPECIALIDADE_AUTOSETUP = {type: 'RECEIVE_DELETE_ESPECIALIDADE_AUTOSETUP'}
export const RESET_ESPECIALIDADE_AUTOSETUP = {type: 'RESET_ESPECIALIDADE_AUTOSETUP'}
export const REQUEST_ESPECIALIDADES_BASE = {type: 'REQUEST_ESPECIALIDADES_BASE'}
export const RECEIVE_ESPECIALIDADES_BASE = {type: 'RECEIVE_ESPECIALIDADES_BASE'}
export const REQUEST_ESPECIALIDADE_BASE = {type: 'REQUEST_ESPECIALIDADE_BASE'}
export const RECEIVE_ESPECIALIDADE_BASE = {type: 'RECEIVE_ESPECIALIDADE_BASE'}
export const REQUEST_SAVE_ESPECIALIDADE_BASE = {type: 'REQUEST_SAVE_ESPECIALIDADE_BASE'}
export const RECEIVE_SAVE_ESPECIALIDADE_BASE = {type: 'RECEIVE_SAVE_ESPECIALIDADE_BASE'}
export const REQUEST_ESPECIALIDADE_NOVA = 'REQUEST_ESPECIALIDADE_NOVA'
export const RECEIVE_ESPECIALIDADE_NOVA = 'RECEIVE_ESPECIALIDADE_NOVA'
export const REQUEST_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO = 'REQUEST_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO'
export const RECEIVE_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO = 'RECEIVE_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO'
export const RESET_ESPECIALIDADE_BASE = {type: 'RESET_ESPECIALIDADE_BASE'}
export const RESET_ALL_ESPECIALIDADE = {type: 'RESET_ALL_ESPECIALIDADE'}

// estatistica
export const REQUEST_ESTATISTICA_ESPECIALIDADE_ES = 'REQUEST_ESTATISTICA_ESPECIALIDADE_ES'
export const RECEIVE_ESTATISTICA_ESPECIALIDADE_ES = 'RECEIVE_ESTATISTICA_ESPECIALIDADE_ES'
export const REQUEST_ESTATISTICA_ESPECIALIDADES = 'REQUEST_ESTATISTICA_ESPECIALIDADES'
export const RECEIVE_ESTATISTICA_ESPECIALIDADES = 'RECEIVE_ESTATISTICA_ESPECIALIDADES'
export const REQUEST_ESTATISTICA_TELECONSULTA_ESPECIALIDADES = 'REQUEST_ESTATISTICA_TELECONSULTA_ESPECIALIDADES'
export const RECEIVE_ESTATISTICA_TELECONSULTA_ESPECIALIDADES = 'RECEIVE_ESTATISTICA_TELECONSULTA_ESPECIALIDADES'
export const REQUEST_ESTATISTICA_ESPECIALIDADES_GRAFICO = 'REQUEST_ESTATISTICA_ESPECIALIDADES_GRAFICO'
export const RECEIVE_ESTATISTICA_ESPECIALIDADES_GRAFICO = 'RECEIVE_ESTATISTICA_ESPECIALIDADES_GRAFICO'
export const REQUEST_ESTATISTICA_TELECONSULTA_GRAFICO = 'REQUEST_ESTATISTICA_TELECONSULTA_GRAFICO'
export const RECEIVE_ESTATISTICA_TELECONSULTA_GRAFICO = 'RECEIVE_ESTATISTICA_TELECONSULTA_GRAFICO'
export const REQUEST_ESTATISTICA_MEDICOS_GRAFICO = 'REQUEST_ESTATISTICA_MEDICOS_GRAFICO'
export const RECEIVE_ESTATISTICA_MEDICOS_GRAFICO = 'RECEIVE_ESTATISTICA_MEDICOS_GRAFICO'
export const REQUEST_ESTATISTICA_EXAMES = 'REQUEST_ESTATISTICA_EXAMES'
export const RECEIVE_ESTATISTICA_EXAMES = 'RECEIVE_ESTATISTICA_EXAMES'
export const REQUEST_ESTATISTICA_MEDICOS = 'REQUEST_ESTATISTICA_MEDICOS'
export const RECEIVE_ESTATISTICA_MEDICOS = 'RECEIVE_ESTATISTICA_MEDICOS'
export const REQUEST_ESTATISTICA_ESPECIALIDADES_CLINICA = 'REQUEST_ESTATISTICA_ESPECIALIDADES_CLINICA'
export const RECEIVE_ESTATISTICA_ESPECIALIDADES_CLINICA = 'RECEIVE_ESTATISTICA_ESPECIALIDADES_CLINICA'
export const REQUEST_ESTATISTICA_ESPECIALIDADES_MEDICO = 'REQUEST_ESTATISTICA_ESPECIALIDADES_MEDICO'
export const RECEIVE_ESTATISTICA_ESPECIALIDADES_MEDICO = 'RECEIVE_ESTATISTICA_ESPECIALIDADES_MEDICO'
export const REQUEST_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO = 'REQUEST_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO'
export const RECEIVE_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO = 'RECEIVE_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO'
export const REQUEST_ESTATISTICA_CLINICAS_ES = 'REQUEST_ESTATISTICA_CLINICAS_ES'
export const RECEIVE_ESTATISTICA_CLINICAS_ES = 'RECEIVE_ESTATISTICA_CLINICAS_ES'
export const REQUEST_ESTATISTICA_CLINICAS_OCIOSIDADE = 'REQUEST_ESTATISTICA_CLINICAS_OCIOSIDADE'
export const RECEIVE_ESTATISTICA_CLINICAS_OCIOSIDADE = 'RECEIVE_ESTATISTICA_CLINICAS_OCIOSIDADE'
export const REQUEST_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO = 'REQUEST_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO'
export const RECEIVE_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO = 'RECEIVE_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO'
export const REQUEST_ESTATISTICA_TOTAL_GRAFICO = 'REQUEST_ESTATISTICA_TOTAL_GRAFICO'
export const RECEIVE_ESTATISTICA_TOTAL_GRAFICO = 'RECEIVE_ESTATISTICA_TOTAL_GRAFICO'
export const REQUEST_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO = 'REQUEST_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO'
export const RECEIVE_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO = 'RECEIVE_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO'
export const REQUEST_ESTATISTICA_CADASTRO_USUARIO_GRAFICO = 'REQUEST_ESTATISTICA_CADASTRO_USUARIO_GRAFICO'
export const RECEIVE_ESTATISTICA_CADASTRO_USUARIO_GRAFICO = 'RECEIVE_ESTATISTICA_CADASTRO_USUARIO_GRAFICO'
export const REQUEST_ESTATISTICA_RECEITA_TOTAL_GRAFICO = 'REQUEST_ESTATISTICA_RECEITA_TOTAL_GRAFICO'
export const RECEIVE_ESTATISTICA_RECEITA_TOTAL_GRAFICO = 'RECEIVE_ESTATISTICA_RECEITA_TOTAL_GRAFICO'
export const REQUEST_ESTATISTICA_TOTAL_ES_GRAFICO = 'REQUEST_ESTATISTICA_TOTAL_ES_GRAFICO'
export const RECEIVE_ESTATISTICA_TOTAL_ES_GRAFICO = 'RECEIVE_ESTATISTICA_TOTAL_ES_GRAFICO'
export const REQUEST_ESTATISTICA_CID10 = 'REQUEST_ESTATISTICA_CID10'
export const RECEIVE_ESTATISTICA_CID10 = 'RECEIVE_ESTATISTICA_CID10'
export const REQUEST_PACIENTES_BY_CID10 = 'REQUEST_PACIENTES_BY_CID10'
export const RECEIVE_PACIENTES_BY_CID10 = 'RECEIVE_PACIENTES_BY_CID10'
export const REQUEST_ESTATISTICA_ENCAMINHAMENTO = 'REQUEST_ESTATISTICA_ENCAMINHAMENTO'
export const RECEIVE_ESTATISTICA_ENCAMINHAMENTO = 'RECEIVE_ESTATISTICA_ENCAMINHAMENTO'
export const RESET_ALL_ESTATISTICA = {type: 'RESET_ALL_ESTATISTICA'}

// exame
export const REQUEST_EXAMES = {type: 'REQUEST_EXAMES', id: 11000}
export const RECEIVE_EXAMES = {type: 'RECEIVE_EXAMES'}
export const REQUEST_EXAME_ANEXOS_ESTATISTICAS = 'REQUEST_EXAME_ANEXOS_ESTATISTICAS'
export const RECEIVE_EXAME_ANEXOS_ESTATISTICAS = 'RECEIVE_EXAME_ANEXOS_ESTATISTICAS'
export const REQUEST_EXAME_ANEXOS_ANALISE = 'REQUEST_EXAME_ANEXOS_ANALISE'
export const RECEIVE_EXAME_ANEXOS_ANALISE = 'RECEIVE_EXAME_ANEXOS_ANALISE'
export const REQUEST_EXAME_ANEXOS_ANALISE_GRAFICO = 'REQUEST_EXAME_ANEXOS_ANALISE_GRAFICO'
export const RECEIVE_EXAME_ANEXOS_ANALISE_GRAFICO = 'RECEIVE_EXAME_ANEXOS_ANALISE_GRAFICO'
export const REQUEST_SAVE_EXAME_ANEXO_ANALISE = 'REQUEST_SAVE_EXAME_ANEXO_ANALISE'
export const RECEIVE_SAVE_EXAME_ANEXO_ANALISE = 'RECEIVE_SAVE_EXAME_ANEXO_ANALISE'
export const REQUEST_EXAME = {type: 'REQUEST_EXAME', id: 11001}
export const RECEIVE_EXAME = {type: 'RECEIVE_EXAME'}
export const RESET_EXAME = {type: 'RESET_EXAME'}
export const REQUEST_SAVE_EXAME = {type: 'REQUEST_SAVE_EXAME', id: 11003}
export const RECEIVE_SAVE_EXAME = {type: 'RECEIVE_SAVE_EXAME'}
export const REQUEST_SAVE_PRE_LAUDO = {type: 'REQUEST_SAVE_PRE_LAUDO', id: 11004}
export const RECEIVE_SAVE_PRE_LAUDO = {type: 'RECEIVE_SAVE_PRE_LAUDO'}
export const SHOW_CONFIRM_LAUDAR = {type: 'SHOW_CONFIRM_LAUDAR'}
export const HIDE_CONFIRM_LAUDAR = {type: 'HIDE_CONFIRM_LAUDAR'}
export const START_EXAME_UPLOAD_FILES = {type: 'START_EXAME_UPLOAD_FILES'}
export const RECEIVE_EXAME_UPLOAD_FILE = {type: 'RECEIVE_EXAME_UPLOAD_FILE'}
export const FINISH_EXAME_UPLOAD_FILES = {type: 'FINISH_EXAME_UPLOAD_FILES'}
export const REQUEST_EXAMES_BY_PACIENTE = {type: 'REQUEST_EXAMES_BY_PACIENTE', id: 11002}
export const RECEIVE_EXAMES_BY_PACIENTE = {type: 'RECEIVE_EXAMES_BY_PACIENTE'}
export const REQUEST_LAUDOS_ANTERIORES = 'REQUEST_LAUDOS_ANTERIORES'
export const RECEIVE_LAUDOS_ANTERIORES = 'RECEIVE_LAUDOS_ANTERIORES'
export const REQUEST_CHANGE_ESPECIALIDADE_EXAME = {type: 'REQUEST_CHANGE_ESPECIALIDADE_EXAME', id: 11005}
export const RECEIVE_CHANGE_ESPECIALIDADE_EXAME = {type: 'RECEIVE_CHANGE_ESPECIALIDADE_EXAME'}
export const REQUEST_ORIENTAR_EXAME = {type: 'REQUEST_ORIENTAR_EXAME', id: 11006}
export const RECEIVE_ORIENTAR_EXAME = {type: 'RECEIVE_ORIENTAR_EXAME'}
export const START_LAUDAR = {type: 'START_LAUDAR'}
export const FINISH_LAUDAR = {type: 'FINISH_LAUDAR'}
export const UPDATE_MEDICO_AVAILABLE_EXAMES = {type: 'UPDATE_MEDICO_AVAILABLE_EXAMES'}
export const RESET_ALL_EXAME = {type: 'RESET_ALL_EXAME'}
export const REQUEST_VERIFICA_PODE_LAUDAR = {type: 'REQUEST_VERIFICA_PODE_LAUDAR', id: 11007}
export const REQUEST_INICIAR_PRE_LAUDO = {type: 'REQUEST_INICIAR_PRE_LAUDO', id: 11008}
export const REQUEST_INICIAR_LAUDO = {type: 'REQUEST_INICIAR_LAUDO', id: 11009}
export const REQUEST_DEVOLVER_FILA = {type: 'REQUEST_DEVOLVER_FILA', id: 11010}
export const REQUEST_REMOVE_EXAME = {type: 'REQUEST_REMOVE_EXAME', id: 11011}
export const RECEIVE_REMOVE_EXAME = {type: 'RECEIVE_REMOVE_EXAME'}
export const REQUEST_REGERAR_LAUDOS_EXAME = 'REQUEST_REGERAR_LAUDOS_EXAME'
export const RECEIVE_REGERAR_LAUDOS_EXAME = 'RECEIVE_REGERAR_LAUDOS_EXAME'
export const REQUEST_SAVE_ANEXOS_ADICIONAIS = {type: 'REQUEST_SAVE_ANEXOS_ADICIONAIS'}
export const RECEIVE_SAVE_ANEXOS_ADICIONAIS = {type: 'RECEIVE_SAVE_ANEXOS_ADICIONAIS'}
export const REQUEST_PARTES_CORPO = 'REQUEST_PARTES_CORPO'
export const RECEIVE_PARTES_CORPO = 'RECEIVE_PARTES_CORPO'
export const REQUEST_ASO_LABELS_CLINICA = 'REQUEST_ASO_LABELS_CLINICA'
export const RECEIVE_ASO_LABELS_CLINICA = 'RECEIVE_ASO_LABELS_CLINICA'
export const REQUEST_PDF_HEADER_FOOTER = 'REQUEST_PDF_HEADER_FOOTER'
export const RECEIVE_PDF_HEADER_FOOTER = 'RECEIVE_PDF_HEADER_FOOTER'
export const REQUEST_DICOM_RESOURCES = 'REQUEST_DICOM_RESOURCES'
export const RECEIVE_DICOM_RESOURCES = 'RECEIVE_DICOM_RESOURCES'
export const RESET_DICOM_RESOURCES = 'RESET_DICOM_RESOURCES'
export const REQUEST_SOLICITAR_REVISAO = 'REQUEST_SOLICITAR_REVISAO'
export const RECEIVE_SOLICITAR_REVISAO = 'RECEIVE_SOLICITAR_REVISAO'
export const REQUEST_ENCAMINHAR_REVISAO = 'REQUEST_ENCAMINHAR_REVISAO'
export const RECEIVE_ENCAMINHAR_REVISAO = 'RECEIVE_ENCAMINHAR_REVISAO'
export const REQUEST_JUSTIFICAR_REVISAO = 'REQUEST_JUSTIFICAR_REVISAO'
export const RECEIVE_JUSTIFICAR_REVISAO = 'RECEIVE_JUSTIFICAR_REVISAO'
export const REQUEST_REMOVER_REVISAO = 'REQUEST_REMOVER_REVISAO'
export const RECEIVE_REMOVER_REVISAO = 'RECEIVE_REMOVER_REVISAO'
export const REQUEST_REMOVE_EXAME_ANEXO = 'REQUEST_REMOVE_EXAME_ANEXO'
export const RECEIVE_REMOVE_EXAME_ANEXO = 'RECEIVE_REMOVE_EXAME_ANEXO'
export const REQUEST_ACCEPT_EXAME_ANEXO = 'REQUEST_ACCEPT_EXAME_ANEXO'
export const RECEIVE_ACCEPT_EXAME_ANEXO = 'RECEIVE_ACCEPT_EXAME_ANEXO'
export const REQUEST_ADD_EXAME_ANEXOS = 'REQUEST_ADD_EXAME_ANEXOS'
export const RECEIVE_ADD_EXAME_ANEXOS = 'RECEIVE_ADD_EXAME_ANEXOS'
export const REQUEST_REPLACE_EXAME_ANEXO = 'REQUEST_REPLACE_EXAME_ANEXO'
export const RECEIVE_REPLACE_EXAME_ANEXO = 'RECEIVE_REPLACE_EXAME_ANEXO'
export const REQUEST_SEARCH_PCMSO_AUTOCOMPLETE = 'REQUEST_SEARCH_PCMSO_AUTOCOMPLETE'
export const RECEIVE_SEARCH_PCMSO_AUTOCOMPLETE = 'RECEIVE_SEARCH_PCMSO_AUTOCOMPLETE'
export const REQUEST_CARGOS_APROVACAO = 'REQUEST_CARGOS_APROVACAO'
export const RECEIVE_CARGOS_APROVACAO = 'RECEIVE_CARGOS_APROVACAO'

// fatura (boleto)
export const REQUEST_BOLETOS = {type: 'REQUEST_BOLETOS', id: 12000}
export const RECEIVE_BOLETOS = {type: 'RECEIVE_BOLETOS'}
export const REQUEST_BOLETO = {type: 'REQUEST_BOLETO', id: 12001}
export const RECEIVE_BOLETO = {type: 'RECEIVE_BOLETO'}
export const REQUEST_BALANCO_ANUAL = {type: 'REQUEST_BALANCO_ANUAL', id: 12002}
export const RECEIVE_BALANCO_ANUAL = {type: 'RECEIVE_BALANCO_ANUAL'}
export const REQUEST_ATUALIZAR_VENCIMENTO = {type: 'REQUEST_ATUALIZAR_VENCIMENTO', id: 12003}
export const RECEIVE_ATUALIZAR_VENCIMENTO = {type: 'RECEIVE_ATUALIZAR_VENCIMENTO'}
export const REQUEST_PRORROGAR_VENCIMENTO = 'REQUEST_PRORROGAR_VENCIMENTO'
export const RECEIVE_PRORROGAR_VENCIMENTO = 'RECEIVE_PRORROGAR_VENCIMENTO'
export const REQUEST_PORTESTO_SOLICITAR = 'REQUEST_PORTESTO_SOLICITAR'
export const RECEIVE_PORTESTO_SOLICITAR = 'RECEIVE_PORTESTO_SOLICITAR'
export const REQUEST_PORTESTO_CANCELAR = 'REQUEST_PORTESTO_CANCELAR'
export const RECEIVE_PORTESTO_CANCELAR = 'RECEIVE_PORTESTO_CANCELAR'
export const REQUEST_CANCELAR_FATURA = {type: 'REQUEST_CANCELAR_FATURA', id: 12003}
export const RECEIVE_CANCELAR_FATURA = {type: 'RECEIVE_CANCELAR_FATURA'}
export const REQUEST_UPDATE_FATURA = {type: 'REQUEST_UPDATE_FATURA', id: 12015}
export const RECEIVE_UPDATE_FATURA = {type: 'RECEIVE_UPDATE_FATURA'}
export const REQUEST_INFORMAR_PAGAMENTO = {type: 'REQUEST_INFORMAR_PAGAMENTO', id: 12004}
export const RECEIVE_INFORMAR_PAGAMENTO = {type: 'RECEIVE_INFORMAR_PAGAMENTO'}
export const REQUEST_FATURA_PIX = 'REQUEST_FATURA_PIX'
export const RECEIVE_FATURA_PIX = 'RECEIVE_FATURA_PIX'
export const REQUEST_REMESSAS = {type: 'REQUEST_REMESSAS', id: 12008}
export const RECEIVE_REMESSAS = {type: 'RECEIVE_REMESSAS'}
export const REQUEST_GENERATE_REMESSA = {type: 'REQUEST_GENERATE_REMESSA', id: 12009}
export const RECEIVE_GENERATE_REMESSA = {type: 'RECEIVE_GENERATE_REMESSA'}
export const REQUEST_RETORNOS = {type: 'REQUEST_RETORNOS', id: 12010}
export const RECEIVE_RETORNOS = {type: 'RECEIVE_RETORNOS'}
export const REQUEST_CAUSAS_REJEICAO_BANCO = {type: 'REQUEST_CAUSAS_REJEICAO_BANCO', id: 12012}
export const RECEIVE_CAUSAS_REJEICAO_BANCO = {type: 'RECEIVE_CAUSAS_REJEICAO_BANCO'}
export const REQUEST_STATUS = {type: 'REQUEST_STATUS', id: 12013}
export const RECEIVE_STATUS = {type: 'RECEIVE_STATUS'}
export const REQUEST_STATUS_BANCO = {type: 'REQUEST_STATUS_BANCO', id: 12014}
export const RECEIVE_STATUS_BANCO = {type: 'RECEIVE_STATUS_BANCO'}
export const REQUEST_PROCESS_RETORNO = {type: 'REQUEST_PROCESS_RETORNO', id: 12011}
export const RECEIVE_PROCESS_RETORNO = {type: 'RECEIVE_PROCESS_RETORNO'}
export const REQUEST_CONFIRMAR_PAGAMENTO = {type: 'REQUEST_CONFIRMAR_PAGAMENTO', id: 12005}
export const RECEIVE_CONFIRMAR_PAGAMENTO = {type: 'RECEIVE_CONFIRMAR_PAGAMENTO'}
export const REQUEST_REJEITAR_PAGAMENTO = {type: 'REQUEST_REJEITAR_PAGAMENTO', id: 12006}
export const RECEIVE_REJEITAR_PAGAMENTO = {type: 'RECEIVE_REJEITAR_PAGAMENTO'}
export const REQUEST_CREATE_FATURA = {type: 'REQUEST_CREATE_FATURA', id: 12007}
export const REQUEST_FATURAS_VENCIDAS = 'REQUEST_FATURAS_VENCIDAS'
export const RECEIVE_FATURAS_VENCIDAS = 'RECEIVE_FATURAS_VENCIDAS'
export const RESET_ALL_FATURA = {type: 'RESET_ALL_FATURA'}

// ferramentas

// filters
export const REQUEST_FILTERS = 'REQUEST_FILTERS'
export const RECEIVE_FILTERS = 'RECEIVE_FILTERS'
export const REQUEST_ADD_FILTER = 'REQUEST_ADD_FILTER'
export const RECEIVE_ADD_FILTER = 'RECEIVE_ADD_FILTER'
export const REQUEST_REMOVE_FILTER = 'REQUEST_REMOVE_FILTER'
export const RECEIVE_REMOVE_FILTER = 'RECEIVE_REMOVE_FILTER'
export const RESET_ALL_FILTER = 'RESET_ALL_FILTER'

// apoio
export const REQUEST_FERRAMENTAS_APOIO = {type: 'REQUEST_FERRAMENTAS_APOIO'}
export const RECEIVE_FERRAMENTAS_APOIO = {type: 'RECEIVE_FERRAMENTAS_APOIO'}
export const REQUEST_FERRAMENTA_APOIO = {type: 'REQUEST_FERRAMENTA_APOIO'}
export const RECEIVE_FERRAMENTA_APOIO = {type: 'RECEIVE_FERRAMENTA_APOIO'}
export const REQUEST_SAVE_FERRAMENTA_APOIO = {type: 'REQUEST_SAVE_FERRAMENTA_APOIO'}
export const RECEIVE_SAVE_FERRAMENTA_APOIO = {type: 'RECEIVE_SAVE_FERRAMENTA_APOIO'}
export const REQUEST_REMOVE_FERRAMENTA_APOIO = {type: 'REQUEST_REMOVE_FERRAMENTA_APOIO'}
export const RECEIVE_REMOVE_FERRAMENTA_APOIO = {type: 'RECEIVE_REMOVE_FERRAMENTA_APOIO'}
export const RESET_FERRAMENTA_APOIO = {type: 'RESET_FERRAMENTA_APOIO'}
export const RESET_ALL_FERRAMENTAS = {type: 'RESET_ALL_FERRAMENTAS'}
export const REQUEST_APOIO_DOCUMENTOS = {type: 'REQUEST_APOIO_DOCUMENTOS'}
export const RECEIVE_APOIO_DOCUMENTOS = {type: 'RECEIVE_APOIO_DOCUMENTOS'}
export const REQUEST_ENVIAR_APOIO_DOCUMENTO = {type: 'REQUEST_ENVIAR_APOIO_DOCUMENTO', id: 20000}
export const RECEIVE_ENVIAR_APOIO_DOCUMENTO = {type: 'RECEIVE_ENVIAR_APOIO_DOCUMENTO'}
export const REQUEST_REMOVE_APOIO_DOCUMENTO = {type: 'REQUEST_REMOVE_APOIO_DOCUMENTO'}
export const RECEIVE_REMOVE_APOIO_DOCUMENTO = {type: 'RECEIVE_REMOVE_APOIO_DOCUMENTO'}
// empresas concorrentes
export const REQUEST_EMPRESAS_CONCORRENTES = {type: 'REQUEST_EMPRESAS_CONCORRENTES'}
export const RECEIVE_EMPRESAS_CONCORRENTES = {type: 'RECEIVE_EMPRESAS_CONCORRENTES'}
export const REQUEST_EMPRESA_CONCORRENTE = {type: 'REQUEST_EMPRESA_CONCORRENTE'}
export const RECEIVE_EMPRESA_CONCORRENTE = {type: 'RECEIVE_EMPRESA_CONCORRENTE'}
export const REQUEST_SAVE_EMPRESA_CONCORRENTE = {type: 'REQUEST_SAVE_EMPRESA_CONCORRENTE'}
export const RECEIVE_SAVE_EMPRESA_CONCORRENTE = {type: 'RECEIVE_SAVE_EMPRESA_CONCORRENTE'}
export const REQUEST_REMOVE_EMPRESA_CONCORRENTE = {type: 'REQUEST_REMOVE_EMPRESA_CONCORRENTE'}
export const RECEIVE_REMOVE_EMPRESA_CONCORRENTE = {type: 'RECEIVE_REMOVE_EMPRESA_CONCORRENTE'}
export const RESET_EMPRESA_CONCORRENTE = {type: 'RESET_EMPRESA_CONCORRENTE'}
// especialidades concorrentes
export const REQUEST_ESPECIALIDADES_CONCORRENTES = {type: 'REQUEST_ESPECIALIDADES_CONCORRENTES'}
export const RECEIVE_ESPECIALIDADES_CONCORRENTES = {type: 'RECEIVE_ESPECIALIDADES_CONCORRENTES'}
export const REQUEST_ESPECIALIDADE_CONCORRENTE = {type: 'REQUEST_ESPECIALIDADE_CONCORRENTE'}
export const RECEIVE_ESPECIALIDADE_CONCORRENTE = {type: 'RECEIVE_ESPECIALIDADE_CONCORRENTE'}
export const REQUEST_SAVE_ESPECIALIDADE_CONCORRENTE = {type: 'REQUEST_SAVE_ESPECIALIDADE_CONCORRENTE'}
export const RECEIVE_SAVE_ESPECIALIDADE_CONCORRENTE = {type: 'RECEIVE_SAVE_ESPECIALIDADE_CONCORRENTE'}
export const REQUEST_REMOVE_ESPECIALIDADE_CONCORRENTE = {type: 'REQUEST_REMOVE_ESPECIALIDADE_CONCORRENTE'}
export const RECEIVE_REMOVE_ESPECIALIDADE_CONCORRENTE = {type: 'RECEIVE_REMOVE_ESPECIALIDADE_CONCORRENTE'}
export const RESET_ESPECIALIDADE_CONCORRENTE = {type: 'RESET_ESPECIALIDADE_CONCORRENTE'}

// image viewer
export const OPEN_IMAGE_VIEWER = 'OPEN_IMAGE_VIEWER'
export const CLOSE_IMAGE_VIEWER = 'CLOSE_IMAGE_VIEWER'

// laudo
export const REQUEST_LAUDOS = 'REQUEST_LAUDOS'
export const RECEIVE_LAUDOS = 'RECEIVE_LAUDOS'
export const REQUEST_LAUDO = 'REQUEST_LAUDO'
export const RECEIVE_LAUDO = 'RECEIVE_LAUDO'
export const REQUEST_LAUDO_TRANSLATE = 'REQUEST_LAUDO_TRANSLATE'
export const RECEIVE_LAUDO_TRANSLATE = 'RECEIVE_LAUDO_TRANSLATE'
export const REQUEST_LAUDOS_BY_PACIENTE = 'REQUEST_LAUDOS_BY_PACIENTE'
export const RECEIVE_LAUDOS_BY_PACIENTE = 'RECEIVE_LAUDOS_BY_PACIENTE'
export const REQUEST_SAVE_LAUDO = 'REQUEST_SAVE_LAUDO'
export const RECEIVE_SAVE_LAUDO = 'RECEIVE_SAVE_LAUDO'
export const REQUEST_LAUDO_TEXTO_TAGS = 'REQUEST_LAUDO_TEXTO_TAGS'
export const RECEIVE_LAUDO_TEXTO_TAGS = 'RECEIVE_LAUDO_TEXTO_TAGS'
export const REQUEST_SAVE_LAUDO_TEXTO_TAG = 'REQUEST_SAVE_LAUDO_TEXTO_TAG'
export const RECEIVE_SAVE_LAUDO_TEXTO_TAG = 'RECEIVE_SAVE_LAUDO_TEXTO_TAG'
export const REQUEST_LAUDO_TEXTOS = 'REQUEST_LAUDO_TEXTOS'
export const RECEIVE_LAUDO_TEXTOS = 'RECEIVE_LAUDO_TEXTOS'
export const REQUEST_LAUDO_TEXTO = 'REQUEST_LAUDO_TEXTO'
export const RECEIVE_LAUDO_TEXTO = 'RECEIVE_LAUDO_TEXTO'
export const RESET_LAUDO_TEXTO = 'RESET_LAUDO_TEXTO'
export const REQUEST_SAVE_LAUDO_TEXTO = 'REQUEST_SAVE_LAUDO_TEXTO'
export const RECEIVE_SAVE_LAUDO_TEXTO = 'RECEIVE_SAVE_LAUDO_TEXTO'
export const REQUEST_DELETE_LAUDO_TEXTO = 'REQUEST_DELETE_LAUDO_TEXTO'
export const RECEIVE_DELETE_LAUDO_TEXTO = 'RECEIVE_DELETE_LAUDO_TEXTO'
export const REQUEST_LAUDO_TEXTOS_BY_ESPECIALIDADE = 'REQUEST_LAUDO_TEXTOS_BY_ESPECIALIDADE'
export const RECEIVE_LAUDO_TEXTOS_BY_ESPECIALIDADE = 'RECEIVE_LAUDO_TEXTOS_BY_ESPECIALIDADE'
export const REQUEST_LAUDO_TEXTOS_OIT_ADICIONAIS = 'REQUEST_LAUDO_TEXTOS_OIT_ADICIONAIS'
export const RECEIVE_LAUDO_TEXTOS_OIT_ADICIONAIS = 'RECEIVE_LAUDO_TEXTOS_OIT_ADICIONAIS'
export const REQUEST_REMOVE_LAUDO = {type: 'REQUEST_REMOVE_LAUDO', id: 15000}
export const RECEIVE_REMOVE_LAUDO = {type: 'RECEIVE_REMOVE_LAUDO'}
export const REQUEST_LABELS_BONUS = 'REQUEST_LABELS_BONUS'
export const RECEIVE_LABELS_BONUS = 'RECEIVE_LABELS_BONUS'
export const RESET_ALL_LAUDO = 'RESET_ALL_LAUDO'

// mail
export const REQUEST_MAILS = 'REQUEST_MAILS'
export const RECEIVE_MAILS = 'RECEIVE_MAILS'
export const REQUEST_MAIL = 'REQUEST_MAIL'
export const RECEIVE_MAIL = 'RECEIVE_MAIL'
export const REQUEST_MAILS_BLACK_LIST = 'REQUEST_MAILS_BLACK_LIST'
export const RECEIVE_MAILS_BLACK_LIST = 'RECEIVE_MAILS_BLACK_LIST'
export const REQUEST_BLACK_LIST_MARK_READ = 'REQUEST_BLACK_LIST_MARK_READ'
export const RECEIVE_BLACK_LIST_MARK_READ = 'RECEIVE_BLACK_LIST_MARK_READ'
export const RESET_ALL_MAIL = 'RESET_ALL_MAIL'

// medico
export const REQUEST_MEDICOS = 'REQUEST_MEDICOS'
export const RECEIVE_MEDICOS = 'RECEIVE_MEDICOS'
export const REQUEST_ALL_MEDICOS = {type: 'REQUEST_ALL_MEDICOS'}
export const RECEIVE_ALL_MEDICOS = {type: 'RECEIVE_ALL_MEDICOS'}
export const REQUEST_MEDICO = 'REQUEST_MEDICO'
export const RECEIVE_MEDICO = 'RECEIVE_MEDICO'
export const REQUEST_AVALIACOES = 'REQUEST_AVALIACOES'
export const RECEIVE_AVALIACOES = 'RECEIVE_AVALIACOES'
export const REQUEST_AVALIACAO = 'REQUEST_AVALIACAO'
export const RECEIVE_AVALIACAO = 'RECEIVE_AVALIACAO'
export const REQUEST_SAVE_AVALIACAO = 'REQUEST_SAVE_AVALIACAO'
export const RECEIVE_SAVE_AVALIACAO = 'RECEIVE_SAVE_AVALIACAO'
export const REQUEST_MEDICO_DOCUMENTOS = 'REQUEST_MEDICO_DOCUMENTOS'
export const RECEIVE_MEDICO_DOCUMENTOS = 'RECEIVE_MEDICO_DOCUMENTOS'
export const REQUEST_ENVIAR_DOCUMENTO = {type: 'REQUEST_ENVIAR_DOCUMENTO', id: 17000}
export const RECEIVE_ENVIAR_DOCUMENTO = {type: 'RECEIVE_ENVIAR_DOCUMENTO'}
export const REQUEST_REMOVE_DOCUMENTO = {type: 'REQUEST_REMOVE_DOCUMENTO'}
export const RECEIVE_REMOVE_DOCUMENTO = {type: 'RECEIVE_REMOVE_DOCUMENTO'}
export const REQUEST_DOCUMENTOS_MEDICOS_BY_CLINICA = 'REQUEST_DOCUMENTOS_MEDICOS_BY_CLINICA'
export const RECEIVE_DOCUMENTOS_MEDICOS_BY_CLINICA = 'RECEIVE_DOCUMENTOS_MEDICOS_BY_CLINICA'
export const REQUEST_MEDICO_ESPECIALIDADES = 'REQUEST_MEDICO_ESPECIALIDADES'
export const RECEIVE_MEDICO_ESPECIALIDADES = 'RECEIVE_MEDICO_ESPECIALIDADES'
export const REQUEST_NOTAS_SERVICOS = 'REQUEST_NOTAS_SERVICOS'
export const RECEIVE_NOTAS_SERVICOS = 'RECEIVE_NOTAS_SERVICOS'
export const REQUEST_SAVE_NOTA_SERVICOS = 'REQUEST_SAVE_NOTA_SERVICOS'
export const RECEIVE_SAVE_NOTA_SERVICOS = 'RECEIVE_SAVE_NOTA_SERVICOS'
export const REQUEST_REMOVE_NOTA_SERVICOS = 'REQUEST_REMOVE_NOTA_SERVICOS'
export const RECEIVE_REMOVE_NOTA_SERVICOS = 'RECEIVE_REMOVE_NOTA_SERVICOS'
export const RESET_ALL_MEDICO = 'RESET_ALL_MEDICO'
export const RESET_MEDICO = 'RESET_MEDICO'
export const REQUEST_SAVE_MEDICO = 'REQUEST_SAVE_MEDICO'
export const RECEIVE_SAVE_MEDICO = 'RECEIVE_SAVE_MEDICO'
export const REQUEST_SAVE_MEDICO_ATENDIMENTO = 'REQUEST_SAVE_MEDICO_ATENDIMENTO'
export const RECEIVE_SAVE_MEDICO_ATENDIMENTO = 'RECEIVE_SAVE_MEDICO_ATENDIMENTO'
export const REQUEST_SAVE_MEDICO_ENDERECOS = 'REQUEST_SAVE_MEDICO_ENDERECOS'
export const RECEIVE_SAVE_MEDICO_ENDERECOS = 'RECEIVE_SAVE_MEDICO_ENDERECOS'
export const REQUEST_CHANGE_NOTIFICATION_OPTIONS = 'REQUEST_CHANGE_NOTIFICATION_OPTIONS'
export const RECEIVE_CHANGE_NOTIFICATION_OPTIONS = 'RECEIVE_CHANGE_NOTIFICATION_OPTIONS'
export const REQUEST_SAVE_PROFILE_MEDICO = 'REQUEST_SAVE_PROFILE_MEDICO'
export const RECEIVE_SAVE_PROFILE_MEDICO = 'RECEIVE_SAVE_PROFILE_MEDICO'
export const REQUEST_SAVE_SIGNATURE = 'REQUEST_SAVE_SIGNATURE'
export const RECEIVE_SAVE_SIGNATURE = 'RECEIVE_SAVE_SIGNATURE'
export const REQUEST_SAVE_CONFIG_PLANTAO = 'REQUEST_SAVE_CONFIG_PLANTAO'
export const RECEIVE_SAVE_CONFIG_PLANTAO = 'RECEIVE_SAVE_CONFIG_PLANTAO'
export const REQUEST_SAVE_AUTENTICAR_MEDICO = 'REQUEST_SAVE_AUTENTICAR_MEDICO'
export const RECEIVE_SAVE_AUTENTICAR_MEDICO = 'RECEIVE_SAVE_AUTENTICAR_MEDICO'
export const REQUEST_REMOVE_MEDICO = 'REQUEST_REMOVE_MEDICO'
export const RECEIVE_REMOVE_MEDICO = 'RECEIVE_REMOVE_MEDICO'
export const REQUEST_SAVE_APTO_TELECONSULTA = 'REQUEST_SAVE_APTO_TELECONSULTA'
export const RECEIVE_SAVE_APTO_TELECONSULTA = 'RECEIVE_SAVE_APTO_TELECONSULTA'

// mensagem
export const REQUEST_MENSAGEM_CHATS = 'REQUEST_MENSAGEM_CHATS'
export const RECEIVE_MENSAGEM_CHATS = 'RECEIVE_MENSAGEM_CHATS'
export const REQUEST_SAVE_MENSAGEM = 'REQUEST_SAVE_MENSAGEM'
export const RECEIVE_SAVE_MENSAGEM = 'RECEIVE_SAVE_MENSAGEM'
export const REQUEST_SAVE_MENSAGEM_SOLVED = 'REQUEST_SAVE_MENSAGEM_SOLVED'
export const RECEIVE_SAVE_MENSAGEM_SOLVED = 'RECEIVE_SAVE_MENSAGEM_SOLVED'

// nota (nfse)
export const REQUEST_NOTAS = 'REQUEST_NOTAS'
export const RECEIVE_NOTAS = 'RECEIVE_NOTAS'
export const REQUEST_NOTA = 'REQUEST_NOTA'
export const RECEIVE_NOTA = 'RECEIVE_NOTA'
export const RESET_ALL_NOTA = 'RESET_ALL_NOTA'

// paciente
export const REQUEST_ALL_PACIENTES = {type: 'REQUEST_ALL_PACIENTES'}
export const RECEIVE_ALL_PACIENTES = {type: 'RECEIVE_ALL_PACIENTES'}
export const REQUEST_ALL_EMPRESAS = 'REQUEST_ALL_EMPRESAS'
export const RECEIVE_ALL_EMPRESAS = 'RECEIVE_ALL_EMPRESAS'
export const REQUEST_PACIENTES = 'REQUEST_PACIENTES'
export const RECEIVE_PACIENTES = 'RECEIVE_PACIENTES'
export const REQUEST_PACIENTE = 'REQUEST_PACIENTE'
export const RECEIVE_PACIENTE = 'RECEIVE_PACIENTE'
export const REQUEST_PACIENTE_CALL_STATUS = {type: 'REQUEST_PACIENTE_CALL_STATUS'}
export const RECEIVE_PACIENTE_CALL_STATUS = {type: 'RECEIVE_PACIENTE_CALL_STATUS'}
export const REQUEST_PACIENTE_PRONTUARIO = {type: 'REQUEST_PACIENTE_PRONTUARIO'}
export const RECEIVE_PACIENTE_PRONTUARIO = {type: 'RECEIVE_PACIENTE_PRONTUARIO'}
export const RESET_PACIENTE = 'RESET_PACIENTE'
export const REQUEST_VALIDATE_PACIENTE = 'REQUEST_VALIDATE_PACIENTE'
export const RECEIVE_VALIDATE_PACIENTE = 'RECEIVE_VALIDATE_PACIENTE'
export const REQUEST_SAVE_PACIENTE = 'REQUEST_SAVE_PACIENTE'
export const RECEIVE_SAVE_PACIENTE = 'RECEIVE_SAVE_PACIENTE'
export const REQUEST_SAVE_PACIENTE_ATENDIMENTO = {type: 'REQUEST_SAVE_PACIENTE_ATENDIMENTO'}
export const RECEIVE_SAVE_PACIENTE_ATENDIMENTO = {type: 'RECEIVE_SAVE_PACIENTE_ATENDIMENTO'}
export const REQUEST_CHANGE_PASSWD_PACIENTE = {type: 'REQUEST_CHANGE_PASSWD_PACIENTE'}
export const RECEIVE_CHANGE_PASSWD_PACIENTE = {type: 'RECEIVE_CHANGE_PASSWD_PACIENTE'}
export const REQUEST_SAVE_ANEXOS_PACIENTE = {type: 'REQUEST_SAVE_ANEXOS_PACIENTE'}
export const RECEIVE_SAVE_ANEXOS_PACIENTE = {type: 'RECEIVE_SAVE_ANEXOS_PACIENTE'}
export const REQUEST_REMOVE_ANEXOS_PACIENTE = {type: 'REQUEST_REMOVE_ANEXOS_PACIENTE'}
export const RECEIVE_REMOVE_ANEXOS_PACIENTE = {type: 'RECEIVE_REMOVE_ANEXOS_PACIENTE'}
export const REQUEST_CERT_SIGN_ANEXO_PACIENTE = 'REQUEST_CERT_SIGN_ANEXO_PACIENTE'
export const RECEIVE_CERT_SIGN_ANEXO_PACIENTE = 'RECEIVE_CERT_SIGN_ANEXO_PACIENTE'
export const REQUEST_PACIENTE_CANCEL_AGENDAMENTO = {type: 'REQUEST_PACIENTE_CANCEL_AGENDAMENTO'}
export const RECEIVE_PACIENTE_CANCEL_AGENDAMENTO = {type: 'RECEIVE_PACIENTE_CANCEL_AGENDAMENTO'}
export const REQUEST_PACIENTE_RESCHEDULE_AGENDAMENTO = {type: 'REQUEST_PACIENTE_RESCHEDULE_AGENDAMENTO'}
export const RECEIVE_PACIENTE_RESCHEDULE_AGENDAMENTO = {type: 'RECEIVE_PACIENTE_RESCHEDULE_AGENDAMENTO'}
export const REQUEST_PACIENTE_RETORNO_AGENDAMENTO = {type: 'REQUEST_PACIENTE_RETORNO_AGENDAMENTO'}
export const RECEIVE_PACIENTE_RETORNO_AGENDAMENTO = {type: 'RECEIVE_PACIENTE_RETORNO_AGENDAMENTO'}
export const REQUEST_CHECK_TIME_AVAILABILITY = {type: 'REQUEST_CHECK_TIME_AVAILABILITY'}
export const RECEIVE_CHECK_TIME_AVAILABILITY = {type: 'RECEIVE_CHECK_TIME_AVAILABILITY'}
export const REQUEST_ACCESS_PROTOCOL = 'REQUEST_ACCESS_PROTOCOL'
export const RECEIVE_ACCESS_PROTOCOL = 'RECEIVE_ACCESS_PROTOCOL'
export const REQUEST_SAVE_SIGNATURE_PACIENTE = 'REQUEST_SAVE_SIGNATURE_PACIENTE'
export const RECEIVE_SAVE_SIGNATURE_PACIENTE = 'RECEIVE_SAVE_SIGNATURE_PACIENTE'
export const RESET_ALL_PACIENTE = 'RESET_ALL_PACIENTE'

// plan
export const REQUEST_ALL_PLANS = 'REQUEST_ALL_PLANS'
export const RECEIVE_ALL_PLANS = 'RECEIVE_ALL_PLANS'
export const REQUEST_PLANS = 'REQUEST_PLANS'
export const RECEIVE_PLANS = 'RECEIVE_PLANS'
export const REQUEST_PLAN = 'REQUEST_PLAN'
export const RECEIVE_PLAN = 'RECEIVE_PLAN'
export const REQUEST_SAVE_PLAN = 'REQUEST_SAVE_PLAN'
export const RECEIVE_SAVE_PLAN = 'RECEIVE_SAVE_PLAN'
export const RESET_PLAN = 'RESET_PLAN'
export const RESET_ALL_PLAN = 'RESET_ALL_PLAN'

// plantao
export const REQUEST_ALL_PLANTOES = {type: 'REQUEST_ALL_PLANTOES'}
export const RECEIVE_ALL_PLANTOES = {type: 'RECEIVE_ALL_PLANTOES'}
export const REQUEST_PLANTOES = {type: 'REQUEST_PLANTOES'}
export const RECEIVE_PLANTOES = {type: 'RECEIVE_PLANTOES'}
export const REQUEST_PLANTAO = {type: 'REQUEST_PLANTAO'}
export const RECEIVE_PLANTAO = {type: 'RECEIVE_PLANTAO'}
export const REQUEST_SAVE_PLANTAO = {type: 'REQUEST_SAVE_PLANTAO'}
export const RECEIVE_SAVE_PLANTAO = {type: 'RECEIVE_SAVE_PLANTAO'}
export const REQUEST_PLANTAO_FILA = {type: 'REQUEST_PLANTAO_FILA'}
export const RECEIVE_PLANTAO_FILA = {type: 'RECEIVE_PLANTAO_FILA'}
export const REQUEST_PLANTAO_FILA_MEDICO = {type: 'REQUEST_PLANTAO_FILA_MEDICO'}
export const RECEIVE_PLANTAO_FILA_MEDICO = {type: 'RECEIVE_PLANTAO_FILA_MEDICO'}
export const REQUEST_PLANTAO_FILA_ITEM = {type: 'REQUEST_PLANTAO_FILA_ITEM'}
export const RECEIVE_PLANTAO_FILA_ITEM = {type: 'RECEIVE_PLANTAO_FILA_ITEM'}
export const REQUEST_CANCEL_PLANTAO_FILA_ITEM = {type: 'REQUEST_CANCEL_PLANTAO_FILA_ITEM'}
export const RECEIVE_CANCEL_PLANTAO_FILA_ITEM = {type: 'RECEIVE_CANCEL_PLANTAO_FILA_ITEM'}
export const RESET_PLANTAO = {type: 'RESET_PLANTAO'}
export const RESET_ALL_PLANTAO = {type: 'RESET_ALL_PLANTAO'}

// plantao unico
export const REQUEST_PLANTAO_QUEUE_INFO = 'REQUEST_PLANTAO_QUEUE_INFO'
export const RECEIVE_PLANTAO_QUEUE_INFO = 'RECEIVE_PLANTAO_QUEUE_INFO'
export const REQUEST_CAPTURAR_PLANTAO_UNICO = 'REQUEST_CAPTURAR_PLANTAO_UNICO'
export const RECEIVE_CAPTURAR_PLANTAO_UNICO = 'RECEIVE_CAPTURAR_PLANTAO_UNICO'
export const RESET_ALL_PLANTAO_UNICO = 'RESET_ALL_PLANTAO_UNICO'

// procedimentos
export const REQUEST_ALL_PROCEDIMENTOS = 'REQUEST_ALL_PROCEDIMENTOS'
export const RECEIVE_ALL_PROCEDIMENTOS = 'RECEIVE_ALL_PROCEDIMENTOS'
export const REQUEST_PROCEDIMENTOS = 'REQUEST_PROCEDIMENTOS'
export const RECEIVE_PROCEDIMENTOS = 'RECEIVE_PROCEDIMENTOS'
export const REQUEST_PROCEDIMENTO = 'REQUEST_PROCEDIMENTO'
export const RECEIVE_PROCEDIMENTO = 'RECEIVE_PROCEDIMENTO'
export const REQUEST_SAVE_PROCEDIMENTO = 'REQUEST_SAVE_PROCEDIMENTO'
export const RECEIVE_SAVE_PROCEDIMENTO = 'RECEIVE_SAVE_PROCEDIMENTO'
export const RESET_PROCEDIMENTO = 'RESET_PROCEDIMENTO'
export const RESET_ALL_PROCEDIMENTO = 'RESET_ALL_PROCEDIMENTO'

// prontuario
export const REQUEST_TEMPLATE_PRONTUARIO_MEDICO = {type: 'REQUEST_TEMPLATE_PRONTUARIO_MEDICO'}
export const RECEIVE_TEMPLATE_PRONTUARIO_MEDICO = {type: 'RECEIVE_TEMPLATE_PRONTUARIO_MEDICO'}
export const REQUEST_SAVE_PRONTUARIO_SECTION_INFO = {type: 'REQUEST_SAVE_PRONTUARIO_SECTION_INFO'}
export const RECEIVE_SAVE_PRONTUARIO_SECTION_INFO = {type: 'RECEIVE_SAVE_PRONTUARIO_SECTION_INFO'}
export const REQUEST_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO = {type: 'REQUEST_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO'}
export const RECEIVE_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO = {type: 'RECEIVE_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO'}
export const REQUEST_SAVE_PRONTUARIO_PRESCRICAO = {type: 'REQUEST_SAVE_PRONTUARIO_PRESCRICAO'}
export const RECEIVE_SAVE_PRONTUARIO_PRESCRICAO = {type: 'RECEIVE_SAVE_PRONTUARIO_PRESCRICAO'}
export const REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO = {type: 'REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO'}
export const RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO = {type: 'RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO'}
export const REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES = {type: 'REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES'}
export const RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES = {type: 'RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES'}
export const REQUEST_SAVE_PRONTUARIO_ANEXOS = {type: 'REQUEST_SAVE_PRONTUARIO_ANEXOS'}
export const RECEIVE_SAVE_PRONTUARIO_ANEXOS = {type: 'RECEIVE_SAVE_PRONTUARIO_ANEXOS'}
export const REQUEST_REMOVE_PRONTUARIO_ANEXOS = {type: 'REQUEST_REMOVE_PRONTUARIO_ANEXOS'}
export const RECEIVE_REMOVE_PRONTUARIO_ANEXOS = {type: 'RECEIVE_REMOVE_PRONTUARIO_ANEXOS'}
// prontuario grupos, seções e campos
export const REQUEST_SECAO_GRUPOS = {type: 'REQUEST_SECAO_GRUPOS'}
export const RECEIVE_SECAO_GRUPOS = {type: 'RECEIVE_SECAO_GRUPOS'}
export const REQUEST_SECAO_GRUPO = {type: 'REQUEST_SECAO_GRUPO'}
export const RECEIVE_SECAO_GRUPO = {type: 'RECEIVE_SECAO_GRUPO'}
export const REQUEST_REMOVE_SECAO_GRUPO = {type: 'REQUEST_REMOVE_SECAO_GRUPO'}
export const RECEIVE_REMOVE_SECAO_GRUPO = {type: 'RECEIVE_REMOVE_SECAO_GRUPO'}
export const REQUEST_COPY_SECAO_GRUPO = {type: 'REQUEST_COPY_SECAO_GRUPO'}
export const RECEIVE_COPY_SECAO_GRUPO = {type: 'RECEIVE_COPY_SECAO_GRUPO'}
export const REQUEST_SAVE_SECAO_GRUPO = {type: 'REQUEST_SAVE_SECAO_GRUPO'}
export const RECEIVE_SAVE_SECAO_GRUPO = {type: 'RECEIVE_SAVE_SECAO_GRUPO'}

// modelo documento_atestado
export const REQUEST_DOCUMENTO_ATESTADO_MODELOS = {type: 'REQUEST_DOCUMENTO_ATESTADO_MODELOS'}
export const RECEIVE_DOCUMENTO_ATESTADO_MODELOS = {type: 'RECEIVE_DOCUMENTO_ATESTADO_MODELOS'}
export const REQUEST_DOCUMENTO_ATESTADO_MODELO = {type: 'REQUEST_DOCUMENTO_ATESTADO_MODELO'}
export const RECEIVE_DOCUMENTO_ATESTADO_MODELO = {type: 'RECEIVE_DOCUMENTO_ATESTADO_MODELO'}
export const RESET_DOCUMENTO_ATESTADO_MODELO = {type: 'RESET_DOCUMENTO_ATESTADO_MODELO'}
export const REQUEST_SAVE_DOCUMENTO_ATESTADO_MODELO = {type: 'REQUEST_SAVE_DOCUMENTO_ATESTADO_MODELO'}
export const RECEIVE_SAVE_DOCUMENTO_ATESTADO_MODELO = {type: 'RECEIVE_SAVE_DOCUMENTO_ATESTADO_MODELO'}
export const REQUEST_ALL_DOCUMENTO_ATESTADO_MODELO = {type: 'REQUEST_ALL_DOCUMENTO_ATESTADO_MODELO'}
export const RECEIVE_ALL_DOCUMENTO_ATESTADO_MODELO = {type: 'RECEIVE_ALL_DOCUMENTO_ATESTADO_MODELO'}
// modelo documento_orientacoes
export const REQUEST_DOCUMENTO_ORIENTACOES_MODELOS = {type: 'REQUEST_DOCUMENTO_ORIENTACOES_MODELOS'}
export const RECEIVE_DOCUMENTO_ORIENTACOES_MODELOS = {type: 'RECEIVE_DOCUMENTO_ORIENTACOES_MODELOS'}
export const REQUEST_DOCUMENTO_ORIENTACOES_MODELO = {type: 'REQUEST_DOCUMENTO_ORIENTACOES_MODELO'}
export const RECEIVE_DOCUMENTO_ORIENTACOES_MODELO = {type: 'RECEIVE_DOCUMENTO_ORIENTACOES_MODELO'}
export const RESET_DOCUMENTO_ORIENTACOES_MODELO = {type: 'RESET_DOCUMENTO_ORIENTACOES_MODELO'}
export const REQUEST_SAVE_DOCUMENTO_ORIENTACOES_MODELO = {type: 'REQUEST_SAVE_DOCUMENTO_ORIENTACOES_MODELO'}
export const RECEIVE_SAVE_DOCUMENTO_ORIENTACOES_MODELO = {type: 'RECEIVE_SAVE_DOCUMENTO_ORIENTACOES_MODELO'}
export const REQUEST_ALL_DOCUMENTO_ORIENTACOES_MODELO = {type: 'REQUEST_ALL_DOCUMENTO_ORIENTACOES_MODELO'}
export const RECEIVE_ALL_DOCUMENTO_ORIENTACOES_MODELO = {type: 'RECEIVE_ALL_DOCUMENTO_ORIENTACOES_MODELO'}
// modelo prescricao
export const REQUEST_PRESCRICAO_MODELOS = {type: 'REQUEST_PRESCRICAO_MODELOS'}
export const RECEIVE_PRESCRICAO_MODELOS = {type: 'RECEIVE_PRESCRICAO_MODELOS'}
export const REQUEST_PRESCRICAO_MODELO = {type: 'REQUEST_PRESCRICAO_MODELO'}
export const RECEIVE_PRESCRICAO_MODELO = {type: 'RECEIVE_PRESCRICAO_MODELO'}
export const RESET_PRESCRICAO_MODELO = {type: 'RESET_PRESCRICAO_MODELO'}
export const REQUEST_SAVE_PRESCRICAO_MODELO = {type: 'REQUEST_SAVE_PRESCRICAO_MODELO'}
export const RECEIVE_SAVE_PRESCRICAO_MODELO = {type: 'RECEIVE_SAVE_PRESCRICAO_MODELO'}
export const REQUEST_ALL_PRESCRICAO_MODELO = {type: 'REQUEST_ALL_PRESCRICAO_MODELO'}
export const RECEIVE_ALL_PRESCRICAO_MODELO = {type: 'RECEIVE_ALL_PRESCRICAO_MODELO'}
// modelo exame_procedimento
export const REQUEST_EXAME_PROCEDIMENTO_MODELOS = {type: 'REQUEST_EXAME_PROCEDIMENTO_MODELOS'}
export const RECEIVE_EXAME_PROCEDIMENTO_MODELOS = {type: 'RECEIVE_EXAME_PROCEDIMENTO_MODELOS'}
export const REQUEST_EXAME_PROCEDIMENTO_MODELO = {type: 'REQUEST_EXAME_PROCEDIMENTO_MODELO'}
export const RECEIVE_EXAME_PROCEDIMENTO_MODELO = {type: 'RECEIVE_EXAME_PROCEDIMENTO_MODELO'}
export const RESET_EXAME_PROCEDIMENTO_MODELO = {type: 'RESET_EXAME_PROCEDIMENTO_MODELO'}
export const REQUEST_SAVE_EXAME_PROCEDIMENTO_MODELO = {type: 'REQUEST_SAVE_EXAME_PROCEDIMENTO_MODELO'}
export const RECEIVE_SAVE_EXAME_PROCEDIMENTO_MODELO = {type: 'RECEIVE_SAVE_EXAME_PROCEDIMENTO_MODELO'}
export const REQUEST_ALL_EXAME_PROCEDIMENTO_MODELO = {type: 'REQUEST_ALL_EXAME_PROCEDIMENTO_MODELO'}
export const RECEIVE_ALL_EXAME_PROCEDIMENTO_MODELO = {type: 'RECEIVE_ALL_EXAME_PROCEDIMENTO_MODELO'}
export const REQUEST_SAVE_PRONTUARIO_ASO_ANAMNESE = 'REQUEST_SAVE_PRONTUARIO_ASO_ANAMNESE'
export const RECEIVE_SAVE_PRONTUARIO_ASO_ANAMNESE = 'RECEIVE_SAVE_PRONTUARIO_ASO_ANAMNESE'
export const REQUEST_SAVE_PRONTUARIO_ASO = 'REQUEST_SAVE_PRONTUARIO_ASO'
export const RECEIVE_SAVE_PRONTUARIO_ASO = 'RECEIVE_SAVE_PRONTUARIO_ASO'

// prescricao renovacao
export const REQUEST_PRESCRICOES_RENOVACAO = 'REQUEST_PRESCRICOES_RENOVACAO'
export const RECEIVE_PRESCRICOES_RENOVACAO = 'RECEIVE_PRESCRICOES_RENOVACAO'
export const REQUEST_PRESCRICAO_RENOVACAO = 'REQUEST_PRESCRICAO_RENOVACAO'
export const RECEIVE_PRESCRICAO_RENOVACAO = 'RECEIVE_PRESCRICAO_RENOVACAO'
export const REQUEST_SAVE_PRESCRICAO_RENOVACAO = 'REQUEST_SAVE_PRESCRICAO_RENOVACAO'
export const RECEIVE_SAVE_PRESCRICAO_RENOVACAO = 'RECEIVE_SAVE_PRESCRICAO_RENOVACAO'
export const REQUEST_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO = 'REQUEST_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO'
export const RECEIVE_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO = 'RECEIVE_SAVE_PRESCRICAO_RENOVACAO_REEMBOLSO'
export const REQUEST_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM = 'REQUEST_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM'
export const RECEIVE_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM = 'RECEIVE_PRESCRICAO_RENOVACAO_CRIAR_PRE_POSTAGEM'
export const REQUEST_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM = 'REQUEST_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM'
export const RECEIVE_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM = 'RECEIVE_PRESCRICAO_RENOVACAO_CANCELAR_PRE_POSTAGEM'
export const REQUEST_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR = 'REQUEST_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR'
export const RECEIVE_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR = 'RECEIVE_PRESCRICAO_RENOVACAO_PRE_POSTAGEM_IMPRESSAO_SOLICITAR'
export const RESET_ALL_PRESCRICAO_RENOVACAO = 'RESET_ALL_PRESCRICAO_RENOVACAO'

// pagamento
export const REQUEST_CREDIT_CARDS = 'REQUEST_CREDIT_CARDS'
export const RECEIVE_CREDIT_CARDS = 'RECEIVE_CREDIT_CARDS'
export const REQUEST_CREDIT_CARD = 'REQUEST_CREDIT_CARD'
export const RECEIVE_CREDIT_CARD = 'RECEIVE_CREDIT_CARD'
export const REQUEST_SAVE_CREDIT_CARD = 'REQUEST_SAVE_CREDIT_CARD'
export const RECEIVE_SAVE_CREDIT_CARD = 'RECEIVE_SAVE_CREDIT_CARD'
export const RESET_CREDIT_CARD = 'RESET_CREDIT_CARD'
export const REQUEST_REMOVE_CREDIT_CARD = 'REQUEST_REMOVE_CREDIT_CARD'
export const RECEIVE_REMOVE_CREDIT_CARD = 'RECEIVE_REMOVE_CREDIT_CARD'
export const REQUEST_URL_PAGAMENTO = {type: 'REQUEST_URL_PAGAMENTO'}
export const RECEIVE_URL_PAGAMENTO = {type: 'RECEIVE_URL_PAGAMENTO'}
export const REQUEST_EXECUTE_PAGAMENTO = {type: 'REQUEST_EXECUTE_PAGAMENTO'}
export const RECEIVE_EXECUTE_PAGAMENTO = {type: 'RECEIVE_EXECUTE_PAGAMENTO'}
export const REQUEST_SAVE_PAYPAL_REMEMBER_CARD = {type: 'REQUEST_SAVE_PAYPAL_REMEMBER_CARD'}
export const RECEIVE_SAVE_PAYPAL_REMEMBER_CARD = {type: 'RECEIVE_SAVE_PAYPAL_REMEMBER_CARD'}
export const REQUEST_CONFIRM_PAGAMENTO = 'REQUEST_CONFIRM_PAGAMENTO'
export const RECEIVE_CONFIRM_PAGAMENTO = 'RECEIVE_CONFIRM_PAGAMENTO'
export const RESET_ALL_PAGAMENTO = {type: 'RESET_ALL_PAGAMENTO'}

// reset
export const RESET_ALL_PRONTUARIO = {type: 'RESET_ALL_PRONTUARIO'}

// questionario
export const REQUEST_QUESTIONARIO = {type: 'REQUEST_QUESTIONARIO'}
export const RECEIVE_QUESTIONARIO = {type: 'RECEIVE_QUESTIONARIO'}
export const REQUEST_SAVE_QUESTIONARIO = {type: 'REQUEST_SAVE_QUESTIONARIO'}
export const RECEIVE_SAVE_QUESTIONARIO = {type: 'RECEIVE_SAVE_QUESTIONARIO'}
export const REQUEST_QUESTIONARIO_RESPONDER = {type: 'REQUEST_QUESTIONARIO_RESPONDER'}
export const RECEIVE_QUESTIONARIO_RESPONDER = {type: 'RECEIVE_QUESTIONARIO_RESPONDER'}
export const RESET_QUESTIONARIO = {type: 'RESET_QUESTIONARIO'}
export const RESET_ALL_QUESTIONARIO = {type: 'RESET_ALL_QUESTIONARIO'}

// relatorios
export const REQUEST_RELATORIO_COMISSAO = 'REQUEST_RELATORIO_COMISSAO'
export const RECEIVE_RELATORIO_COMISSAO = 'RECEIVE_RELATORIO_COMISSAO'
export const REQUEST_RELATORIO_PRODUTIVIDADE = 'REQUEST_RELATORIO_PRODUTIVIDADE'
export const RECEIVE_RELATORIO_PRODUTIVIDADE = 'RECEIVE_RELATORIO_PRODUTIVIDADE'
export const REQUEST_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS = 'REQUEST_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS'
export const RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS = 'RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS'
export const REQUEST_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS = 'REQUEST_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS'
export const RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS = 'RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS'
// export const REQUEST_RELATORIO_NOTAS_MEDICAS_PENDENTES = 'REQUEST_RELATORIO_NOTAS_MEDICAS_PENDENTES'
// export const RECEIVE_RELATORIO_NOTAS_MEDICAS_PENDENTES = 'RECEIVE_RELATORIO_NOTAS_MEDICAS_PENDENTES'
export const REQUEST_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES = 'REQUEST_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES'
export const RECEIVE_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES = 'RECEIVE_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES'
export const REQUEST_RELATORIO_PRODUTIVIDADE_CLINICAS = 'REQUEST_RELATORIO_PRODUTIVIDADE_CLINICAS'
export const RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICAS = 'RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICAS'
export const REQUEST_RELATORIO_PRODUTIVIDADE_MEDICOS = 'REQUEST_RELATORIO_PRODUTIVIDADE_MEDICOS'
export const RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICOS = 'RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICOS'
export const REQUEST_RELATORIO_CONTAS_PAGAR = {type: 'REQUEST_RELATORIO_CONTAS_PAGAR'}
export const RECEIVE_RELATORIO_CONTAS_PAGAR = {type: 'RECEIVE_RELATORIO_CONTAS_PAGAR'}
export const REQUEST_ENVIAR_COMPROVANTE_CONTA_PAGAR = {type: 'REQUEST_ENVIAR_COMPROVANTE_CONTA_PAGAR', id: 18000}
export const RECEIVE_ENVIAR_COMPROVANTE_CONTA_PAGAR = {type: 'RECEIVE_ENVIAR_COMPROVANTE_CONTA_PAGAR'}
export const REQUEST_REMOVER_COMPROVANTE_CONTA_PAGAR = 'REQUEST_REMOVER_COMPROVANTE_CONTA_PAGAR'
export const RECEIVE_REMOVER_COMPROVANTE_CONTA_PAGAR = 'RECEIVE_REMOVER_COMPROVANTE_CONTA_PAGAR'
export const REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES = 'REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES'
export const RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES = 'RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES'
export const REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES = 'REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES'
export const RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES = 'RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES'
export const REQUEST_MEIOS_PAGAMENTO = 'REQUEST_MEIOS_PAGAMENTO'
export const RECEIVE_MEIOS_PAGAMENTO = 'RECEIVE_MEIOS_PAGAMENTO'
export const RESET_ALL_RELATORIO = 'RESET_ALL_RELATORIO'

// sms
export const REQUEST_SMSS = 'REQUEST_SMSS'
export const RECEIVE_SMSS = 'RECEIVE_SMSS'
export const REQUEST_RELATORIO_SMS_CLINICA = 'REQUEST_RELATORIO_SMS_CLINICA'
export const RECEIVE_RELATORIO_SMS_CLINICA = 'RECEIVE_RELATORIO_SMS_CLINICA'
export const REQUEST_SMS_CONFIG = 'REQUEST_SMS_CONFIG'
export const RECEIVE_SMS_CONFIG = 'RECEIVE_SMS_CONFIG'
export const REQUEST_SAVE_SMS_CONFIG = 'REQUEST_SAVE_SMS_CONFIG'
export const RECEIVE_SAVE_SMS_CONFIG = 'RECEIVE_SAVE_SMS_CONFIG'
export const REQUEST_RESET_SMS_CONFIG = 'REQUEST_RESET_SMS_CONFIG'
export const RECEIVE_RESET_SMS_CONFIG = 'RECEIVE_RESET_SMS_CONFIG'
export const RESET_ALL_SMS = 'RESET_ALL_SMS'

// tecnico
export const REQUEST_TECNICOS = {type: 'REQUEST_TECNICOS'}
export const RECEIVE_TECNICOS = {type: 'RECEIVE_TECNICOS'}
export const REQUEST_ALL_TECNICOS = {type: 'REQUEST_ALL_TECNICOS'}
export const RECEIVE_ALL_TECNICOS = {type: 'RECEIVE_ALL_TECNICOS'}
export const REQUEST_TECNICO = {type: 'REQUEST_TECNICO'}
export const RECEIVE_TECNICO = {type: 'RECEIVE_TECNICO'}
export const RESET_ALL_TECNICO = {type: 'RESET_ALL_TECNICO'}
export const REQUEST_NOTAS_SERVICOS_TECNICO = {type: 'REQUEST_NOTAS_SERVICOS_TECNICO'}
export const RECEIVE_NOTAS_SERVICOS_TECNICO = {type: 'RECEIVE_NOTAS_SERVICOS_TECNICO'}
export const REQUEST_SAVE_NOTA_SERVICOS_TECNICO = {type: 'REQUEST_SAVE_NOTA_SERVICOS_TECNICO'}
export const RECEIVE_SAVE_NOTA_SERVICOS_TECNICO = {type: 'RECEIVE_SAVE_NOTA_SERVICOS_TECNICO'}
export const REQUEST_REMOVE_NOTA_SERVICOS_TECNICO = {type: 'REQUEST_REMOVE_NOTA_SERVICOS_TECNICO'}
export const RECEIVE_REMOVE_NOTA_SERVICOS_TECNICO = {type: 'RECEIVE_REMOVE_NOTA_SERVICOS_TECNICO'}
export const RESET_TECNICO = {type: 'RESET_TECNICO'}
export const REQUEST_SAVE_TECNICO = {type: 'REQUEST_SAVE_TECNICO'}
export const RECEIVE_SAVE_TECNICO = {type: 'RECEIVE_SAVE_TECNICO'}

// teleconsulta
export const REQUEST_TELECONSULTAS = {type: 'REQUEST_TELECONSULTAS'}
export const RECEIVE_TELECONSULTAS = {type: 'RECEIVE_TELECONSULTAS'}
export const REQUEST_TELECONSULTA = {type: 'REQUEST_TELECONSULTA'}
export const RECEIVE_TELECONSULTA = {type: 'RECEIVE_TELECONSULTA'}
export const REQUEST_SAVE_TELECONSULTA = {type: 'REQUEST_SAVE_TELECONSULTA'}
export const RECEIVE_SAVE_TELECONSULTA = {type: 'RECEIVE_SAVE_TELECONSULTA'}
export const REQUEST_DEVOLVER_PLANTAO_FILA = 'REQUEST_DEVOLVER_PLANTAO_FILA'
export const RECEIVE_DEVOLVER_PLANTAO_FILA = 'RECEIVE_DEVOLVER_PLANTAO_FILA'
export const START_TELECONSULTA = {type: 'START_TELECONSULTA'}
export const FINISH_TELECONSULTA = {type: 'FINISH_TELECONSULTA'}
export const RESET_TELECONSULTA = {type: 'RESET_TELECONSULTA'}
export const REQUEST_PACIENTE_TEM_RETORNO = {type: 'REQUEST_PACIENTE_TEM_RETORNO'}
export const RECEIVE_PACIENTE_TEM_RETORNO = {type: 'RECEIVE_PACIENTE_TEM_RETORNO'}
export const RESET_PACIENTE_TEM_RETORNO = {type: 'RESET_PACIENTE_TEM_RETORNO'}
export const REQUEST_SAVE_NOTA_MEDICA = {type: 'REQUEST_SAVE_NOTA_MEDICA'}
export const RECEIVE_SAVE_NOTA_MEDICA = {type: 'RECEIVE_SAVE_NOTA_MEDICA'}
export const REQUEST_TELECONSULTAS_CHART = {type: 'REQUEST_TELECONSULTAS_CHART'}
export const RECEIVE_TELECONSULTAS_CHART = {type: 'RECEIVE_TELECONSULTAS_CHART'}
export const REQUEST_TELECONSULTAS_TEMPO_ATENDIMENTO = {type: 'REQUEST_TELECONSULTAS_TEMPO_ATENDIMENTO'}
export const RECEIVE_TELECONSULTAS_TEMPO_ATENDIMENTO = {type: 'RECEIVE_TELECONSULTAS_TEMPO_ATENDIMENTO'}
export const REQUEST_TELECONSULTA_CALL_LEGS = {type: 'REQUEST_TELECONSULTA_CALL_LEGS'}
export const RECEIVE_TELECONSULTA_CALL_LEGS = {type: 'RECEIVE_TELECONSULTA_CALL_LEGS'}
export const REQUEST_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION = 'REQUEST_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION'
export const RECEIVE_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION = 'RECEIVE_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION'
export const REQUEST_TELECONSULTA_CONFIRMATIONS = {type: 'REQUEST_TELECONSULTA_CONFIRMATIONS'}
export const RECEIVE_TELECONSULTA_CONFIRMATIONS = {type: 'RECEIVE_TELECONSULTA_CONFIRMATIONS'}
export const REQUEST_SAVE_REEMBOLSO = {type: 'REQUEST_SAVE_REEMBOLSO'}
export const RECEIVE_SAVE_REEMBOLSO = {type: 'RECEIVE_SAVE_REEMBOLSO'}
export const REQUEST_CONFIRM_PRESENCE = {type: 'REQUEST_CONFIRM_PRESENCE'}
export const RECEIVE_CONFIRM_PRESENCE = {type: 'RECEIVE_CONFIRM_PRESENCE'}
export const REQUEST_SAVE_TELECONSULTA_PROCEDIMENTOS = 'REQUEST_SAVE_TELECONSULTA_PROCEDIMENTOS'
export const RECEIVE_SAVE_TELECONSULTA_PROCEDIMENTOS = 'RECEIVE_SAVE_TELECONSULTA_PROCEDIMENTOS'
export const REQUEST_LINK_RES_UNIMED = 'REQUEST_LINK_RES_UNIMED'
export const RECEIVE_LINK_RES_UNIMED = 'RECEIVE_LINK_RES_UNIMED'
export const RESET_LINK_RES_UNIMED = 'RESET_LINK_RES_UNIMED'
export const REQUEST_ALL_TELECONSULTA_TIPO = 'REQUEST_ALL_TELECONSULTA_TIPO'
export const RECEIVE_ALL_TELECONSULTA_TIPO = 'RECEIVE_ALL_TELECONSULTA_TIPO'
export const REQUEST_TELECONSULTA_SIGN_DOCS = 'REQUEST_TELECONSULTA_SIGN_DOCS'
export const RECEIVE_TELECONSULTA_SIGN_DOCS = 'RECEIVE_TELECONSULTA_SIGN_DOCS'
export const RESET_ALL_TELECONSULTA = {type: 'RESET_ALL_TELECONSULTA'}

// toast
export const CHANGE_TOAST = 'CHANGE_TOAST'

// users
export const REQUEST_USERS_STATUS = {type: 'REQUEST_USERS_STATUS'}
export const RECEIVE_USERS_STATUS = {type: 'RECEIVE_USERS_STATUS'}
export const REQUEST_USERS = {type: 'REQUEST_USERS'}
export const RECEIVE_USERS = {type: 'RECEIVE_USERS'}
export const REQUEST_USER = {type: 'REQUEST_USER'}
export const RECEIVE_USER = {type: 'RECEIVE_USER'}
export const REQUEST_REMOVE_USER = {type: 'REQUEST_REMOVE_USER', id: 21000}
export const RECEIVE_REMOVE_USER = {type: 'RECEIVE_REMOVE_USER'}
export const REQUEST_SAVE_USER = {type: 'REQUEST_SAVE_USER', id: 21001}
export const RECEIVE_SAVE_USER = {type: 'RECEIVE_SAVE_USER'}
export const REQUEST_CHANGE_PASSWD = {type: 'REQUEST_CHANGE_PASSWD', id: 14003}
export const RECEIVE_CHANGE_PASSWD = {type: 'RECEIVE_CHANGE_PASSWD'}
export const REQUEST_RECOVERY_PASSWORD = {type: 'REQUEST_RECOVERY_PASSWORD',  id: 14005}
export const RECEIVE_RECOVERY_PASSWORD = {type: 'RECEIVE_RECOVERY_PASSWORD'}
export const REQUEST_CHANGE_EMAIL = {type: 'REQUEST_CHANGE_EMAIL',  id: 14006}
export const RECEIVE_CHANGE_EMAIL = {type: 'RECEIVE_CHANGE_EMAIL'}
export const REQUEST_CHANGE_PHONE = {type: 'REQUEST_CHANGE_PHONE',  id: 14007}
export const RECEIVE_CHANGE_PHONE = {type: 'RECEIVE_CHANGE_PHONE'}
export const REQUEST_RESET_PASSWD = {type: 'REQUEST_RESET_PASSWD'}
export const RECEIVE_RESET_PASSWD = {type: 'RECEIVE_RESET_PASSWD'}
export const REQUEST_NEW_PASSWD = 'REQUEST_NEW_PASSWD'
export const RECEIVE_NEW_PASSWD = 'RECEIVE_NEW_PASSWD'
export const RESET_NEW_PASSWD = 'RESET_NEW_PASSWD'
export const REQUEST_ADMIN_RESEND_EMAIL = {type: 'REQUEST_ADMIN_RESEND_EMAIL'}
export const RECEIVE_ADMIN_RESEND_EMAIL = {type: 'RECEIVE_ADMIN_RESEND_EMAIL'}
export const REQUEST_USER_RESEND_EMAIL = {type: 'REQUEST_USER_RESEND_EMAIL'}
export const RECEIVE_USER_RESEND_EMAIL = {type: 'RECEIVE_USER_RESEND_EMAIL'}
export const REQUEST_ACCEPT_TERMS = {type: 'REQUEST_ACCEPT_TERMS'}
export const RECEIVE_ACCEPT_TERMS = {type: 'RECEIVE_ACCEPT_TERMS'}
export const RESET_USER = {type: 'RESET_USER'}
export const REQUEST_USERS_PACIENTE = 'REQUEST_USERS_PACIENTE'
export const RECEIVE_USERS_PACIENTE = 'RECEIVE_USERS_PACIENTE'
export const REQUEST_USER_PACIENTE = 'REQUEST_USER_PACIENTE'
export const RECEIVE_USER_PACIENTE = 'RECEIVE_USER_PACIENTE'
export const REQUEST_SAVE_USER_PACIENTE = 'REQUEST_SAVE_USER_PACIENTE'
export const RECEIVE_SAVE_USER_PACIENTE = 'RECEIVE_SAVE_USER_PACIENTE'
export const RESET_USER_PACIENTE = 'RESET_USER_PACIENTE'
export const RESET_ALL_USERS = {type: 'RESET_ALL_USERS'}

// vendedor
export const REQUEST_ALL_VENDEDORES = {type: 'REQUEST_ALL_VENDEDORES'}
export const RECEIVE_ALL_VENDEDORES = {type: 'RECEIVE_ALL_VENDEDORES'}
export const REQUEST_VENDEDORES = {type: 'REQUEST_VENDEDORES'}
export const RECEIVE_VENDEDORES = {type: 'RECEIVE_VENDEDORES'}
export const REQUEST_VENDEDOR = {type: 'REQUEST_VENDEDOR'}
export const RECEIVE_VENDEDOR = {type: 'RECEIVE_VENDEDOR'}
export const REQUEST_SAVE_VENDEDOR = {type: 'REQUEST_SAVE_VENDEDOR'}
export const RECEIVE_SAVE_VENDEDOR = {type: 'RECEIVE_SAVE_VENDEDOR'}
export const REQUEST_NOTAS_SERVICOS_VENDEDOR = {type: 'REQUEST_NOTAS_SERVICOS_VENDEDOR'}
export const RECEIVE_NOTAS_SERVICOS_VENDEDOR = {type: 'RECEIVE_NOTAS_SERVICOS_VENDEDOR'}
export const REQUEST_SAVE_NOTA_SERVICOS_VENDEDOR = {type: 'REQUEST_SAVE_NOTA_SERVICOS_VENDEDOR'}
export const RECEIVE_SAVE_NOTA_SERVICOS_VENDEDOR = {type: 'RECEIVE_SAVE_NOTA_SERVICOS_VENDEDOR'}
export const REQUEST_REMOVE_NOTA_SERVICOS_VENDEDOR = {type: 'REQUEST_REMOVE_NOTA_SERVICOS_VENDEDOR'}
export const RECEIVE_REMOVE_NOTA_SERVICOS_VENDEDOR = {type: 'RECEIVE_REMOVE_NOTA_SERVICOS_VENDEDOR'}
export const RESET_ALL_VENDEDOR = {type: 'RESET_ALL_VENDEDOR'}
export const RESET_VENDEDOR = {type: 'RESET_VENDEDOR'}

// whatsapp
export const REQUEST_WHATSAPP_INSTANCES = 'REQUEST_WHATSAPP_INSTANCES'
export const RECEIVE_WHATSAPP_INSTANCES = 'RECEIVE_WHATSAPP_INSTANCES'
export const REQUEST_WHATSAPP_INSTANCE = 'REQUEST_WHATSAPP_INSTANCE'
export const RECEIVE_WHATSAPP_INSTANCE = 'RECEIVE_WHATSAPP_INSTANCE'
export const REQUEST_SAVE_WHATSAPP_INSTANCE = 'REQUEST_SAVE_WHATSAPP_INSTANCE'
export const RECEIVE_SAVE_WHATSAPP_INSTANCE = 'RECEIVE_SAVE_WHATSAPP_INSTANCE'

export const REQUEST_WHATSAPP_INSTANCE_QRCODE = 'REQUEST_WHATSAPP_INSTANCE_QRCODE'
export const RECEIVE_WHATSAPP_INSTANCE_QRCODE = 'RECEIVE_WHATSAPP_INSTANCE_QRCODE'
export const REQUEST_WHATSAPP_INSTANCE_STATUS = 'REQUEST_WHATSAPP_INSTANCE_STATUS'
export const RECEIVE_WHATSAPP_INSTANCE_STATUS = 'RECEIVE_WHATSAPP_INSTANCE_STATUS'
export const REQUEST_WHATSAPP_INSTANCE_DEVICE = 'REQUEST_WHATSAPP_INSTANCE_DEVICE'
export const RECEIVE_WHATSAPP_INSTANCE_DEVICE = 'RECEIVE_WHATSAPP_INSTANCE_DEVICE'
// restore-session, restart, disconnect
export const REQUEST_WHATSAPP_INSTANCE_ACTION = 'REQUEST_WHATSAPP_INSTANCE_ACTION'
export const RECEIVE_WHATSAPP_INSTANCE_ACTION = 'RECEIVE_WHATSAPP_INSTANCE_ACTION'

export const REQUEST_WHATSAPP_MESSAGES = 'REQUEST_WHATSAPP_MESSAGES'
export const RECEIVE_WHATSAPP_MESSAGES = 'RECEIVE_WHATSAPP_MESSAGES'
export const REQUEST_WHATSAPP_MESSAGE = 'REQUEST_WHATSAPP_MESSAGE'
export const RECEIVE_WHATSAPP_MESSAGE = 'RECEIVE_WHATSAPP_MESSAGE'
export const REQUEST_RELATORIO_WHATSAPP_CLINICA = 'REQUEST_RELATORIO_WHATSAPP_CLINICA'
export const RECEIVE_RELATORIO_WHATSAPP_CLINICA = 'RECEIVE_RELATORIO_WHATSAPP_CLINICA'
export const RESSET_ALL_WHATSAPP = 'RESSET_ALL_WHATSAPP'

// window resizable
export const CHANGE_WINDOW_RESIZABLE = 'CHANGE_WINDOW_RESIZABLE'

// websocket
// export const CHANGE_WS_CONNECTION_STATE = 'CHANGE_WS_CONNECTION_STATE'
// export const RESET_ALL_WS = 'RESET_ALL_WS'
