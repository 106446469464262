import {
  REQUEST_SAVE_MENSAGEM, RECEIVE_SAVE_MENSAGEM, REQUEST_MENSAGEM_CHATS, RECEIVE_MENSAGEM_CHATS,
  REQUEST_SAVE_MENSAGEM_SOLVED, RECEIVE_SAVE_MENSAGEM_SOLVED
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  chats: {
    isLoading: false,
    receivedAt: null,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
  },
  saveItem: {
    isLoading: false,
    receivedAt: null,
  },
  saveItemSolved: {
    isLoading: false,
    receivedAt: null,
  }
}

export default function MensagemReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_MENSAGEM_CHATS:
      return {
        ...state,
        chats: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.chats.data,
        }
      }
    case RECEIVE_MENSAGEM_CHATS:
      return {
        ...state,
        chats: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_MENSAGEM:
    case RECEIVE_SAVE_MENSAGEM:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_MENSAGEM_SOLVED:
    case RECEIVE_SAVE_MENSAGEM_SOLVED:
      return {
        ...state,
        saveItemSolved: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    default:
      return state
  }
}
