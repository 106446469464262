import {
  REQUEST_TELECONSULTAS, RECEIVE_TELECONSULTAS, REQUEST_TELECONSULTA, RECEIVE_TELECONSULTA, REQUEST_SAVE_TELECONSULTA,
  RECEIVE_SAVE_TELECONSULTA, START_TELECONSULTA, FINISH_TELECONSULTA, RESET_TELECONSULTA, REQUEST_PACIENTE_TEM_RETORNO,
  RECEIVE_PACIENTE_TEM_RETORNO, RESET_PACIENTE_TEM_RETORNO, REQUEST_SAVE_NOTA_MEDICA, RECEIVE_SAVE_NOTA_MEDICA,
  REQUEST_TELECONSULTAS_CHART, RECEIVE_TELECONSULTAS_CHART, REQUEST_TELECONSULTAS_TEMPO_ATENDIMENTO,
  RECEIVE_TELECONSULTAS_TEMPO_ATENDIMENTO, REQUEST_TELECONSULTA_CALL_LEGS, RECEIVE_TELECONSULTA_CALL_LEGS,
  REQUEST_TELECONSULTA_CONFIRMATIONS, RECEIVE_TELECONSULTA_CONFIRMATIONS, REQUEST_SAVE_REEMBOLSO,
  RECEIVE_SAVE_REEMBOLSO, REQUEST_CONFIRM_PRESENCE, RECEIVE_CONFIRM_PRESENCE, REQUEST_SAVE_TELECONSULTA_PROCEDIMENTOS,
  RECEIVE_SAVE_TELECONSULTA_PROCEDIMENTOS, REQUEST_LINK_RES_UNIMED, RECEIVE_LINK_RES_UNIMED, RESET_LINK_RES_UNIMED,
  REQUEST_ALL_TELECONSULTA_TIPO, RECEIVE_ALL_TELECONSULTA_TIPO, REQUEST_DEVOLVER_PLANTAO_FILA,
  RECEIVE_DEVOLVER_PLANTAO_FILA, REQUEST_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION,
  RECEIVE_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION, REQUEST_TELECONSULTA_SIGN_DOCS, RECEIVE_TELECONSULTA_SIGN_DOCS,
  RESET_ALL_TELECONSULTA
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  save: {
    isLoading: false,
  },
  idTeleconsultaInProgress: null,
  pacienteTemRetorno: {
    isLoading: false,
    data: null,
    receivedAt: null,
  },
  saveNotaMedica: {
    isLoading: false,
  },
  chart: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  tempoAtendimento: {
    isLoading: false,
    data: null,
    receivedAt: null,
  },
  callLegs: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  callLegsHasIntersection: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  confirmations: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveReembolso: {
    isLoading: false,
  },
  confirmPresence: {
    isLoading: false,
  },
  saveProcedimentos: {
    isLoading: false,
  },
  linkRESUnimed: {
    isLoading: false,
    data: {
      // Sucesso: null,         // Status da requisição HTTP
      // CodigoMensagem: null,  // Código da requisição HTTP;
      // Mensagem: null,        // Mensagem da requisição HTTP
      // Retorno: {             // Resultado da consulta (apenas qd Sucesso = true)
      //   Status: null,        // Identifica que todas as validações foram realizadas com sucesso
      //   TipoCodigo: null,    // Código do tipo de erro
      //   TipoDescricao: null, // Descrição do código do tido do erro
      //   ErroCodigo: null,    // Código do erro no tipo de validação
      //   ErroDescricao: null, // Descrição do código de erro no tipo de validação
      //   UrlAcesso: null,     // Link de acesso aos Dados Clínicos (apenas qd Status = true)
      // }
    },
    receivedAt: null,
  },
  allTipo: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  devolverPlantaoFila: {
    isLoading: false,
  },
  signDocs: {
    isLoading: false,
  }
}

export default function TeleconsultaReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_TELECONSULTAS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.list.data : INITIAL_STATE.list.data,
          receivedAt: state.list.receivedAt,
        }
      }
    case RECEIVE_TELECONSULTAS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TELECONSULTA.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.item.data : INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_TELECONSULTA.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_TELECONSULTA.type:
    case RECEIVE_SAVE_TELECONSULTA.type:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_DEVOLVER_PLANTAO_FILA:
    case RECEIVE_DEVOLVER_PLANTAO_FILA:
      return {
        ...state,
        devolverPlantaoFila: {
          isLoading: action.isLoading,
        }
      }
    case START_TELECONSULTA.type:
      return {
        ...state,
        idTeleconsultaInProgress: action.idTeleconsulta
      }
    case FINISH_TELECONSULTA.type:
      return {
        ...state,
        idTeleconsultaInProgress: INITIAL_STATE.idTeleconsultaInProgress,
      }
    case REQUEST_PACIENTE_TEM_RETORNO.type:
      return {
        ...state,
        pacienteTemRetorno: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.pacienteTemRetorno.data,
        }
      }
    case RECEIVE_PACIENTE_TEM_RETORNO.type:
      return {
        ...state,
        pacienteTemRetorno: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_PACIENTE_TEM_RETORNO.type:
      return {
        ...state,
        pacienteTemRetorno: INITIAL_STATE.pacienteTemRetorno,
      }
    case REQUEST_SAVE_NOTA_MEDICA.type:
    case RECEIVE_SAVE_NOTA_MEDICA.type:
      return {
        ...state,
        saveNotaMedica: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_TELECONSULTAS_CHART.type:
      return {
        ...state,
        chart: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.chart.data,
        }
      }
    case RECEIVE_TELECONSULTAS_CHART.type:
      return {
        ...state,
        chart: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TELECONSULTAS_TEMPO_ATENDIMENTO.type:
      return {
        ...state,
        tempoAtendimento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.tempoAtendimento.data,
        }
      }
    case RECEIVE_TELECONSULTAS_TEMPO_ATENDIMENTO.type:
      return {
        ...state,
        tempoAtendimento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TELECONSULTA_CALL_LEGS.type:
    case RECEIVE_TELECONSULTA_CALL_LEGS.type:
      return {
        ...state,
        callLegs: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION:
    case RECEIVE_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION:
      return {
        ...state,
        callLegsHasIntersection: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TELECONSULTA_CONFIRMATIONS.type:
    case RECEIVE_TELECONSULTA_CONFIRMATIONS.type:
      return {
        ...state,
        confirmations: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_REEMBOLSO.type:
    case RECEIVE_SAVE_REEMBOLSO.type:
      return {
        ...state,
        saveReembolso: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CONFIRM_PRESENCE.type:
    case RECEIVE_CONFIRM_PRESENCE.type:
      return {
        ...state,
        confirmPresence: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_TELECONSULTA_PROCEDIMENTOS:
    case RECEIVE_SAVE_TELECONSULTA_PROCEDIMENTOS:
      return {
        ...state,
        saveProcedimentos: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_LINK_RES_UNIMED:
      return {
        ...state,
        linkRESUnimed: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.linkRESUnimed.data,
          receivedAt: INITIAL_STATE.linkRESUnimed.receivedAt,
        }
      }
    case RECEIVE_LINK_RES_UNIMED:
      return {
        ...state,
        linkRESUnimed: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_LINK_RES_UNIMED:
      return {
        ...state,
        linkRESUnimed: INITIAL_STATE.linkRESUnimed,
      }
    case REQUEST_ALL_TELECONSULTA_TIPO:
      return {
        ...state,
        allTipo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allTipo.data,
        }
      }
    case RECEIVE_ALL_TELECONSULTA_TIPO:
      return {
        ...state,
        allTipo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TELECONSULTA_SIGN_DOCS:
    case RECEIVE_TELECONSULTA_SIGN_DOCS:
      return {
        ...state,
        signDocs: {
          isLoading: action.isLoading,
        }
      }
    case RESET_TELECONSULTA.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_TELECONSULTA.type:
      return INITIAL_STATE
    default:
      return state
  }
}